import { navy, tiffany, orange, lightGray, lightBlue, textColor, iconGray } from './style';

export const SavedSearchFormStyle = theme => ({
  navLink: {
    color: `${tiffany}`,
    marginLeft: '16px',
    textDecoration: 'underline',
  },
  searchPracticeLink: {
    color: `${tiffany}`,
    textDecoration: 'underline',
    display: 'flex',
    justifyContent: 'center',
  },
  colorChooser: {
    '& div': {
      padding: 0,
      paddingRight: 20,
      display: 'flex',
    },
    marginLeft: 5,
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    margin: '8px 0',
  },
  formControlWithLabel: {
    height: '35px',
    '& label': {
      zIndex: 0,
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeButton: {
    minWidth: 10,
    padding: 2,
  },
  employmentTypeItem: {},
  practiceSelectEndAdornment: {
    position: 'absolute',
    right: '40px',
  },
  practiceSelectInput: {
    paddingRight: '60px',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    color: '#9e9e9e',
    height: '24px',
    marginTop: '16px',
  },
  container: {
    padding: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  actions: {
    padding: 0,
  },
  mapSearchContainer: {
    '@media (max-width: 960px)': {
      display: 'none',
    },
  },
  checkbox: {
    padding: '4px 9px 4px 9px',
  },
  notificationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '8px',
    marginBottom: '8px',
  },
  notifications: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  notificationLabel: {
    color: `${navy}`,
    fontWeight: 'bold',
  },
  frequencyLabel: {
    color: '#9e9e9e',
    fontWeight: 'bold',
  },
  notificationItems: {
    '& label': {
      display: 'flex',
      width: 120,
    },
    marginBottom: 20,
    paddingLeft: 14,
    display: 'flex',
  },
  warning: {
    color: 'red',
    fontSize: '0.875rem',
  },
  smsVerify: {
    height: 'auto',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  smsInput: {
    height: 35,
    borderRadius: '2px',
  },
  smsVerifyText: {
    fontSize: '12px',
    color: 'red',
  },
  warningIcon: {
    height: 12,
    width: 12,
  },
  verifyWarningIcon: {
    height: 18,
    width: 18,
  },
  helpPopoverText: {
    whiteSpace: 'pre-line',
  },
  helpPopover: {
    maxWidth: 400,
    padding: 10,
  },
  helpOutlineIcon: {
    marginLeft: '5px',
    height: '20px',
  },
  searchText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.2px',
    color: textColor,
  },
  emailItemContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  smsWarningIconAndTextField: {
    display: 'flex',
    flexDirection: 'row',
    height: '24px',
  },
  noEmailWarningText: {
    color: 'red',
    fontSize: '12px',
    paddingLeft: '10px',
  },
  warningIconAndText: {
    display: 'flex',
  },
  saveButton: {
    backgroundColor: orange,
    color: '#fff',
    '&:hover': {
      backgroundColor: orange,
      color: '#fff',
    },
  },
  select: {
    '& .MuiOutlinedInput-input': {
      paddingTop: '8px',
      paddingBottom: '8px',
    }
  },
  drawer: {
    width: '100%',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '100%',
    zIndex: 100,
    background: '#F9F9F9',
  },
  drawerHeader: {
    position: 'sticky',
    width: '100%',
    top: 0,
    zIndex: 100,
  },
  headerContainer: {
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: '#E1E5EA',
    borderBottom: '1px solid #D9D9D9',
  },
  backBtn: {
    color: '#364D96',
  },
  title: {
    fontFamily: 'Asap',
    fontSize: 20,
    fontWeight: 700,
    color: '#233160',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    cursor: 'pointer',
  },
  whiskerIcon: {
    marginLeft: 8,
    width: '65px',
    height: '14px',
  },
  drawerBody: {
    height: '100%',
  }
});
