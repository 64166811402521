import {
  Grid, Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { STEPS } from '../../config/enums';
import { StudentComponentStyle } from '../../css/style';
import OnboardingFlowComponent from './OnboardingFlowComponent';
import StudentGraduationDateComponent from './itemTypes/StudentGraduationDateComponent';
import SearchesComponent from './itemTypes/SearchesComponent';
import ExternDesciplineComponent from './itemTypes/ExternDesciplineComponent';
import ExternCareerComponent from './itemTypes/ExternCareerComponent';
import SchoolAutocomplete from './itemTypes/SchoolAutocomplete';
import { isEmpty } from 'lodash';

class StudentOnboarding extends OnboardingFlowComponent {
  constructor(props) {
    const { savedsearchs } = props.resources;
    const location = JSON.parse(sessionStorage.getItem('firstWBSearchLocation'));
    let showDefaultSearches = false;
    if (props.talent.type === 'dvm-doctor-of-veterinary-medicine' && !(savedsearchs && savedsearchs.length) && location) {
      showDefaultSearches = true;
    }
    let maxStep = showDefaultSearches ? 4 : props.talent.type === 'dvm-doctor-of-veterinary-medicine' ? 3 : 1;  
    
    if (props.location?.state?.from) {
      maxStep = 1;
    }

    super(props, maxStep);
    const st = this.state;
    st.step0 = [false, false];
    st.step1 = [false, false];
    st.step2 = [false];
    st.step3 = [true];
    st.optionalSelectSteps = [1];
    st.studentDefaults = [];
    st.externDiscipline = [];
    st.isExtern = false;
    this.state = st;
  }

  componentDidMount=() => {
    const { talent, resources } = this.props;
    const { savedsearchs } = resources;

    if (isEmpty(savedsearchs)) this.setState({ isEdit: true });

    if (talent.type === 'dvm-doctor-of-veterinary-medicine') {
      this.setItems({
        'sub-category': 'er-veterinarian',
        'job-type-sub-category': 'in-person',
        'patient-type': 'companion',
        'years-in-position': 1,
        'practice-size': 1,
        'patients-per-day': 1,
      });
    } else if (talent.type === 'vet-tech-or-assistant') {
      this.setItems({
        'vet-sub-category': 'certified-vet-tech',
        'job-type-sub-category': 'in-person',
        'vet-certification': 'CT / UT No Cert',
        'cert-specialty': null,
        'patient-type': 'companion',
        'years-in-position': 1,
        'practice-size': 1,
        'patients-per-day': 1,
        'administering-anesthesia': 2,
        'monitoring-anesthesia': 2,
        'central-line': 2,
        catheterization: 2,
        'filling-presciptions': 2,
      });
    }
  }

  setItems=(items) => {
    this.setOnboardingItems('student-defaults', Object.keys(items)
      .map(k => ({ criteria: k, strValue: typeof items[k] === 'string' && items[k] || null, numValue: typeof items[k] === 'number' && items[k] || null })));
  }

  studentInfo = (step) => {
    const { classes, talent, vetSchools, resources, location } = this.props;
    const vetDoctorSchools = vetSchools.filter(x => (x.isDoctor || x.isOther));
    const vetTechSchools = vetSchools.filter(x => (!x.isDoctor || x.isOther));
    const schoolList = talent.type === 'vet-tech-or-assistant' ? vetTechSchools : vetDoctorSchools;
    const { savedsearchs } = resources;
    const isDisabled = !isEmpty(savedsearchs) || (talent.isStudent && location?.state?.from === 'isEditExternUser');
    return (
        <div style={{ width: '100%', marginTop: 50 }}>
          <Typography color="secondary" className={classes.titleNoMarginBottom}>
            SCHOOL NAME (or OTHER)
          </Typography>
          <SchoolAutocomplete
            criteria="school-id"
            initState={this.getOnboardingItems('school-id')}
            handleStateChange={items => this.handleStateChange(items, 'school-id', step, 0)}
            schoolList={schoolList}
            disabled={isDisabled}
          />
          {isDisabled && <Typography variant="caption" display="block" gutterBottom>
            The school cannot be changed here, please <a href="https://www.jobsunleashed.com/contact-us" target="_blank" className={classes.link}>
            contact admins</a>
          </Typography>}
          <Typography color="secondary" className={classes.titleNoMarginBottom}>
            EXPECTED GRADUATION DATE
          </Typography>
          <StudentGraduationDateComponent
            criteria="graduation-date"
            initState={this.getOnboardingItems('graduation-date')}
            handleStateChange={items => this.handleStateChange(items, 'graduation-date', step, 1)}
          />
        </div>
    );
  };


  renderStep0() {
    return this.studentInfo(STEPS.zero);
  }

  handleExternDisciplineChange(data) {
    this.setState({ externDiscipline: data });
    this.handleStateChange(data, 'extern-discipline', 1, 0);
  }

  onExternCareerClick(key) {
    const item = [{ criteria: 'extern-career', strValue: key }];
    this.handleStateChange(item, 'extern-discipline', 2, 0);
  }

  renderStep1() {
    return (
      <ExternDesciplineComponent
        initState1={this.getOnboardingItems('extern-discipline-1')}
        initState2={this.getOnboardingItems('extern-discipline-2')}
        handleExternDisciplineChange={this.handleExternDisciplineChange.bind(this)}
      />
    );
  }

  renderStep2() {
    return (
      <ExternCareerComponent
        initState={this.getOnboardingItems('extern-career')}
        onClick={this.onExternCareerClick.bind(this)}
      />
    );
  }

  renderStep3() {
    const { classes } = this.props;
    return (
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography color="secondary" className={classes.title}>
            For students, Jobs Unleashed can match you with several different types of educational or work opportunities. Please indicate below which types of opportunities interest you. (You can click one or several options)
          </Typography>
        </Grid>

        <SearchesComponent
          criteria="patient-type"
          initState={this.getOnboardingItems('patient-type')}
          handleStateChange={items => this.handleDefaultSavedSearches(items, 1, 0)}
        />
        <Grid item xs={12}>
          <Typography color="secondary" className={classes.title}>
            We will build your initial WhiskerBot searches based on your choices above and your location. On the Whisker Searches tab, you will be able to modify these choices later (or make new searches).
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(StudentComponentStyle)(withRouter(StudentOnboarding));
