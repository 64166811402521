import { withStyles } from '@mui/styles';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  VET_TYPE_VET_TECH_OR_ASSISTANT,
  VET_TYPE_DVM,
  VET_TYPE_NON_CLINICAL,
  VET_TYPE_SPECIALIST_VETERINARIAN,
} from '../../config/enums';
import { OnboardingPageStyle } from '../../css/style';
import VetTechOrAssistantOnboarding from './VetTechOrAssistantOnboarding';
import GPVeterinarianOnboarding from './GPVeterinarianOnboarding';
import NonClinicalOnboarding from './NonClinicalOnboarding';
import SpecialistVeterinarianOnboarding from './SpecialistVeterinarianOnboarding';
import StudentOnboarding from './StudentOnboarding';

class OnbardingPage extends Component {
  componentDidMount() {
    const {
      actions, user, history, resources,
    } = this.props;
    if (user) {
      if (user.talent_id && !resources.talents) {
        actions.getAllResources(user.token, 'talents');
      }
    } else {
      history.push('/login');
    }
  }

  render() {
    const { resources, actions, user } = this.props;
    const { talents, schools } = resources;
    if (talents) {
      let talent = talents;
      if (Array.isArray(talents)) {
        if (user.talent_id) {
          const ftalents = talents.filter(t => t.id === user.talent_id);
          talent = ftalents.length ? ftalents[0] : talents[0];
        } else if (talents.length) {
          talent = talents[0];
        } else {
          talent = {};
        }
      }
      if (talent.isStudent) {
        return <StudentOnboarding user={user} actions={actions} resources={resources} talent={talent} vetSchools={schools} />;
      }
      switch (talent.type) {
        case VET_TYPE_VET_TECH_OR_ASSISTANT.key:
          return <VetTechOrAssistantOnboarding user={user} actions={actions} resources={resources} talent={talent} />;
        case VET_TYPE_SPECIALIST_VETERINARIAN.key:
          return <SpecialistVeterinarianOnboarding user={user} actions={actions} resources={resources} talent={talent} />;
        case VET_TYPE_DVM.key:
          return <GPVeterinarianOnboarding user={user} actions={actions} resources={resources} talent={talent} />;
        case VET_TYPE_NON_CLINICAL.key:
          return <NonClinicalOnboarding user={user} actions={actions} resources={resources} talent={talent} />;
        default:
          return '1';
      }
    }
    return '';
  }
}

OnbardingPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  history: PropTypes.shape({
    action: PropTypes.string,
    location: PropTypes.object,
    length: PropTypes.number,
    push: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    token: PropTypes.string,
    talent_id: PropTypes.number,
  }).isRequired,
  resources: PropTypes.shape({
    matches: PropTypes.array,
    talents: PropTypes.array,
  }).isRequired,
};

export default withStyles(OnboardingPageStyle)(withRouter(OnbardingPage));
