import React from 'react';
import isEqual from 'lodash/isEqual';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import CoreView from '../CoreView';

class CoreRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentToRender: this.props.render(),
      props,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(prevState.props, nextProps)) {
      return { componentToRender: nextProps.render(), props: nextProps };
    } else {
      return null;
    }
  }

  render() {
    const {
      coreview, access, name, ...rest
    } = this.props;
    const { componentToRender } = this.state;
    const componentResourceProps = componentToRender && componentToRender.props && componentToRender.props.resources;
    return (
      <Route
        {...rest}
        render={() => (
          <CoreView actions={coreview.actions} name={name} user={coreview.user} resources={componentToRender.props.resources}>
            {this.state.componentToRender}
          </CoreView>
        )}
      />
    );
  }
}

CoreRoute.propTypes = {
  user: PropTypes.object,
  render: PropTypes.func.isRequired,
  coreview: PropTypes.object,
};

CoreRoute.defaultProps = {
  user: null,
  coreview: {
    actions: null,
    user: null,
    resources: null,
    users: null,
  },
};

export default CoreRoute;
