import { navy, tiffany, lightAquaGreen, orange, lightGray, textColor, iconGray, lightNavy, ellipsis, aquaGreen } from './style';

const button = {
  height: '32px',
  textTransform: 'capitalize',
  fontSize: '13px',
  fontWeight: 600,
  lineHeight: '15.6px',
};

export const JobMatchCardStyle = theme => ({
  card: {
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
  },
  badgeFilter: {
    color: '#fff',
    marginRight: 5,
    marginTop: 5,
    height: 25,
    cursor: 'auto',
    padding: '0px 8px',
  },
  jobTitle: {
    color: navy,
    fontFamily: 'Asap',
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    padding: '10px 0px',
    maxHeight: '58px',
  },
  cardHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerText: {
    fontSize: '16px',
    fontWeight: 600,
  },
  iconGridItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  newIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '22px',
    height: '32px',
  },
  newIcon: {
    color: orange,
    fontSize: '20px',
  },
  newIconText: {
    color: orange,
    fontSize: '10px',
  },
  connectedIconText: {
    color: tiffany,
    fontSize: '10px',
  },
  deleteIcon: {
    cursor: 'pointer',
    color: lightGray,
  },
  matchItemInfo: {
    padding: 0,
    marginTop: '8px',
  },
  matchIteminfoItem: {
    display: 'flex',
    padding: '4px 0px',
    alignItems: 'center',
  },
  matchIteminfoIcon: {
    color: iconGray,
    marginRight: 5,
    fontSize: '20px',
  },
  matchItemText: {
    ...ellipsis,
    color: textColor,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19.2px',
    maxWidth: '380px',
  },
  matchItemLinkIcon: {
    color: aquaGreen,
    marginRight: 5,
    fontSize: '20px',
  },
  matchItemLink: {
    color: aquaGreen,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '19.2px',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  connectedStyle: {
    minWidth: 140,
    margin: 'auto',
    fontSize: '12px',
  },
  searchTitle: {
    textTransform: 'capitalize',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '15.6px',
    ...ellipsis,
    maxWidth: '160px',
  },
  button: {
    ...button,
  },
  lightNavyButton: {
    ...button,
    backgroundColor: lightNavy,
    color: '#fff',
    '&:hover': {
      backgroundColor: lightNavy,
      color: '#fff',
    },
  },
  redButton: {
    ...button,
    backgroundColor: orange,
    color: '#fff',
    '&:hover': {
      backgroundColor: orange,
      color: '#fff',
    },
  },
  cardContainer: {
    position: 'relative',
  },
  boostedBtn: {
    backgroundColor: orange,
    color: '#fff',
    height: 25,
    fontSize: 12,
    padding: '0px 8px',
    '&:hover': {
      backgroundColor: orange,
    },
  },
  boostedBtnContainer: {
    position: 'absolute',
    top: -10,
    right: 40,
    zIndex: 10,
    display: 'flex',
  },
  boostedBtnWhiteBorder: {
    width: '10px',
    backgroundColor: '#ffffff',
    marginTop: '10px',
  },
  closeButton: {
    padding: '8px',
  },
  link: {
    color: lightAquaGreen,
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '15.6px',
  },
  fastpassText: {
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '12px',
    padding: '0px',
    textTransform: 'capitalize',
  },
  infoGrid: {
    width: '100%',
    padding: '0px 8px',
  },
  infoTypography: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    textTransform: 'capitalize',
    color: navy,
  },
});