import React from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { GPVeterinarianFlowComponentStyle } from '../../css/style';
import OnboardingFlowComponent from './OnboardingFlowComponent';
import CurrentRoleComponent from './itemTypes/CurrentRoleComponent';
import {
  CURRENT_ROLE_CRITERIA,
  DVM_CATEGORIES,
  JOB_TYPE_SUBCATEGORIES,
  STEPS,
} from '../../config/enums';
import AnimalPatientTypeComponent from '../onboarding/itemTypes/AnimalPatientTypeComponent';
import SimpleSelectableIconComponent from './itemTypes/SimpleSelectableIconComponent';
import { JOB_TYPE_SUB_CATEGORY, SUB_CATEGORY } from '../constants/Constants';

class GPVeterinarianOnboarding extends OnboardingFlowComponent {
  constructor(props) {
    const { talent } = props;
    var maxSteps = 4;
    super(props, maxSteps);
    const st = this.state;
    st.step0 = [false];
    st.step1 = [false];
    st.step2 = [false];
    st.step3 = [false];
    st.optionalSelectSteps = [0];
    this.initSubCategoryItems(st, 'selectedDvm', SUB_CATEGORY);
    this.state = st;

    /* Remove telemed options when a worker edits details page
    (skip the page) and assign the worker to be in-person */
    this.addSkippedStep(1);
    this.autoSaveTelemedPrefAsInPerson();
  }

  componentDidUpdate(prevProps, prevState) {
    const { step } = this.state;
    if (step === 1 && prevState.step === 0) {
      this.verifyCriteriaDataFilled(JOB_TYPE_SUB_CATEGORY, step, 0);
    }
  }

  handleOnClick = (criteria, key, step, index) => {
    this.handleStateChange([{
      criteria,
      strValue: key,
    }], criteria, step, index);
    const stateKey = criteria === 'sub-category'
      ? 'selectedDvm'
      : 'jobTypeSubCategory';
    this.setState({ [stateKey]: key });
  };

  renderStep0() {
    const { classes, user } = this.props;
    const disabled = !!user.externalId;
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography color="secondary" className={classes.title}>
            WHAT TYPE OF DVM?
          </Typography>
          {disabled && (<Typography className={classes.resourceAlert}>{'>>>To edit unavailable fields, please update information in Workday.<<<'}</Typography>)}
        </Grid>
        {
          DVM_CATEGORIES.map(type => (
            <Grid
              item
              key={type.key}
            >
              <SimpleSelectableIconComponent
                icon={type.key}
                title={type.title.toUpperCase()}
                selected={type.key === this.state.selectedDvm}
                onClick={() => !disabled && this.handleOnClick('sub-category', type.key, 0, 0)}
              />
            </Grid>
          ))
        }
      </Grid>
    );
  }

  renderStep1() {
    const { classes } = this.props;
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography color="secondary" className={classes.title}>
            TELEMEDICINE PREFERENCE?
          </Typography>
        </Grid>
        {
          JOB_TYPE_SUBCATEGORIES.map(type => (
            <Grid item key={type.key}>
              <SimpleSelectableIconComponent
                icon={type.key}
                title={type.title.toUpperCase()}
                selected={type.key === this.state.jobTypeSubCategory}
                onClick={() => this.handleOnClick(JOB_TYPE_SUB_CATEGORY, type.key, 1, 0)}
              />
            </Grid>
          ))
        }
      </Grid>
    );
  }

  renderStep2() {
    const { classes } = this.props;
    return (
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography color="secondary" className={classes.title}>
            WHO ARE YOUR PATIENTS?
          </Typography>
        </Grid>
        <AnimalPatientTypeComponent
          criteria="patient-type"
          initState={this.getOnboardingItems('patient-type')}
          handleStateChange={items => this.handleStateChange(items, 'patient-type', 2, 0)}
        />
      </Grid>
    );
  }

  renderStep3() {
    const { classes } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <Typography color="secondary" className={classes.title}>
          TELL US ABOUT YOUR CURRENT ROLE
        </Typography>
        <CurrentRoleComponent
          keyValue="current-roles"
          info={CURRENT_ROLE_CRITERIA}
          enableContinue={() => this.enableContinueButton()}
          initState={this.getOnboardingItems('current-roles')}
          handleStateChange={items => this.handleStateChange(items, 'current-roles', 3, 0)}
        />
      </div>
    );
  }
}

export default withStyles(GPVeterinarianFlowComponentStyle)(withRouter(GPVeterinarianOnboarding));
