
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isEqual, sortBy, isNil } from 'lodash';
import { Link } from 'react-router-dom';
import { AppBar, Button, Container, Icon, IconButton, Toolbar, Paper } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import { NavAppBarStyle, navy } from '../../../css/style';
import { pageInfo, userMenu, IMG_STORAGE_LINK, DESKTOP_BREAKPOINT } from '../../constants/Constants';
import ConfirmedShiftsDialog from '../../subcomponents/ConfirmedShiftsDialog';
import SavedSearchDialog from '../../subcomponents/SavedSearchDialog';

class NavAppBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openUserMenu: false,
      openConfirmedShiftsDialog: false,
      openSearchDialog: false,
      totalUnreadCount: 0,
      connectedCount: 0,
      confirmedShiftsData: 0,
      isSignUpStudentUser: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { resources, user, actions } = this.props;

    if (user) {
      const preUnreadMsgCounts = prevProps.resources && prevProps.resources['messages/count-unread-msg-by-match'];
      const unreadMsgCounts = resources && resources['messages/count-unread-msg-by-match'];
      if (!unreadMsgCounts) {
        actions.getAllResources(user.token, 'messages/count-unread-msg-by-match')
          .then((res) => {
            if (!res.error) {
              const unreadMsgCounts = res.response || {};
              const totalUnreadCount = Object.keys(unreadMsgCounts).length;
              this.setState({ totalUnreadCount });
            }
          });
      } else if (unreadMsgCounts && !isEqual(preUnreadMsgCounts, unreadMsgCounts)) {
        const totalUnreadCount = Object.keys(unreadMsgCounts).length;
        this.setState({ totalUnreadCount });
      }
      const preMatchesStats = prevProps.resources && prevProps.resources['matches/stats'];
      const matchesStats = resources && resources['matches/stats'];
      if (matchesStats && !isEqual(preMatchesStats, matchesStats)) {
        this.setState({ connectedCount: Number(matchesStats.connected_count) }, () => {
          this.getConfirmedShiftsData();
        });
      }
    }
  }

  componentDidMount() {
    const { resources, user, actions } = this.props;
    if (resources) {
      let totalUnreadCount = 0;
      const unreadMsgCounts = resources['messages/count-unread-msg-by-match'];
      if (unreadMsgCounts) {
        totalUnreadCount = Object.keys(unreadMsgCounts).length;
      } else if (resources.messages) {
        const visibleMessages = resources.messages?.filter(x => x.match && !x.match.invisibleToTalent);
        totalUnreadCount = visibleMessages?.filter(msg => msg.isUnread && msg.creator_id !== user.id).length;
      }
      this.setState({ totalUnreadCount });

      const matchesStats = resources['matches/stats'];
      if (matchesStats) {
        this.setState({ connectedCount: Number(matchesStats.connected_count) }, () => {
          this.getConfirmedShiftsData();
        });
      } else {
        this.getMatchStatsData();
      }
    }

    const isSignUpStudentUser = localStorage.getItem(`student_user_${user?.id}`) === 'true';
    this.setState({ isSignUpStudentUser})
  }

  getMatchStatsData = () => {
    const { actions, user } = this.props;
    if (user && isNil(this.state.connectedCount)) {
      actions.getAllResources(user.token, 'matches/stats').then((res) => {
        this.setState({ connectedCount: Number(res?.response?.connected_count) }, () => {
          this.getConfirmedShiftsData();
        });
      });
    }
  }

  getConfirmedShiftsData() {
    const { actions, user } = this.props;
    const { connectedCount, confirmedShiftsData } = this.state;
    
    if (user && connectedCount > 0 && !confirmedShiftsData) {
      const url = user.intervalDate ? 'reports/worker-confirmation?intervalDate=true' : 'reports/worker-confirmation';
      actions?.getAllResources(user.token, url).then((reps) => {
        let data = reps?.response;
        if (data) {
          data = sortBy(data, 'date').reverse();
          data = data.map((row, index) => ({
            ...row,
            id: index,
          }))
        }
        this.setState({ confirmedShiftsData: data });
      });
    }
  }

  handleLogoClicked = () => {
    const { user, history } = this.props;
    if (user) {
      if (user.isStudent || this.state.isSignUpStudentUser) {
        history.push('/externhome');
      } else {
        history.push('/matches');
      }
    } else {
      history.push('/');
    }
  };

  renderToolBarLinks = () => {
    const { classes, history, user } = this.props;
    return (
      <>
        <img
          src={`${IMG_STORAGE_LINK}Jobs_Unleashed_LOGO.svg`}
          alt="Jobs Unleashed Logo"
          className={classes.logo}
          onClick={() => this.handleLogoClicked()}
        />
        {pageInfo.map(page => (!page.hideInMenu && (!!user || (!user && !page.hideWhenLogin))) && (
          <Button
            component="button"
            className={classes.navMenuButton}
            key={page.key}
            disableRipple
            onClick={() => {
              if (page.value) {
                if (page.value == '/' && (user?.isStudent || this.state.isSignUpStudentUser)) {
                  history.push('/externhome');
                } else {
                  history.push(page.value);
                }
                
              } else {
                window.location = page.redirect;
              }
            }}
          >
            {page.title}
          </Button>
        ))}
      </>
    );
  };

  getUserMenu() {
    const {
      classes, user, history, actions, onOpenReleaseNoteDialog
    } = this.props;
    const { anchorRef } = this.state;

    return (
      <>
        <Button
          color="secondary"
          className={classes.userMenu}
          onClick={e => this.setState({ anchorRef: e.currentTarget })}
        >
          {user.name}
          {user && user.releaseNote && !user.isReadReleaseNote && (
            <Icon className={classes.animatedIcon}>
              lens
            </Icon>
          )}
          <ExpandMore />
        </Button>
        <Menu
          anchorEl={anchorRef}
          open={Boolean(anchorRef)}
          onClose={e => this.setState({ anchorRef: null })}
        >
          {userMenu.map(page => (
            <MenuItem
              key={page.key}
              dense={false}
              className={classes.menuItem}
              onClick={(e) => {
                if(page.key === 'matches' && (user?.isStudent || this.state.isSignUpStudentUser)) {
                  history.push('/externhome');
                } else {
                  history.push(page.value);
                }
                this.setState({ anchorRef: null });
              }}
            >
              {page.title}
            </MenuItem>
          ))}
          <div className={classes.userInfo}>
            <Typography className={classes.userInfoNickName}>{user.name}</Typography>
            <Link
              color="primary"
              className={classes.signoutLink}
              to='/login'
              onClick={(e) => {
                this.setState({
                  anchorRef: null,
                  totalUnreadCount: 0,
                  connectedCount: 0,
                  confirmedShiftsData: 0
                });
                actions.logoutUser();
              }}
            >
              sign out
            </Link>
          </div>
          {user && user.releaseNote && (
            <div className={classes.version} onClick={() => onOpenReleaseNoteDialog()}>
              {!user.isReadReleaseNote && <Icon className={classes.animatedIcon}>
                lens
              </Icon>}
              Current Version: {user.releaseNote.version}
            </div>
          )}
        </Menu>
      </>
    );
  }

  render() {
    const {
      classes, onClickDrawerOpenAndClose, history, name, user, isTemporaryUser, actions, resources, isDesktop,
    } = this.props;
    const { totalUnreadCount, openConfirmedShiftsDialog, confirmedShiftsData } = this.state;
    const showUserMenu = !!user;
    const showLogin = (name !== 'login' && !user);
    let isExtern = false;

    if (this.props.location?.pathname?.includes('externlogin') || this.props.location?.pathname?.includes('extern-signup')) {
      isExtern = true;
    }
    return (
      <>
        {!isDesktop ?
          // Mobile
          !user ? (<Container className={classes.appBarInnerContainer} style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Button
              variant="text"
              className={classes.menuIcon}
              onClick={onClickDrawerOpenAndClose}
              style={{ top: 20, left: 16 }}
            >
              <img src={`${IMG_STORAGE_LINK}ic-menu.svg`} alt="menu" style={{ width: '20px', height: '20px' }} />
              {user && user.releaseNote && !user.isReadReleaseNote && (
                <Icon className={classes.animatedIcon}>
                  lens
                </Icon>
              )}
            </Button>
            <img
              src={`${IMG_STORAGE_LINK}Jobs_Unleashed_LOGO.svg`}
              alt="Jobs Unleashed Logo"
              className={classes.logo}
              onClick={() => this.handleLogoClicked()}
            />
            {showLogin && (<Button
              variant="contained"
              className={classes.signInButton}
              onClick={() => {
                if (isExtern) {
                  history.push('/externlogin')
                } else {
                  history.push('/login')
                }
              }}
            >
              Sign In
            </Button>
            )}
          </Container>
          )
            : (
              <Button
                variant="text"
                className={classes.menuIcon}
                onClick={onClickDrawerOpenAndClose}
              >
                <img src={`${IMG_STORAGE_LINK}ic-menu.svg`} alt="menu" style={{ width: '20px', height: '20px' }} />
                {user && user.releaseNote && !user.isReadReleaseNote && (
                  <Icon className={classes.animatedIcon}>
                    lens
                  </Icon>
                )}
              </Button>
            )

        :
        (// Desktop
        <AppBar position="absolute" className={classes.appBar}>
          <Paper square className={classes.appBarPaper}>
            <div className={classes.appBarInnerContainer}>
              <Toolbar>
                {this.renderToolBarLinks(classes)}
              </Toolbar>
              <div className={classes.rightContainer}>
                {!isTemporaryUser && showUserMenu && (
                  <>
                    <div className={classes.toolBarIconContainer}>
                      <IconButton
                        className={classes.toolBarIconBtn}
                        onClick={() => this.setState({ openConfirmedShiftsDialog: true })}
                        disableRipple>
                        <ThumbUpIcon className={classes.toolBarIcon}/>
                      </IconButton>
                      { (<Typography className={classes.chatBubbleText}>{`${confirmedShiftsData?.length || 0} Confirmed`}</Typography>)}
                    </div>
                    {(!user?.isStudent && !this.state.isSignUpStudentUser) && (<div className={classes.toolBarIconContainer}>
                      <IconButton
                        className={classes.toolBarIconBtn}
                        onClick={() => this.setState({ openSearchDialog: true })}
                        disableRipple>
                        <img
                          src={`${IMG_STORAGE_LINK}JU_search_icon.svg`}
                          className={classes.toolBarIcon}
                          style={{ width: 24, height: 24, }}
                        />
                      </IconButton>
                      { (<Typography className={classes.chatBubbleText}>Create search</Typography>)}
                    </div>
                    )}
                    { (this.state.connectedCount > 0 || totalUnreadCount > 0) &&
                    (<div className={classes.toolBarIconContainer}>
                      <IconButton className={classes.toolBarIconBtn}
                        onClick={() => history.push('/messages')}
                        disableRipple
                      >
                        {
                          totalUnreadCount > 0 && (
                            <Fragment>
                              <Icon className={classes.lensIcon}>
                                lens
                              </Icon>
                            </Fragment>
                          )
                        }
                        <Icon className={classes.toolBarIcon}>chat_bubble</Icon>
                      </IconButton>
                      <Typography className={classes.chatBubbleText}>{`${totalUnreadCount} New`}</Typography>
                    </div>
                )}
                  </>
                )}
                {
                  showLogin
                  && (
                    <Button
                      variant="contained"
                      className={classes.signInButton}
                      onClick={() => history.push('/login')}
                    >
                      Sign In
                    </Button>
                  )
                }
                {!isTemporaryUser && showUserMenu && this.getUserMenu()}
              </div>

                  <ConfirmedShiftsDialog
                    open={openConfirmedShiftsDialog}
                    handleClose={() => this.setState({ openConfirmedShiftsDialog: false })}
                    confirmedShiftsData={confirmedShiftsData}
                  />
                  <SavedSearchDialog
                    open={Boolean(this.state.openSearchDialog)}
                    user={user}
                    actions={actions}
                    resources={resources}
                    openConfirmationModal={() => this.setState({ openConfirmationModal: true })}
                    handleClose={e => this.setState({ openSearchDialog: false })}
                  />
                </div>
              </Paper>
            </AppBar>
          )}
      </>

    );
  }
}

NavAppBar.propTypes = {
  onClickDrawerOpenAndClose: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  history: PropTypes.shape({
    action: PropTypes.string,
    location: PropTypes.object,
    length: PropTypes.number,
    push: PropTypes.func,
  }).isRequired,
};

function NavAppBarWrapper({ theme, ...rest }) {
  const isDesktop = theme ? useMediaQuery(theme.breakpoints.up('md')) : true;
  return <NavAppBar {...rest} theme={theme} isDesktop={isDesktop} />;
}

export default withStyles(NavAppBarStyle, { withTheme: true })(withRouter(NavAppBarWrapper));