export const VET_TYPE_VET_TECH_OR_ASSISTANT = {
  key: 'vet-tech-or-assistant',
  id: 'vet-tech-or-assistant',
  title: 'Vet Tech / Vet Assistant',
  extra: [{
    key: 'isStudent',
    value: false,
  }],
};
export const VET_TYPE_VET_TECH_STUDENT = {
  key: 'vet-tech-or-assistant',
  id: 'vet-tech-or-assistant-student',
  title: 'Vet Tech Student Extern',
  isExtern: true,
  extra: [{
    key: 'isStudent',
    value: true,
  }],
};
export const VET_TYPE_SPECIALIST_VETERINARIAN = {
  key: 'specialist-veterinarian',
  id: 'specialist-veterinarian',
  title: 'Specialist Veterinarian',
  extra: [{
    key: 'isStudent',
    value: false,
  }],
};
export const VET_TYPE_DVM = {
  key: 'dvm-doctor-of-veterinary-medicine',
  id: 'dvm-doctor-of-veterinary-medicine',
  title: 'DVM',
  extra: [{
    key: 'isStudent',
    value: false,
  }],
};
export const VET_TYPE_DVM_STUDENT = {
  key: 'dvm-doctor-of-veterinary-medicine',
  id: 'dvm-doctor-of-veterinary-medicine-student',
  title: 'Veterinary Student Extern',
  isExtern: true,
  extra: [{
    key: 'isStudent',
    value: true,
  }],
};
export const VET_TYPE_NON_CLINICAL = {
  key: 'non-clinical',
  id: 'non-clinical',
  title: 'Non-Clinical',
  extra: [{
    key: 'isStudent',
    value: false,
  }],
};
export const ANIMAL_PATIENT_TYPES = ['companion', 'exotic-companion', 'equine', 'large-food', 'small-food', 'lab', 'wildlife'];
export const DVM_STUDENT_DEFAULT_SEARCHES = [{ key: 'Externship', title: 'Externship Positions' }, { key: 'Shadowing', title: 'Shadowing opportunities' }, { key: 'VetAssistant-Relief', title: 'Paid student (assistant) work' }];
export const VET_TYPES = [VET_TYPE_VET_TECH_OR_ASSISTANT, VET_TYPE_SPECIALIST_VETERINARIAN, VET_TYPE_DVM, VET_TYPE_VET_TECH_STUDENT, VET_TYPE_DVM_STUDENT, VET_TYPE_NON_CLINICAL];
export const STUDENT_VET_TYPES = [VET_TYPE_VET_TECH_STUDENT, VET_TYPE_DVM_STUDENT];
export const VET_CERTS = ['avdt', 'avtaa', 'aimvt', 'avecct', 'abvt', 'avzmt', 'aevnt', 'avtcp', 'avst', 'avnt', 'avot', 'avcpt', 'aala', 'aav', 'acvr', 'aarv'];
export const VET_CERT_SPECIALTIES = {
  avdt: 'Dentistry',
  avtaa: 'Anesthesia',
  aimvt: 'Internal Medicine',
  avecct: 'Emergency / Critical Care',
  abvt: 'Behaviour',
  avzmt: 'Zoological Medicine',
  aevnt: 'Equine',
  avtcp: 'Clinical Practice',
  avst: 'Surgery',
  avnt: 'Nutrition',
  avot: 'Opthalmic',
  avcpt: 'Clinical Pathology',
  aala: 'Lab Animal',
  aav: 'Avian',
  acvr: 'Radiology',
  aarv: 'Marine Rehabilitation',
};

export const VETENARIAN_COLLEGES = ['abvp', 'abvt', 'acaw', 'aclam', 'acpv', 'act', 'acvaa', 'acvb', 'acvcp', 'acvd', 'acvecc', 'acvim', 'acvm', 'acvn', 'acvo', 'acvp', 'acvpm', 'acvr', 'acvs', 'acvsmr', 'aczm', 'avdc', 'not-listed'];
export const VETENARIAN_COLLEGE_SPECIALITIES = {
  abvp: ['shelter medicine', 'reptile medicine', 'exotic companion animal', 'canine and feline', 'equine', 'food animal', 'dairy', 'swine health', 'avian', 'beef cattle', 'feline'],
  abvt: ['toxicology'],
  acaw: ['animal welfare'],
  aclam: ['laboratory animal medicine'],
  acpv: ['poultry'],
  act: ['theriogeniology'],
  acvaa: ['anesthesia'],
  acvb: ['behavior'],
  acvcp: ['pharmacology'],
  acvd: ['dermatology'],
  acvecc: ['emergency and critical care'],
  acvim: ['cardiology', 'small animal internal medicine', 'large animal internal medicine', 'neurology', 'oncology'],
  acvm: ['virology', 'immunology', 'bacteriology / mycology', 'parisitology'],
  acvn: ['nutrition'],
  acvo: ['ophthalmology'],
  acvp: ['anatomic pathology', 'clinical pathology'],
  acvpm: ['preventative medicine'],
  acvr: ['radiology', 'radiation oncology', 'equine diagnostic imaging'],
  acvs: ['small animal surgery', 'large animal surgery'],
  acvsmr: ['canine', 'equine'],
  aczm: ['zoological medicine'],
  avdc: ['dentistry', 'equine dental'],
  'not-listed': ['shelter medicine', 'reptile medicine', 'exotic companion animal', 'canine and feline', 'equine', 'food animal', 'dairy', 'swine health', 'avian', 'beef cattle', 'feline', 'toxicology', 'animal welfare', 'laboratory animal medicine', 'poultry', 'theriogeniology', 'anesthesia', 'behavior', 'pharmacology', 'dermatology', 'emergency and critical care', 'cardiology', 'small animal internal medicine', 'large animal internal medicine', 'neurology', 'oncology', 'virology', 'immunology', 'bacteriology / mycology', 'parisitology', 'nutrition', 'ophthalmology', 'anatomic pathology', 'clinical pathology', 'preventative medicine', 'radiology', 'radiation oncology', 'equine diagnostic imaging', 'small animal surgery', 'large animal surgery', 'canine', 'zoological medicine', 'dentistry', 'equine dental'],
};
export const VET_ASSISTANT_COMFORT_LEVEL = [
  {
    criteria: 'monitoring-anesthesia',
    title: 'Monitoring Anesthesia?',
  },
  {
    criteria: 'catheterization',
    title: 'Catheterization?',
  },
  {
    criteria: 'dog-cat-cpr-assistance',
    title: 'Assisting CPR on a dog or cat',
  },
  {
    criteria: 'dog-cat-restraint',
    title: 'Restraining cats and dogs during examination and treatment',
  },
  {
    criteria: 'sterilize-equipment',
    title: 'Sterilizing surgical and other medical equipment',
  },
];
export const CERTIFIED_VET_COMFORT_LEVEL = [
  {
    criteria: 'administering-anesthesia',
    title: 'Administering Anesthesia?',
  },
  {
    criteria: 'central-line',
    title: 'Running a central line?',
  },
  {
    criteria: 'monitoring-anesthesia',
    title: 'Monitoring Anesthesia?',
  },
  {
    criteria: 'catheterization',
    title: 'Catheterization?',
  },
  {
    criteria: 'filling-presciptions',
    title: 'Filling Prescriptions?',
  },
];
export const NON_CLINICAL_CURRENT_ROLE_CRITERIA = [
  {
    criteria: 'years-in-animal-care',
    title: 'Years worked in this sort of position',
  },
  {
    criteria: 'practice-size',
    title: 'How many vets are there at your practice?',
  },
];
export const CURRENT_ROLE_CRITERIA = [
  {
    criteria: 'years-in-position',
    title: 'Years in your current position?',
  },
  {
    criteria: 'practice-size',
    title: 'How many vets are there at your practice?',
  },
  {
    criteria: 'patients-per-day',
    title: 'Typical # of patients you see in a day?',
  },
];
export const ABOUT_YOU_CRITERIA = [
  {
    criteria: 'brief',
    title: 'Write a brief introduction about yourself',
    information: 'We\'ll use this when you ask to be introduced',
  },
  {
    criteria: 'about',
    title: 'What are a couple of highlights about your experience?',
    information: 'Also for your intro',
  },
];
export const JOB_CATEGORIES = [
  {
    key: 'accounting',
    category: 'Accounting',
  },
  {
    key: 'finance',
    category: 'Finance',
  },
  {
    key: 'human-resources',
    category: 'Human Resources',
  },
  {
    key: 'information-technology',
    category: 'Information Technology',
  },
  {
    key: 'sales',
    category: 'Sales',
  },
  {
    key: 'marketing',
    category: 'Marketing',
  },
  {
    key: 'practice-management',
    category: 'Practice Management',
  },
  {
    key: 'supply-chain',
    category: 'Supply Chain',
  },
  {
    key: 'administrative',
    category: 'Administrative',
  },
  {
    key: 'customer-service',
    category: 'Customer Service / Client Care',
  },
  {
    key: 'grooming',
    category: 'Grooming',
  },
  {
    key: 'kennel-tech',
    category: 'Kennel Tech',
  },
  {
    key: 'dog-handler',
    category: 'Dog Handler',
  },
  {
    key: 'custodian',
    category: 'Custodian',
  },
  {
    key: 'dog-trainer',
    category: 'Dog Trainer',
  },
  {
    key: 'kennel-boarding-manager',
    category: 'Kennel Boarding Manager',
  },
  {
    key: 'facilities-coordinator',
    category: 'Facilities Coordinator',
  },
  {
    key: 'other',
    category: 'Other',
  },
];
export const DVM_CATEGORIES = [
  {
    key: 'gp-veterinarian',
    title: 'GP Veterinarian',
  },
  {
    key: 'er-veterinarian',
    title: 'ER Veterinarian',
  },
];

export const JOB_TYPE_SUBCATEGORIES = [
  {
    key: 'telemedicine-vet',
    title: 'Telemedicine',
  },
  {
    key: 'in-person',
    title: 'In-Person',
  }, {
    key: 'telemedicine-in-person',
    title: 'In-Person and Telemed.',
  },
];

export const VET_CATEGORIES = [
  {
    key: 'certified-vet-tech',
    title: 'Vet Tech',
  },
  {
    key: 'vet-assistant',
    title: 'Vet Assistant',
  },
];

export const TALENT_ROLES_CONFIG = {
  all: {
    subKey: 'all',
    subTypeList: [{
      key: 'all',
      title: 'All roles',
    }],
  },
  'vet-tech-or-assistant': {
    subKey: 'vet-sub-category',
    subTypeList: VET_CATEGORIES,
  },
  'dvm-doctor-of-veterinary-medicine': {
    subKey: 'sub-category',
    subTypeList: DVM_CATEGORIES,
  },
  'specialist-veterinarian': {
    subKey: 'speciality',
    subTypeList: VETENARIAN_COLLEGE_SPECIALITIES['not-listed'].map(item => ({
      key: item,
      title: `Specialist - ${item.charAt(0).toUpperCase()}${item.slice(1)}`,
    })),
  },
  'non-clinical': {
    subKey: 'job-category',
    subTypeList: JOB_CATEGORIES,
  },
};

export const EMPLOYMENT_TYPES = ['Relief', 'Shadowing', 'Externship'];
export const EMPLOYMENT_TYPE_TITLES = {
  Relief: 'Relief',
  Shadowing: 'Shadowing',
  Externship: 'Externship',
  'VetAssistant-Relief': 'Student (assistant) Work',
};
export const COLORS = ['ff8686', 'fb4771', 'ce186a', '8f0b5f', '53034b', 'ad6dea', '9fb9ff', '567feb', '0a547b', '278c7f', '0ce7a7', 'acfcad', 'ffec6d', 'ffa763', 'ff4040'];

export const CERTIFICATIONS = [
  {
    key: 'LVT',
    title: 'Licensed Veterinary Technician',
  },
  {
    key: 'CVT',
    title: 'Veterinary Technician',
  },
  {
    key: 'RVT',
    title: 'Registered Veterinary Technician',
  },
  {
    key: 'LVMT',
    title: 'Licensed Veterinary Medical Technican',
  },
  {
    key: 'CT / UT No Cert',
    title: 'Other / None',
  },
];
export const STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const NOTIFICATION_FREQUENCY_OPTIONS = [
  {
    value: 'REAL_TIME',
    label: 'Real-Time',
  },
  {
    value: 'DAILY',
    label: 'Daily',
    sublabel: '(9AM - PT)',
  },
];

export const STAFF_WB_LOCATION_OPTIONS = [
  {
    value: 'WHOLE_STATE',
    label: 'Entire State',
  },
  {
    value: 'SELECT_PRACTICES',
    label: 'Select Practices',
  },
];

export const FIRST_WB_SEARCH_CONFIG = {
  color: 'acfcad',
  title: 'Relief Work Near Me',
  hasEmail: true,
  range: 100,
  positionType: 'Relief',
  notificationFrequency: 'DAILY',
};

export const FIRST_EXTERN_WB_SEARCH_CONFIG = {
  color: 'acfcad',
  title: 'Externships Near Me',
  hasEmail: true,
  range: 100,
  positionType: 'Externship',
  notificationFrequency: 'DAILY',
};

export const JOB_TYPE_SUB_CATEGORY_OBJ = {
  'telemedicine-vet': 'Telemedicine',
  'in-person': 'In-Person',
  'telemedicine-in-person': 'In-Person and Telemed.',
};

export const STEPS = Object.freeze({
  zero: 0,
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
});

export const EXTERN_DISCIPLINE = [
  'None',
  'Bovine',
  'Cardiology',
  'Dentistry',
  'Dermatology',
  'Emergency',
  'Equine',
  'Exotics/Avian',
  'General Practice',
  'Internal Medicine',
  'Medical Oncology',
  'Neurology',
  'Ophthalmology',
  'Radiology',
  'Radiation Oncology',
  'Rehab/Sports Medicine',
  'Surgery',
];
export const EXTERN_CAREER = [
  'Internship',
  'Government',
  'Industry (Zoetis, Purina, etc.)',
  'Residency',
  'Research',
  'Private Practice (Small Animal)',
  'Private Practice (Large Animal)',
];
