import isString from 'lodash/isString';
import Immutable from 'seamless-immutable';
import types from '../actions/ActionTypes';
import { LOGIN_ERROR_MESSAGES } from '../components/constants/Constants';

const sanitizeMsg = (actionMsg) => {
  // Sanitize action message to make sure a string is added to this reducer's state
  if (actionMsg) {
    const message = isString(actionMsg) ? actionMsg : isString(actionMsg.message) ? actionMsg.message : '';
    return message.replace(/validation error:/gi, '');
  }
  return '';
};

export default function MessageReducer(state = Immutable({ errors: '', success: '', serverDown: false }), action) {
  let serverIsDown = false;
  let unrecognizedUser = false;
  const sanitizedErrorMsg = sanitizeMsg(action.error);
  if (action.error) {
    if (Object.keys(LOGIN_ERROR_MESSAGES).includes(sanitizedErrorMsg)) {
      unrecognizedUser = true;
    } else if (sanitizedErrorMsg === 'Server is not responding') {
      serverIsDown = true;
    }
  }

  switch (action.type) {
    case types.LOGIN_USER_FAILURE:
      return Immutable.merge(state, { errors: unrecognizedUser ? '' : sanitizedErrorMsg, success: '', serverDown: serverIsDown });
    case types.ERROR:
    case types.RESET_PASSWORD_FAILURE:
    case types.VERIFY_USER_FAILURE:
    case types.UPDATE_RESOURCE_NO_LOADING_FAILURE:
    case types.UPDATE_RESOURCE_FAILURE:
    case types.VERIFY_USER_FAILURE:
    case types.VERIFY_ACCOUNT_SMS_FAILURE:
    case types.VERIFY_ACCOUNT_EMAIL_FAILURE:
    case types.RENDER_MESSAGE:
      return Immutable.merge(state, { errors: sanitizedErrorMsg, success: '', serverDown: serverIsDown });
    case types.VERIFY_USER_SUCCESS:
    case types.RESET_PASSWORD_SUCCESS:
    case types.UPDATE_RESOURCE_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
    case types.VERIFY_USER_SUCCESS:
    case types.VERIFY_ACCOUNT_SMS_SUCCESS:
    case types.VERIFY_ACCOUNT_EMAIL_SUCCESS:
      return Immutable.merge(state, { errors: '', success: sanitizeMsg(action.successMsg), serverDown: false });
    case types.CLEAR_MESSAGE:
      return Immutable.merge(state, { errors: '', success: '' });
    default:
      return Immutable.merge(state, { errors: sanitizedErrorMsg });
  }
}
