import {
  Button, Container, Grid, Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ConnectionsComponentStyle } from '../../css/style';
import JobMatchCard from '../subcomponents/JobMatchCard';
import DeleteConfirmDialog from '../subcomponents/DeleteConfirmDialog';
import { formatPostTitle } from '../../utils/Functions';

const TIMEOUT_DURATION = 400;
class ConnectionsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      openDeleteConfirmDialog: false,
      matchToBeDeleted: {},
      isMatchDeleted: false,
    };
  }

  componentDidMount = async () => {
    const { actions, user, resources } = this.props;
    await actions.getAllResources(user.token, 'matches');
  }

  refreshStats() {
    const { actions, user } = this.props;

    actions.getAllResources(user.token, 'matches/stats');
  }

  handleDeleteMath = () => {
    const { actions, user } = this.props;
    const { matchToBeDeleted } = this.state;
    this.setState({ openDeleteConfirmDialog: false, isMatchDeleted: true }, () => {
      setTimeout(() => actions.deleteResource('matches', user.token, 'matches', matchToBeDeleted.id)
        .then(() => this.refreshStats())
        .then(() => this.setState({ isMatchDeleted: false })), TIMEOUT_DURATION);
    });
  }

  render() {
    const {
      actions, classes, resources, user, unreadMsgCounts,
    } = this.props;
    const { matches } = resources;
    const { filter, openDeleteConfirmDialog, isMatchDeleted, matchToBeDeleted } = this.state;
    const actualFilter = (filter === 'all') ? '' : filter;
    const allMatches = matches ? matches.filter(match => match.status === 'connected') : [];
    const filters = ['all-#243060', ...new Set(allMatches.map(match => `${match?.savedSearch?.title}-${match?.savedSearch?.color}`))]
      .map(item => item.split('-'));
    const matchItems = allMatches.filter(item => !actualFilter || item?.savedSearch?.title === actualFilter);

    return (
      <Container>
        <Typography variant="h5" color="secondary" className={classes.title}>
          MY CONNECTIONS
        </Typography>
        <Grid container spacing={1} className={classes.filterContainer}>
          {filters.map(([title, color]) => (
            <Grid item key={title}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                disabled={title === actualFilter}
                className={classes.filterButton}
                onClick={e => this.setState({ filter: title })}
                style={{ backgroundColor: (title === actualFilter) ? '' : `#${color}` }}
              >
                {title === 'Externship Work Near Me' ? 'Externships Near Me' : title}
              </Button>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2}>
          {
            matchItems.map(item => (
              <Grid key={item.id} item xs={12} lg={4}>
                <JobMatchCard
                  item={item}
                  user={user}
                  actions={actions}
                  unreadCount={unreadMsgCounts[item.id]}
                  enableDelete
                  deleteMatch={() => this.setState({ openDeleteConfirmDialog: true, matchToBeDeleted: item })}
                  open={!(matchToBeDeleted.id === item.id && isMatchDeleted)}
                />
              </Grid>
            ))
          }
          <DeleteConfirmDialog
            resourceName="Job Post"
            open={openDeleteConfirmDialog}
            handleDelete={() => this.handleDeleteMath()}
            handleClose={() => this.setState({ openDeleteConfirmDialog: false })}
            jobTitle={matchToBeDeleted && matchToBeDeleted.jobPosting && formatPostTitle(matchToBeDeleted.jobPosting.title)}
            practiceName={matchToBeDeleted && matchToBeDeleted.practice && matchToBeDeleted.practice.name}
            message="Are you sure you want to remove the post below - any new dates added to this post will not be visible!"
            hasCancelButton
          />
        </Grid>
      </Container>
    );
  }
}

ConnectionsComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  resources: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(ConnectionsComponentStyle)(withRouter(ConnectionsComponent));
