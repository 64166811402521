import React, { Component } from 'react';
import {
  Grid, Typography, FormControlLabel, Slider,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ComfortLevelSelectionComponentStyle, GeneralSliderStyle } from '../../../css/style';

const PrettoSlider = withStyles(GeneralSliderStyle)(Slider);

class ComfortLevelSelectionComponent extends Component {
  constructor(props) {
    super(props);
    const {
      info, handleStateChange, keyValue, initState,
    } = props;
    const items = [];
    info.forEach((value, index) => {
      const initValue = (initState.length > index) ? initState[index].numValue : 1;
      items.push({
        criteria: value.criteria,
        numValue: initValue,
      });
    });
    this.state = { items };
    handleStateChange(items);
  }

  componentDidMount() {
    const { enableContinue } = this.props;
    enableContinue && enableContinue();
  }


  handleChange(value, criteria) {
    const { handleStateChange, keyValue } = this.props;
    const { items } = this.state;
    const updatedItems = items.map((item) => {
      if (item.criteria === criteria) {
        return {
          ...item,
          numValue: value,
        };
      }
      return item;
    });
    handleStateChange(updatedItems);
    this.setState({
      items: updatedItems,
    });
  }

  render() {
    const { classes, info } = this.props;
    const { items } = this.state;

    return (
      <Grid container spacing={1} justifyContent="center">
        {
          info.map((value, index) => {
            const { criteria, title } = value;
            return (
              <Grid key={criteria} item xs={12}>
                <Typography color="primary" className={classes.title}>
                  {title}
                </Typography>
                <div>
                  <PrettoSlider
                    aria-label="pretto slider"
                    defaultValue={items[index].numValue}
                    max={5}
                    min={1}
                    marks={[{ value: 1, label: '1' }, { value: 2, label: '2' }, { value: 3, label: '3' }, { value: 4, label: '4' }, { value: 5, label: '5' }]}
                    onChange={(e, value) => this.handleChange(value, criteria)}
                  />
                </div>
              </Grid>
            );
          })
        }
      </Grid>
    );
  }
}

ComfortLevelSelectionComponent.propTypes = {
  initState: PropTypes.array.isRequired,
  keyValue: PropTypes.string.isRequired,
  info: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleStateChange: PropTypes.func.isRequired,
};

ComfortLevelSelectionComponent.defaultProps = {
  initState: [],
};

export default withStyles(ComfortLevelSelectionComponentStyle)(withRouter(ComfortLevelSelectionComponent));
