import { navy, green, tiffany, orange, lightGray, textColor, iconGray, lightNavy, armyGreen, bgGray, bgLightGray, ellipsis, fabBtn, closeDrawer, lightAquaGreen } from './style';

const homeDrawerWidth = '25%';
const mobileHomeDrawerWidth = '30%';

export const MatchDetailsPageStyle = theme => ({
  root: {
    display: 'flex',
  },
  drawerPaper: {
    width: homeDrawerWidth,
    zIndex: 100,
    overflowY: 'hidden',
    paddingTop: '63px',

    [theme.breakpoints.down('md')]: {
      width: mobileHomeDrawerWidth,
    },

  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    minHeight: '83px',
    backgroundColor: bgGray,
  },
  drawerHeading: {
    color: navy,
    fontSize: 20,
    fontWeight: 700,
    paddingLeft: '10px',

    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  leftDrawerSortBtn: {
    ...closeDrawer,
  },
  headingTitleItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headingBtnGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  mobileContent: {
    flexGrow: 1,
    padding: 0,
  },

  mobileInfoContainer: {
    marginTop: '87px',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '80vh',
  },

  closeIconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  topDrawerCloseBtn: {
    ...closeDrawer,
    marginTop: '-10px',
    position: 'relative',
  },
  itemEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '36px',

  },
  calendarIconBox: {
    border: `1px solid ${tiffany}`,
    borderRadius: '50%',
    boxShadow: '0px 4px 4px 0px #00000040',
    cursor: 'pointer!important',
    backgroundColor: 'white',
  },
  content: {
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: homeDrawerWidth,

    [theme.breakpoints.down('md')]: {
      marginLeft: mobileHomeDrawerWidth,
    },
  },
  contentShiftLeft: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  contentToolbar: {
    display: 'flex',
    padding: 0,
    width: '75%',
    height: '87px',
    //minHeight: 'unset',
    backgroundColor: bgGray,
    borderBottom: '1px solid #E1E5EA',
    position: 'fixed',
    zIndex: 10,

    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
  },
  infoContainer: {
    marginTop: '87px',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '80vh',
  },

  carouselContainer: {
    padding: '20px 10px 20px 10px',
    width: '516px',
    height: '344px',
    display: 'flex',
    justifyContent: 'center',

    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      minHeight: '200px',
    },
  },
  practiceImage: {
    display: 'block',
    maxHeight: '399px',
    margin: 'auto',
    maxWidth: '449px',
  },
  badgeFilter: {
    color: '#fff',
    marginRight: 5,
    marginTop: 5,
    height: 25,
    cursor: 'auto',
    padding: '0px 8px',
  },
  matchItemInfo: {
    backgroundColor: bgLightGray,
    padding: 5,
    marginTop: 5,
    borderRadius: 5,
  },
  matchIteminfoItem: {
    display: 'flex',
  },
  matchIteminfoIcon: {
    color: lightAquaGreen,
    marginRight: 5,
  },
  matchItemCalendar: {
    display: 'flex',
    '&:hover': {
      cursor: 'pointer',
    },
    color: orange,
  },
  matchItemCalendarIcon: {
    color: orange,
    marginRight: 5,
    marginTop: 3,
  },
  pageTitle: {
    color: navy,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'capitalize',
  },
  pageSubTitle: {
    color: navy,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '19.2px',
    textTransform: 'capitalize',
  },
  title: {
    color: navy,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '19.2px',
    textTransform: 'capitalize',
  },
  text: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '19.2px',
  },
  directionLink: {
    color: lightAquaGreen,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '19.2px',
    display: 'flex',
    alignItems: 'center',
  },
  practiceContainer: {
    display: 'flex',
    padding: '20px 66px',
    borderBottom: `1px solid ${lightGray}`,

    // mobile styles
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '8px 0px',
    },

    [theme.breakpoints.down('md')]: {
      padding: '20px',
    },

  },
  practiceDescriptionTitle: {
    color: navy,
    fontSize: '16px',
    fontWeight: 700,

    // mobile styles
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px',
    },
  },
  practiceContent: {
    display: 'flex',
    alignItems: 'center',

    // mobile styles
    [theme.breakpoints.down('sm')]: {
      padding: '0px 16px',
    },
  },
  practiceDescription: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '19.2px',

    // mobile styles
    [theme.breakpoints.down('sm')]: {
      padding: '0px 16px',
    },
  },
  connectButton: {
    margin: '16px 0',
    height: '40px',
    fontSize: '16px',
    fontWeight: 600,
    backgroundColor: orange,
    color: '#fff',
    textTransform: 'capitalize',
    //position: 'absolute',
    // top: '12px',
    // left: 'calc(100% - 182px)',

    '&:hover': {
      backgroundColor: orange,
      color: '#fff',
    },
  },

  connectFabBtn: {
    ...fabBtn,
    backgroundColor: orange,
    color: 'white',
    borderWidth: '1px',
    borderColor: orange,
    left: 'calc(50% - 61px)',
    bottom: '20px', 
    height: '32px',
    flexDirection: 'row',

    '& > * + *': {
      fontSize: '16px',
      fontWeight: 600,

      [theme.breakpoints.down('sm')]: {
        fontSize: '13px',
      },
    },

    '&:hover': {
      backgroundColor: orange,
    },

    [theme.breakpoints.down('sm')]: {
      height: '32px',
    },
  },
  connectFabBtnIcon: {
    fontSize: '20px',
    color: 'white',
    marginRight: '4px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    minHeight: 400,
  },
  modalHeaderIcon: {
    fontSize: '20px',
    color: `${green}`,
    marginRight: '4px',
  },
  modalHeader: {
    fontSize: '22px',
    fontWeight: 700,
    color: `${green}`,
  },
  navLink: {
    color: `${tiffany}`,
  },
  backButton: {
    // mobile styles
    [theme.breakpoints.down('xs')]: {
      margin: '8px 0',
    },
    // tablet styles
    [theme.breakpoints.between('sm', 'md')]: {
      margin: '8px 0',
    },
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      marginBottom: '10px',
    },
    backgroundColor: `${navy}`,
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '12px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: `${navy}`,
    },

  },
  backButtonIcon: {
    fontSize: '17px',
    marginRight: '5px',
  },
  socialMediaButton: {
    margin: '10px',
    padding: '0',
    height: '45px',
    width: '45px',
  },
  DescriptionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '25px',
  },
  mapTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    // mobile styles
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px',
    },
  },

  unreadCountText: {
    fontSize: '11px',
    position: 'absolute',
    bottom: '15px',
    left: '17px',
    zIndex: '5',
    color: '#ffffff',
  },
  unreadIcon: {
    color: orange,
    fontSize: '16px',
    padding: '0',
    position: 'absolute',
    bottom: '15px',
    left: '12px',
  },
  unread: {
    position: 'absolute',
    top: '56%',
    left: '3%',
    transform: 'translateY(-50%)',
  },
  bubbleBtn: {
    padding: '0',
  },
  bubbleIcon: {
    color: '#243060',
  },
  boostDescriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    margin: '8px 0px',
  },
  boostBorderContainer: {
    border: `2px solid ${orange}`,
    borderRadius: '6px',
    padding: '5px',
    minWidth: '245px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  boostDescription: {
    marginTop: '12px',
  },

  messageCard: {
    padding: '10px 20px 10px 40px',
    borderBottom: '1px solid #D9D9D9',
    cursor: 'pointer',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardTitle: {
    fontFamily: 'Asap',
    fontSize: 16,
    fontWeight: 600,
    color: '#233160',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  },
  cardTitleActive: {
    fontFamily: 'Asap',
    fontSize: 16,
    fontWeight: 600,
    color: armyGreen,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    lineHeight: '19.2px',
    textTransform: 'capitalize',
  },
  active: {
    color: armyGreen,
  },
  cardSubTitle: {
    fontFamily: 'Asap',
    fontSize: 13,
    fontWeight: 400,
    color: '#64748B',
    textAlign: 'right',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cardBody: {
    fontFamily: 'Asap',
    fontSize: 13,
    fontWeight: 400,
    color: lightNavy,
  },
  msgJobTitle: {
    textTransform: 'capitalize',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '15.6px',
    ...ellipsis,
    maxWidth: '220px',
  },
  contentRight: {
    textAlign: 'right',
  },
  messageText: {
    fontFamily: 'Asap',
    fontSize: 13,
    fontWeight: 400,
    color: '#233160',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  },
  filterButton: {
    color: navy,
    border: `1px solid ${tiffany}`,
  },
  fastpassImg: {
    width: 20,
    height: 20,
    marginLeft: '5px',
  },
});
