import React, { Component } from 'react';
import moment from 'moment';
import Week from './Week';
import { defaultUtils as utils } from './dateUtils';
import { withStyles } from '@mui/styles';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    lineHeight: '1.25',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: '0px 5px 0px 5px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px 25px 0px 25px',
    },
  },
  largeSizeRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    lineHeight: '1.25',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: '0px 5px 0px 5px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px',
    },
  },
});

class Weeks extends Component {
  render() {
    const { classes } = this.props;
    return <div className={classes.largeSizeRoot}>{this.renderWeeks(this.props.displayDate)}</div>;
  }

  renderWeeks = () => {
    const { highlightedList, largeSize, isFastpass, boosted, surgeryNeededList, calendarData } = this.props;
    const weekArray = utils.getWeekArray(
      this.props.displayDate,
      moment.localeData().firstDayOfWeek(),
    );

    return weekArray.map(
      (s, i) => (
        <Week
          key={i}
          week={s}
          selectedDates={this.props.selectedDates}
          requestedDates={this.props.requestedDates}
          confirmedDates={this.props.confirmedDates}
          notAvailableDates={this.props.notAvailableDates}
          pendingDates={this.props.pendingDates}
          onSelect={this.props.onSelect}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          readOnly={this.props.readOnly}
          highlightedList={highlightedList}
          largeSize={largeSize}
          isFastpass={isFastpass}
          surgeryNeededList={surgeryNeededList}
          boosted={boosted}
          calendarData={calendarData}
        />
      ),
      this,
    );
  }
}

export default withStyles(styles)(Weeks);
