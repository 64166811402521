import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Icon,
  IconButton,
  Grid,
  Link,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Close } from '@mui/icons-material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import DomainIcon from '@mui/icons-material/Domain';
import PlaceIcon from '@mui/icons-material/Place';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { DialogStyles } from '../../css/dialogStyle';
import { formatPostTitle } from '../../utils/Functions';
import LoadingBar from './LoadingBar';
import { DESKTOP_BREAKPOINT } from '../constants/Constants';

class ConfirmedShiftsDialog extends Component {
  constructor() {
    super();
    this.state = {};
  }

  renderMobileContent = () => {
    const { classes, confirmedShiftsData, handleClose, history } = this.props;
    if (!confirmedShiftsData) return <LoadingBar size={20} noText />;
    if (isEmpty(confirmedShiftsData)) {
      return (
        <div style={{ textAlign: "center" }}>
          <Typography>No shifts</Typography>
        </div>
      );
    }

    return confirmedShiftsData.map((item) => (
      <Grid
        className={classes.infoGrid}
        direction="row"
        alignContent="space-between"
        container
        key={`${item.id}_container`}
      >
        <Grid item xs={12} key={`${item.id}_date_container`}>
          <Typography
            key={`${item.id}_date`}
            color="secondary"
            className={classes.infoTypography}
          >
            <span>{`${item.intervalNumber ? `[${item.intervalNumber}]` : ''}`}</span>{' '}{`${moment(item.date).format("MM/DD/YYYY")}`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          key={`${item.id}_practice_container`}
          className={classes.matchIteminfoItem}
        >
          <DomainIcon
            className={classes.linkIcon}
            key={`${item.id}_practice_icon`}
            fontSize="small"
          />
          <Typography key={`${item.id}_practice_name`}>
            {item.practiceName || "-"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          key={`${item.id}_location_container`}
          className={classes.matchIteminfoItem}
        >
          <PlaceIcon
            className={classes.linkIcon}
            key={`${item.id}_location_icon`}
            fontSize="small"
          />
          <Typography key={`${item.id}_location`}>
            {item.location || "-"}
          </Typography>
        </Grid>
        {item.match_id && (
          <Grid
            item
            xs={12}
            key={`${item.id}_postlink_container`}
            className={classes.matchIteminfoItem}
          >
            <DoubleArrowIcon
              className={classes.linkIcon}
              fontSize="small"
              key={`${item.id}_postlink_icon`}
            />
            <Link
              href="#"
              className={classes.link}
              underline="always"
              key={`${item.id}_postlink`}
              onClick={() => {
                localStorage.setItem("viewDetailsMatchIdStr", item.match_id);
                handleClose();
                history.replace({
                  pathname: `/matches/${item.job_posting_id}`,
                });
              }}
            >
              {formatPostTitle(item.jobPostingTitle)}
            </Link>
          </Grid>
        )}
        {item.match_id && item.status === "connected" && (
          <Grid
            item
            xs={12}
            key={`${item.id}_chatlink_container`}
            className={classes.matchIteminfoItem}
          >
            <Icon
              key={`${item.id}_chatlink_icon`}
              fontSize="small"
              className={classes.linkIcon}
            >
              chat_bubble
            </Icon>
            <Link
              href="#"
              className={classes.link}
              underline="always"
              key={`${item.id}_chatlink`}
              onClick={() => {
                handleClose();
                window.location.assign(`/messages/${item.match_id}`);
                history.replace({ pathname: `/messages/${item.match_id}` });
              }}
            >
              Jump to Chat
            </Link>
          </Grid>
        )}
      </Grid>
    ));
  };

  renderDesktopContent = () => {
    const { classes, confirmedShiftsData, handleClose, width, history } =
      this.props;

    return (
      <div className={classes.dataGridContainer}>
        <DataGrid
          autoHeight
          rows={confirmedShiftsData}
          columns={[
            {
              field: "intervalNumber",
              headerName: "Interval",
              width: 150,
              renderCell: (params) => (
                <Typography variant="body2">{params.row.intervalNumber || ''}</Typography>
              ),
            },
            {
              field: "date",
              headerName: "Relief Date",
              width: 150,
              renderCell: (params) => (
                <Typography variant="body2">{`${moment(params.row.date).format(
                  "MM/DD/YYYY"
                )}`}</Typography>
              ),
            },
            {
              field: "practiceName",
              headerName: "Practice Location",
              flex: 1,
              maxWidth: 370,
              renderCell: (params) => (
                <div>
                  <Typography variant="body2" key={`practice_${params.row.id}`}>
                    {params.row.practiceName || "-"}
                  </Typography>
                  <Typography variant="body2" key={`location_${params.row.id}`}>
                    {`(${params.row.location})` || ""}
                  </Typography>
                </div>
              ),
            },
            {
              field: "jobPostingTitle",
              headerName: "Job Posting",
              flex: 0.7,
              renderCell: (params) => {
                if (params.row.match_id) {
                  return (
                    <Link
                      href="#"
                      className={`${classes.link} ${classes.overflowEllipsis}`}
                      underline="always"
                      key={`postlink_${params.row.id}`}
                      onClick={() => {
                        handleClose();
                        history.replace({
                          pathname: `/matches/${params.row.job_posting_id}`,
                        });
                      }}
                    >
                      {formatPostTitle(params.row.jobPostingTitle)}
                    </Link>
                  );
                }
                return null;
              },
            },
            {
              field: "chatLink",
              headerName: "Chat Link",
              type: "actions",
              flex: 0.5,
              renderCell: (params) => {
                if (params.row.match_id && params.row.status === "connected") {
                  return (
                    <Link
                      href="#"
                      className={classes.link}
                      underline="always"
                      key={`chatlink_${params.row.id}`}
                      onClick={() => {
                        handleClose();
                        history.replace({
                          pathname: `/messages/${params.row.match_id}`,
                        });
                      }}
                    >
                      Jump to Chat
                    </Link>
                  );
                }
                return null;
              },
            },
          ]}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          disableColumnMenu
          isRowSelectable={() => false}
          localeText={{ noRowsLabel: "No shifts" }}
        />
      </div>
    );
  };

  render() {
    const {
      classes,
      confirmedShiftsData = [],
      handleClose,
      open,
      isDesktop,
      history,
    } = this.props;

    return (
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            padding: "24px 4px",
          },
        }}
        open={open}
        onClose={() => handleClose()}
      >
        <DialogTitle className={classes.gridDialogContent}>
          <div className={classes.titleContainer}>
            {isDesktop && (
              <AssignmentTurnedInIcon className={classes.titleIcon} />
            )}
            <Typography color="primary" className={classes.title}>
              Confirmed Shifts
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => handleClose()}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {isDesktop
            ? this.renderDesktopContent()
            : this.renderMobileContent()
          }
        </DialogContent>
      </Dialog>
    );
  }
}

ConfirmedShiftsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};

function ConfirmedShiftsDialogWrapper({ theme, ...rest }) {
  const isDesktop = theme ? useMediaQuery(theme.breakpoints.up('md')) : true;
  return <ConfirmedShiftsDialog {...rest} theme={theme} isDesktop={isDesktop} />;
}

export default withStyles(DialogStyles, { withTheme: true })(withRouter(ConfirmedShiftsDialogWrapper));
