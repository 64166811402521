import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Typography,
  Grid,
  ButtonBase,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material/';
import { withStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PlaceIcon from '@mui/icons-material/Place';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { navy, iconGray, bgGray, lightGray } from '../../css/style';
import { MatchesComponentStyle } from './styles/matchesComponentStyle';
import SavedSearchDialog from '../subcomponents/SavedSearchDialog';
import { renderPhoneVerificationModal } from '../core/renderHelper';
import { Redirect } from 'react-router';
import { DESKTOP_BREAKPOINT, IMG_STORAGE_LINK } from '../constants/Constants';

class MobileSearch extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props;

    this.state = {
      searchDrawerOpen: true,
      selectPracticeEnabled: user.privateAccess && (user.privateAccess.indexOf('HQ Admin') >= 0 || user.privateAccess.indexOf('Private User') >= 0),
      openSearchDialog: false,
      openPhoneVerification: false,
    };
  }

  componentDidMount = async () => {
    const { actions, user } = this.props;
    actions.getAllResources(user.token, 'savedsearchs');
    actions.getAllResources(user.token, 'practices');
    actions.getAllResources(user.token, 'talents');
  }

  getItemPractices = (item) => {
    const { practices, state } = item;
    if (practices && practices.length) {
      let practiceNames = practices.map(practice => practice.name);
      if (practiceNames.length > 3) {
        return `${practiceNames.slice(0, 3).join(', ')}...`;
      } else {
        return practiceNames.join(', ');
      }
    }
    return state || '';
  }

  getSavedSearchItem = (item) => {
    const {
      classes, user, actions, resources,
    } = this.props;
    const { selectPracticeEnabled } = this.state;
    const locationText = selectPracticeEnabled && !item.isDefaultRelief
      ? this.getItemPractices(item)
      : item.address;

    return (
      <Grid container item xs={12} className={classes.ssItem} key={`ss_${item.id}`}
        style={{ borderBottom: `1px solid ${lightGray}`, marginBottom: 10 }}
      >
        <Grid item xs={8} className={classes.ssItem}>
          <div
            key={`${item.id}_ss_icon`}
            style={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: `#${item.color}`,
              marginRight: '4px',
            }}
          />
          <Typography className={classes.searchTitle}>
            {item.title === 'Externship Work Near Me' ? 'Externships Near Me' : item.title}
          </Typography>
        </Grid>
        <Grid item xs={4} container justifyContent='flex-end'>
          {(item.hasEmail || item.hasSms) ? <NotificationsIcon color='secondary' /> : <NotificationsOffIcon style={{ color: iconGray }} />}
          <ButtonBase onClick={() => this.setState({ openSearchDialog: true, savedSearchItem: item, savedSearchId: item.id, enableDelete: true, nextColor: null })}>
            <Typography
              className={classes.navLink}
              key={`edit_ss_${item.id}`}
            >
              Edit
            </Typography>
          </ButtonBase>
        </Grid>
        <Grid item xs={12} className={classes.ssItem}>
          <PlaceIcon fontSize="small" className={classes.ssIcon} />
          <Typography className={classes.searchAddress}>{locationText}</Typography>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      classes, resources, actions, user, search, history, isDesktop,
    } = this.props;

    if (isDesktop) {
      return <Redirect to='/' />
    }

    const { savedsearchs = [] } = resources;
    const { mobileVerificationCode, openPhoneVerification } = this.state;

    const allSavedsearches = savedsearchs || [];

    return this.state.openSearchDialog ?
      (
        <SavedSearchDialog
          user={user}
          actions={actions}
          resources={resources}
          savedSearch={this.state.savedSearchItem}
          openPhoneVerification={() => this.setState({ openPhoneVerification: true })}
          handleClose={(isSaved) => this.setState({ openSearchDialog: false, snackOpen: isSaved })}
          enableDelete={this.state.enableDelete}
          form
          isMobile
        />
      ) : (
        <div>
          <Grid container
            className={classes.mobileSearchContainer}
          >
            <Grid item className={classes.drawerHeader}
              style={{ width: '100%', justifyContent: 'space-between', padding: '20px', backgroundColor: '#E1E5EA', borderBottom: `1px solid ${lightGray}` }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ButtonBase onClick={() => history.push('/matches')}>
                  <ChevronLeftIcon style={{ fontSize: '28px', color: navy }} />
                </ButtonBase>
                <Typography color="secondary" className={classes.drawerHeading}>Whisker Searches</Typography>
                <img src={`${IMG_STORAGE_LINK}wb_search.png`} className={classes.whiskerIcon} />
              </div>
              <IconButton
                onClick={() => {
                  this.setState({ savedSearchItem: null, openSearchDialog: true, enableDelete: false });
                }}
                size='small'>
                <ControlPointIcon size='small' color='primary' />
              </IconButton>
            </Grid>
            <Grid item className={classes.drawerContainer} style={{ maxHeight: '100vh' }}>
              {allSavedsearches.map((item) => this.getSavedSearchItem(item))}
            </Grid>
          </Grid>
          <div className={classes.snackBarRoot}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={this.state.snackOpen}
              onClose={() => this.setState({ snackOpen: false })}
              key='snackbar'
              autoHideDuration={4000}
            >
              <Alert
                variant="outlined" severity="success"
                style={{ backgroundColor: '#fff' }}
              >
                Changes Saved!
              </Alert>
            </Snackbar>
          </div>
          {renderPhoneVerificationModal({
            ...this.props,
            openPhoneVerification: openPhoneVerification,
            onClose: () => this.setState({ openPhoneVerification: false }),
            onOk: () => {
              const updatedUser = {
                mobileVerificationCode,
              };
              actions.updateResource('updateUserMobileCode', user.token, updatedUser, 'users', user.id)
                .then(this.setState({ openPhoneVerification: false }));
            },
            handleStateChange: (e) => this.setState({ mobileVerificationCode: e.target.value }),
          })
          }
        </div>
      );
  }
}

MobileSearch.propTypes = {};

function MobileSearchWrapper({ theme, ...rest }) {
  const isDesktop = theme ? useMediaQuery(theme.breakpoints.up('md')) : true;
  return <MobileSearch {...rest} theme={theme} isDesktop={isDesktop} />;
}

export default withStyles(MatchesComponentStyle, { withTheme: true })(withRouter(MobileSearchWrapper));