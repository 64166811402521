import ResourcesAPI from '../services/api/ResourcesApi';
import UsersAPI from '../services/api/UsersApi';
import types from './ActionTypes';

function updateLoadingState(type) {
  return {
    type,
  };
}

export function dataRetrievalFailure(error) {
  return {
    type: types.ERROR,
    error,
  };
}

export function displayErrorMessage(error) {
  return {
    type: types.RENDER_MESSAGE,
    error,
  };
}

function loginUserSuccess(user) {
  const loggedInUser = user.data;
  const resourcePath = ['users'];
  return {
    type: types.LOGIN_USER_SUCCESS,
    loggedInUser,
    resourcePath,
  };
}

function loginUserFailure(error) {
  return {
    type: types.LOGIN_USER_FAILURE,
    error,
  };
}

export function loginUser(user) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.LOGIN_USER_REQUEST));
    return UsersAPI.loginUser(user)
      .then(
        loggedInUser => dispatch(loginUserSuccess(loggedInUser)),
        error => dispatch(loginUserFailure(error)),
      );
  };
}

export function oauthLogin(search, oauthProvider) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.LOGIN_USER_REQUEST));
    return UsersAPI.oauthCallback(search, oauthProvider, 'login')
      .then(
        loggedInUser => dispatch(loginUserSuccess(loggedInUser)),
        error => dispatch(loginUserFailure(error)),
      );
  };
}

function registerUserSuccess(data) {
  const loggedInUser = data.data;
  const resourcePath = ['users'];
  return {
    type: types.REGISTER_USER_SUCCESS,
    loggedInUser,
    resourcePath,
  };
}

function registerUserFailure(error) {
  return {
    type: types.REGISTER_USER_FAILURE,
    error,
  };
}

export function registerUser(user) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.REGISTER_USER_REQUEST));
    return UsersAPI.signUpUser(user)
      .then(
        data => dispatch(registerUserSuccess(data)),
        error => dispatch(registerUserFailure(error)),
      );
  };
}

export function oauthRegister(search, oauthProvider) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.REGISTER_USER_REQUEST));
    return UsersAPI.oauthCallback(search, oauthProvider, 'signup')
      .then(
        data => dispatch(registerUserSuccess(data)),
        error => dispatch(registerUserFailure(error)),
      );
  };
}


function verifyUserSuccess(user, resourcePath) {
  const loggedInUser = user.data;
  return {
    type: types.VERIFY_USER_SUCCESS,
    loggedInUser,
    successMsg: 'Your account has been verified.',
  };
}

function verifyUserFailure(error) {
  return {
    type: types.VERIFY_USER_FAILURE,
    error,
  };
}

// Verify user
export function verifyUser(resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.VERIFY_USER_REQUEST));
    return ResourcesAPI.addResource(null, {}, resourcePath)
      .then(
        (verifiedRecoveredUser) => {
          dispatch(verifyUserSuccess(verifiedRecoveredUser, resourcePath));
          return verifiedRecoveredUser.data;
        },
        error => dispatch(verifyUserFailure(error)),
      );
  };
}

function verifyAccountEmailFailure(error) {
  return {
    type: types.VERIFY_ACCOUNT_EMAIL_FAILURE,
    error: 'Unable to resend email, please try again later.',
  };
}

function sendVerifyAccountEmailSuccess() {
  return {
    type: types.VERIFY_ACCOUNT_EMAIL_SUCCESS,
    successMsg: 'Email has been resent.',
  };
}

function sendVerifyAccountSmsFailure(error) {
  return {
    type: types.VERIFY_ACCOUNT_SMS_FAILURE,
    error: 'Unable to resend SMS verification code, please try again',
  };
}

function sendVerifyAccountSmsSuccess() {
  return {
    type: types.VERIFY_ACCOUNT_SMS_SUCCESS,
    successMsg: 'SMS Verification code has been resent',
  };
}

export function sendVerifyAccountEmail(user, ...resourcePath) {
  return dispatch => (ResourcesAPI.addResource(user.token, user, resourcePath)
    .then((response) => {
      dispatch(sendVerifyAccountEmailSuccess());
    }, error => dispatch(verifyAccountEmailFailure(error)))
  );
}

export function sendVerifyAccountSms(user, ...resourcePath) {
  return dispatch => (ResourcesAPI.addResource(user.token, user, resourcePath)
    .then((response) => {
      dispatch(sendVerifyAccountSmsSuccess());
    }, error => dispatch(sendVerifyAccountSmsFailure(error)))
  );
}

export function logoutUser() {
  return {
    type: types.LOGOUT_USER,
  };
}

function sendVerificationTokenSuccess() {
  return {
    type: types.SEND_VERIFICATION_TOKEN_SUCCESS,
    successMsg: 'A new verification token has been sent.',
  };
}

function sendVerificationTokenFailure(error) {
  return {
    type: types.SEND_VERIFICATION_TOKEN_FAILURE,
    error,
  };
}

export function sendVerificationToken(userObj, resourcePath, apiToken = '') {
  return (dispatch) => {
    dispatch(updateLoadingState(types.SEND_VERIFICATION_TOKEN_REQUEST));
    return ResourcesAPI.addResource(apiToken, userObj, resourcePath)
      .then(
        () => dispatch(sendVerificationTokenSuccess()),
        error => dispatch(sendVerificationTokenFailure(error)),
      );
  };
}

function sendResetPasswordTokenFailure(error) {
  return {
    type: types.SEND_RESET_PASSWORD_TOKEN_FAILURE,
    error,
  };
}

function sendResetPasswordTokenSuccess(response) {
  return {
    user: response.data,
    type: types.SEND_RESET_PASSWORD_TOKEN_SUCCESS,
    successMsg: 'A new recovery token has been sent.',
  };
}

export function sendResetPasswordToken(userObj, path, apiToken = '') {
  return (dispatch) => {
    dispatch(updateLoadingState(types.SEND_RESET_PASSWORD_TOKEN_REQUEST));
    return ResourcesAPI.addResource(apiToken, userObj, path)
      .then(
        response => dispatch(sendResetPasswordTokenSuccess(response)),
        error => dispatch(dataRetrievalFailure(error)),
      );
  };
}

function resetPasswordSuccess(response, resourcePath) {
  const loggedInUser = response.data.data;

  // TODO: Should be updated if we want user to be logged in (Also requires API change)
  return {
    type: types.RESET_PASSWORD_SUCCESS,
    loggedInUser,
    resourcePath,
    successMsg: 'Successfully updated your password.',
  };
}

function resetPasswordFailure(error) {
  return {
    type: types.RESET_PASSWORD_FAILURE,
    error,
  };
}

export function resetPassword(user, resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.RESET_PASSWORD_REQUEST));
    return ResourcesAPI.addResource(null, user, resourcePath)
      .then(
        response => dispatch(resetPasswordSuccess(response, resourcePath)),
        error => dispatch(resetPasswordFailure(error)),
      );
  };
}

export function clearMessage() {
  return {
    type: types.CLEAR_MESSAGE,
  };
}

export function setError(error) {
  return {
    type: types.ERROR,
    error,
  };
}

export function clearFileReviews() {
  return {
    type: types.CLEAR_FILE_REVIEWS,
  };
}

function getOneResourceSuccess(response, resourcePath) {
  return {
    type: types.GET_ONE_RESOURCE_SUCCESS,
    response,
    resourcePath,
  };
}

function getOneResourceFailure(error) {
  return {
    type: types.GET_ONE_RESOURCE_FAILURE,
    error,
  };
}

export function getOneResource(apiToken, ...resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.GET_ONE_RESOURCE_REQUEST));
    return ResourcesAPI.getResource(apiToken, resourcePath)
      .then(response => dispatch(getOneResourceSuccess(
        response.data, resourcePath,
      )),
      error => dispatch(getOneResourceFailure(error)));
  };
}

function getAllResourcesFailure(error, resourcePath) {
  return {
    type: types.GET_ALL_RESOURCES_FAILURE,
    error,
    resourcePath,
  };
}

function getAllResourcesSuccess(response, resourcePath) {
  if (resourcePath && resourcePath.length && resourcePath[0] === 'clients') {
    let connectedCount = 0;
    response.forEach(t => connectedCount += (t.credentials ? 1 : 0));
  }
  return {
    type: types.GET_ALL_RESOURCES_SUCCESS,
    response,
    resourcePath,
  };
}

export function clear401Message() {
  return {
    type: types.CLEAR_401_MESSAGE,
  };
}

export function getAllResources(apiToken, ...resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.GET_ALL_RESOURCES_REQUEST));
    return ResourcesAPI.getAllResources(apiToken, resourcePath)
      .then(
        response => dispatch(getAllResourcesSuccess(response.data, resourcePath)),
        error => dispatch(getAllResourcesFailure(error, resourcePath)),
      );
  };
}

export function search(q, location, positionType) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.GET_ALL_RESOURCES_REQUEST));
    return ResourcesAPI.getAllResources('', [`search?q=${q}&location=${location}&positionType=${positionType}`])
      .then(
        response => dispatch(getAllResourcesSuccess(response.data, ['search'])),
        error => dispatch(getAllResourcesFailure(error)),
      );
  };
}

function addResourceSuccess(resourceType, response, resourcePath) {
  let successMessage = `${resourceType || 'Resource'} has been added.`;
  return {
    type: types.ADD_RESOURCE_SUCCESS,
    response,
    resourcePath,
    successMsg: successMessage,
  };
}

function addResourceFailure(error) {
  return {
    type: types.ADD_RESOURCE_FAILURE,
    error,
  };
}

export function addResource(resourceType, apiToken, addedResource, ...resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.ADD_RESOURCE_REQUEST));
    return ResourcesAPI.addResource(apiToken, addedResource, resourcePath)
      .then(
        (response) => {
          dispatch(addResourceSuccess(resourceType, response.data, resourcePath));
          return response.data;
        },
        error => dispatch(addResourceFailure(error)),
      );
  };
}

function updateResourceSuccess(resourceType, response, resourcePath) {
  let successMessage;
  const noSuccessMessage = [
    'jobpostingsClickCount', 'updateMatchStatus', 'messages', 'savedsearchs', 'talentpiis', 'updateUserMobileCode',
  ];
  if (resourceType === 'disconnectQB') {
    successMessage = 'Client is disconnected.';
  } else if (noSuccessMessage.includes(resourceType)) {
    // No success message when updating job posting click count, updating match status or messages
    successMessage = null;
  } else {
    successMessage = `${resourceType || 'Resource'} has been updated.`;
  }

  if (resourcePath.includes('users') || resourceType === 'talentPII') {
    return {
      type: types.UPDATE_USER_SUCCESS,
      loggedInUser: response,
      resourcePath,
      successMsg: successMessage,
      resourceType,
    };
  }

  return {
    type: types.UPDATE_RESOURCE_SUCCESS,
    response,
    resourcePath,
    successMsg: successMessage,
  };
}

function updateResourceFailure(error) {
  return {
    type: types.UPDATE_RESOURCE_FAILURE,
    error,
  };
}

export function updateResource(resourceType, user, updatedResource, ...resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.UPDATE_RESOURCE_REQUEST));
    return ResourcesAPI.updateResource(user, updatedResource, resourcePath)
      .then(
        response => dispatch(updateResourceSuccess(resourceType, response.data, resourcePath)),
        error => dispatch(updateResourceFailure(error)),
      );
  };
}

function deleteResourceSuccess(resourceType, response, resourcePath) {
  let successMessage = `${resourceType || 'Resource'} has been deleted.`;
  return {
    type: types.DELETE_RESOURCE_SUCCESS,
    response,
    resourcePath,
    successMsg: successMessage,
  };
}

function deleteResourceFailure(error) {
  return {
    type: types.DELETE_RESOURCE_FAILURE,
    error,
  };
}

export function deleteResource(resourceType, user, ...resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.DELETE_RESOURCE_REQUEST));
    return ResourcesAPI.deleteResource(user, resourcePath)
      .then(
        response => dispatch(deleteResourceSuccess(resourceType, response.data, resourcePath)),
        error => dispatch(deleteResourceFailure(error)),
      );
  };
}

export function updateUserRegistrationSuccess(data) {
  return {
    type: types.UPDATE_USER_REGISTRATION_SUCCESS,
    data,
  };
}

export function updateUserRegistrationFailure(error) {
  return {
    type: types.UPDATE_USER_REGISTRATION_FAILURE,
    error,
  };
}

export function updateUserRegistration(apiToken, talent) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.GET_USER_REGISTRATION));
    return ResourcesAPI.addResource(apiToken, { talent }, ['users', 'update-user-registration'])
      .then(
        response => dispatch(updateUserRegistrationSuccess(response.data)),
        error => dispatch(updateUserRegistrationFailure(error)),
      );
  };
}

export function calcAssignBadges(apiToken, addedResource, ...resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.ADD_RESOURCE_REQUEST));
    return ResourcesAPI.addResource(apiToken, addedResource, resourcePath).then(() => ({ type: types.ADD_RESOURCE_SUCCESS }));
  };
}