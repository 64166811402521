import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';

const _filterOptions = createFilterOptions({
  limit: 5,
  stringify: option => option.name,
});

const filterOptions = (option, state, list) => {
  const results = [...(_filterOptions(option, state))];
  const defaultOption = list.find(x => x.id === 404);
  if (results.length === 0) {
    results.push(defaultOption);
  }

  return results;
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    fontSize: '14px',
    backgroundColor: '#ffffff',

    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      '& .MuiAutocomplete-input': {
        paddingTop: 5,
        paddingBottom: 5,
      },
    },

    '& .MuiAutocomplete-input': {
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.87)',
        opacity: 1,
        '&:focus': {
          opacity: 0.5,
        },
      },
    },

    '& .Mui-focused': {
      '& .MuiAutocomplete-input': {
        '&::placeholder': {
          color: 'rgba(0, 0, 0, 0.87)',
          opacity: 0.5,
        },
      },
    },
  },
});

export default function SchoolAutocomplete(props) {
  const [schoolList] = React.useState(props.schoolList)
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState(() => {
    const { initState } = props;
    let schoolId;
    if (initState) {
      initState.forEach(item => schoolId = item.numValue);
    }
    const validSchool = schoolList && schoolList.find(s => s.id === Number(schoolId));
    return schoolId ? validSchool || schoolList.find(s => s.id === 404) : {};
  });
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  React.useEffect(() => {
    const { criteria, handleStateChange } = props;
    const items = [{ criteria, numValue: value ? value.id : '' }];
    handleStateChange(items);
  }, [value]);
  React.useEffect(() => {
    const { schoolList } = props;
    if (!inputValue || inputValue.length <= 1) {
      setOptions([]);
      setOpen(false);
      return;
    }
    // Prevent the dropdown open right after the page loaded with initial data
    const preloadedSchool = schoolList && schoolList.filter(c => c.name.toLowerCase() === inputValue.toLowerCase());
    if (inputValue === 'Other' || !isEmpty(preloadedSchool)) {
      setOpen(false);
      setOptions(preloadedSchool);
      return;
    }
    let filteredSchools = schoolList && schoolList.filter(c => c.name.toLowerCase().includes(inputValue.toLowerCase()));
    setOptions(filteredSchools || []);
    setOpen(true);
  }, [inputValue]);
  return (
    <Autocomplete
      id="school-autocomplete"
      classes={classes}
      open={open}
      onOpen={() => {
        setOpen(inputValue.length > 1);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={option => (option.name ? option.name : '')}
      getOptionSelected={(option, value) => (value.name ? option.name === value.name : true)}
      filterOptions={(option, state) => filterOptions(option, state, schoolList)}
      inputValue={inputValue}
      value={value}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        if (event && event.type === 'change') {
          const inputSchool = schoolList && schoolList.find(c => c.name.toLowerCase() === newInputValue.toLowerCase());
          if (inputSchool) {
            setValue(inputSchool);
          }
        }
      }}
      clearOnBlur={!(schoolList && schoolList.find(c => c.name.toLowerCase() === inputValue.toLowerCase()))}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      noOptionsText="No Schools"
      options={options}
      popupIcon={null}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          placeholder=''
          onFocus={e => e.target.placeholder = 'Type letters'}
          onBlur={e => e.target.placeholder = ''}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      disabled={props.disabled}
    />
  );
}
