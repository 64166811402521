export const DesciplineComponentStyle = theme => ({
  title: {
    color: '#243060',
    display: 'flex',
    fontFamily: 'Asap',
    fontWeight: 'bold',
    alignItems: 'flex-end',
    justifyContent: 'center',
    fontSize: '20px',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textTransform: 'uppercase',
    padding: 8,
    marginBottom: 12,
  },
  gridContainer: {
    justifyContent: 'center',
    marginBottom: 20,
  },
  square: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '10px 10px',
    userSelect: 'none',
    [theme.breakpoints.down('xs')]: {
      width: 135,
      height: 135,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 156,
      height: 156,
    },
    [theme.breakpoints.up('lg')]: {
      width: 175,
      height: 175,
    },
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
    '&:active': {
      backgroundColor: '#D3D3D3',
    },
  },
  squareText: {
    fontWeight: 700,
    textTransform: 'uppercase',
    wordBreak: 'break-word',
  },
  selectedSquare: {
    '& img': {
      filter: 'brightness(100)',
    },
    width: 156,
    height: 156,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '10px 10px',
    userSelect: 'none',
    [theme.breakpoints.down('xs')]: {
      width: 135,
      height: 135,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 156,
      height: 156,
    },
    [theme.breakpoints.up('lg')]: {
      width: 175,
      height: 175,
    },
  },
  disabledSquare: {
    color: '#A6A6A6',
    cursor: 'default',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '10px 10px',
    backgroundColor: '#D3D3D3',
    pointerEvents: 'none',
    userSelect: 'none',
    [theme.breakpoints.down('xs')]: {
      width: 135,
      height: 135,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 156,
      height: 156,
    },
    [theme.breakpoints.up('lg')]: {
      width: 175,
      height: 175,
    },
  },
});
