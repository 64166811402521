import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { textColor } from '../../css/style';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      '& > *': {
        width: 36,
        margin: `0 ${theme.spacing(1)}px`,
      },
      padding: '0px 25px 0px 25px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px 5px 0px 5px',
    },
    fontFamily: 'Asap',
    fontSize: '11px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: textColor,

  },
  largeSizeRoot: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

    '& > *': {
      fontSize: '13px',
      fontWeight: 500,
    },

    // mobile styles
    [theme.breakpoints.down('xs')]: {
      padding: '0px 18px 0px 18px',
    },
    // tablet styles
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '0px 22px 0px 22px',
    },
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      '& > *': {
        width: 36,
        margin: `0 ${theme.spacing(1)}px`,
      },
      padding: '0px',
    },
    color: textColor,
  },
}));

const Week = props => <Typography {...props} />;

const WeekHeader = (props) => {
  const { largeSize } = props;
  const classes = useStyles();
  moment.updateLocale('en', {
    week: {
      dow: 0,
    },
  });
  moment.updateLocale('en', {
    weekdaysShort : ["S", "M", "T", "W", "T", "F", "S"]
  });
  const weekdayNames = moment.weekdaysShort(true);

  return (
    <div className={largeSize ? classes.largeSizeRoot : classes.root}>
      {
        weekdayNames.map((name, i) => (
            <Week key={`day_${i}`}>{name}</Week>
        ))
      }
    </div>
  );
};

export default WeekHeader;
