import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OnboardingItemComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onboardingItems: [{
        criteria: this.props.criteria,
        strValue: '',
        str2Value: '',
        numValue: null,
        boolValue: null,
      }],
    };
  }

  setStrValue(strValue) {
    this.set({ strValue });
  }

  setStr2Value(str2Value) {
    this.set({ str2Value });
  }

  setNumValue(numValue) {
    this.set({ numValue });
  }

  setBoolValue(boolValue) {
    this.set({ boolValue });
  }

  set(obj) {
    const onboardingItems = this.state.onboardingItems;
    Object.keys(obj).forEach(key => onboardingItems[key] = obj[key]);
    setState({ onboardingItems }, () => this.props.handleStateChange(this.getOnboardingItems()));
  }

  getOnboardingItems() {
    return [this.state.onboardingItems];
  }
}

OnboardingItemComponent.propTypes = {
  handleStateChange: PropTypes.func.isRequired,
};
export default OnboardingItemComponent;
