import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { orangeLight, orange, green } from '../../css/style';
import { Typography, ButtonBase } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useCircleStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      width: 28,
      height: 28,
    },
    [theme.breakpoints.down('lg')]: {
      width: '24px!important',
      height: 24,
    },
    borderRadius: '50%',
    background: 'rgba(0, 0, 0, 0)',
  },
  rootText: {
    color: theme.palette.text.primary,
  },
  rootTextDisabled: {
    color: theme.palette.text.disabled,
  },
  pendingText: {
    color: theme.palette.common.white,
  },
  todayRoot: {
    background: theme.palette.background.default,
  },
  checkedRoot: {
    background: green,
  },
  requestedRoot: {
    background: '#243060',
  },
  takeShiftRoot: {
    background: orangeLight,
  },
  checkedRootDisabled: {
    background: theme.palette.action.disabled,
  },
  confirmedRoot: {
    background: '#243060',
    borderRadius: '10%',
  },
  confirmedText: {
    color:
      theme.palette.type === 'dark'
        ? theme.palette.getContrastText(theme.palette.primary.main)
        : theme.palette.common.white,
  },
  checkedText: {
    color:
      theme.palette.type === 'dark'
        ? theme.palette.getContrastText(theme.palette.primary.main)
        : theme.palette.common.white,
  },
  requestedText: {
    color:
      theme.palette.type === 'dark'
        ? theme.palette.getContrastText(theme.palette.primary.main)
        : theme.palette.common.white,
  },
  todayText: {
    fontWeight: '700 !important',
  },
  text: {
    textAlign: 'center',
  },
  notAvailable: {
    border: `1px solid ${orange}`,
    color: `${orange} !important`,
  },
  pending: {
    background: '#9E9E9E !important',
    border: 'none',
  },
  highlight: props => ({
    boxShadow: props.confirmed ? '0 0 0 0.2rem rgba(75,189,173,.8)' : '0 0 0 0.2rem rgba(36,48,96,.8)',
  }),
}));

const Circle = ({
  label, disabled, checked, requested, onCheck, className, isToday, isNotAvailable, isPending, isHighLighted, isFastpass, confirmed,
}) => {
  const styleProps = { confirmed };
  const classes = useCircleStyles(styleProps);

  const handleClick = useCallback(
    () => {
      if (!disabled) {
        onCheck(!checked);
      }
    },
    [onCheck, disabled, checked],
  );

  return (
    <ButtonBase
      className={classNames(
        classes.root,
        {
          [classes.rootText]: !checked && !disabled,
          [classes.todayRoot]: isToday,
          [classes.confirmedRoot]: confirmed,
          [classes.checkedRoot]: checked,
          [isFastpass ? classes.takeShiftRoot : classes.requestedRoot ]: requested,
          [classes.notAvailable]: isNotAvailable,
          [classes.pending]: isPending,
          [classes.highlight]: isHighLighted,
        },
        className,
      )}
      disabled={disabled}
      onClick={handleClick}
    >
      <Typography
        color='inherit'
        variant='body1'
        className={classNames(classes.text, {
          [classes.confirmedText]: confirmed,
          [classes.checkedText]: checked,
          [classes.requestedText]: requested,
          [classes.pendingText]: isPending,
          [classes.todayText]: isToday,
        })}
      >
        {label}
      </Typography>
    </ButtonBase>
  );
};

Circle.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  confirmed: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  requested: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  className: PropTypes.string,
  isPending: PropTypes.bool,
  isHighLighted: PropTypes.bool,
};

export default Circle;
