import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { PropTypes } from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { BackButtonStyle } from '../../css/style';
import { IMG_STORAGE_LINK } from '../constants/Constants';

export const BackButton = (props) => {
  const {
    classes,
    history,
    onClick,
    customText,
  } = props;
  return (
    <div className={classes.container}>
      <Typography className={classes.back} onClick={e => (onClick ? onClick(e) : history.goBack())}>
        <img
          src={`${IMG_STORAGE_LINK}keyboard-backspace.svg`}
          alt="back"
          className={classes.backIcon}
        />
        {customText || 'Back'}
      </Typography>
    </div>
  );
};

BackButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  history: PropTypes.shape({
    action: PropTypes.string,
    location: PropTypes.object,
    length: PropTypes.number,
    push: PropTypes.func,
  }).isRequired,
  onClick: PropTypes.func,
};

export default withStyles(BackButtonStyle)(withRouter(BackButton));
