import Immutable from 'seamless-immutable';
import types from '../actions/ActionTypes';

const initialState = Immutable({
  user: null,
});

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case types.REGISTER_USER_SUCCESS:
    case types.LOGIN_USER_SUCCESS:
    case types.VERIFY_USER_SUCCESS:
      const userData = { ...action.loggedInUser, isInvitedUser: true }
      localStorage.setItem('user', JSON.stringify(userData));
      return Immutable.merge(state, { user: action.loggedInUser });
    case types.UPDATE_USER_SUCCESS:
      if (action.resourceType === 'talentPII') {
        return Immutable.setIn(state, ['user', 'talentPII'], action.loggedInUser);
      }
      const currentUser = (localStorage.getItem('user') !== null
        ? JSON.parse(localStorage.getItem('user'))
        : {});
      const user = Object.assign(currentUser, action.loggedInUser);
      localStorage.setItem('user', JSON.stringify(user));
      return Immutable.merge(state, { user }, { deep: true });
    case types.UPDATE_USER_REGISTRATION_SUCCESS:
      let localUser = (localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')) : (user && user.user) ? user.user : null);

      if (action.data && action.data.email) {
        localUser.email = action.data && action.data.email;
      }

      if (action.data && action.data.isCompleted && !localUser.isInvitedUser) {
        localUser.isCompleted = action.data.isCompleted;
      }
      localStorage.setItem('user', JSON.stringify(localUser));
      return Immutable.merge(state, { localUser }, { deep: true });
    case types.LOGIN_USER_FAILURE:
    case types.REGISTER_USER_FAILURE:
    case types.VERIFY_USER_FAILURE:
    case types.LOGOUT_USER:
    case types.UPDATE_USER_REGISTRATION_FAILURE:
      localStorage.clear();
      return Immutable.merge(state, { ...initialState });
    default:
      return state;
  }
}
