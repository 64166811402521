import React, { useReducer, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateUtilities from './utils';
import { makeStyles } from '@mui/styles';
import CalendarDetailsPage from './CalendarDetailsPage';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    width: 500,
    minHeight: 450,
    display: 'flex',
  },
}));

function initState(pendingDates, selectedDates, notAvailableDates) {
  return {
    requestedDates: [],
    selectedDates: selectedDates ? [...selectedDates] : [],
    notAvailableDates: notAvailableDates ? [...notAvailableDates] : [],
    minDate: null,
    maxDate: null,
    pendingDates: pendingDates ? [...pendingDates] : [],
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'setRequestedDates':
      return { ...state, requestedDates: action.payload };
    case 'setSelectedDates':
      return { ...state, selectedDates: action.payload };
    case 'setNotAvailableDates':
      return { ...state, notAvailableDates: action.payload };
    default:
      return new Error('wrong action type in multiple date picker reducer');
  }
}

const CalendarContainer = ({
  isMobile,
  readOnly,
  onSubmit,
  initialDate,
  declinedTrackingDates = [],
  requestedDates: outerRequestedDates,
  selectedDates: outerSelectedDates,
  notAvailableDates: outerNotAvailableDates,
  confirmedDates,
  cancelButtonText,
  submitButtonText = 'Submit',
  selectedDatesTitle = 'Dates Requested:',
  pendingDates,
  disabledConnect = false,
  isFastpass = false,
  chatButtonText,
  allConfirmedDates,
  calendarData
}) => {
  if (cancelButtonText == null) {
    cancelButtonText = readOnly ? 'Dismiss' : 'Cancel';
  }


  const [{
    requestedDates, selectedDates, notAvailableDates, minDate, maxDate,
  }, dispatch] = useReducer(
    reducer,
    [outerRequestedDates,
      outerSelectedDates,
      outerNotAvailableDates],
    initState,
  );
  const classes = useStyles();
  const [warningMsg, setWarningMsg] = useState('');

  const onSelect = useCallback(
    (day) => {
      if (readOnly) return;
      if (DateUtilities.dateIn(selectedDates, day) && !isEmpty(declinedTrackingDates) && DateUtilities.dateIn(declinedTrackingDates, day)) {
        setWarningMsg(`We have decided to pursue another worker for ${moment(day).format('MM/DD')}.`);
        return;
      } else if (DateUtilities.dateIn(selectedDates, day) && allConfirmedDates && DateUtilities.dateIn(allConfirmedDates, day)) {
        setWarningMsg(`${moment(day).format('MM/DD')} committed elsewhere`);
        return;
      } else {
        setWarningMsg(null);
      }
      if (DateUtilities.dateIn(selectedDates, day)) {
        dispatch({
          type: 'setRequestedDates',
          payload: [...requestedDates, day],
        });
        dispatch({
          type: 'setSelectedDates',
          payload: selectedDates.filter(date => !DateUtilities.isSameDay(date, day)),
        });
      } else if (DateUtilities.dateIn(requestedDates, day)) {
        dispatch({
          type: 'setSelectedDates',
          payload: [...selectedDates, day],
        });
        dispatch({
          type: 'setRequestedDates',
          payload: requestedDates.filter(date => !DateUtilities.isSameDay(date, day)),
        });
      }
    },
    [requestedDates, selectedDates, notAvailableDates, dispatch, readOnly, allConfirmedDates],
  );

  const onRemoveAtIndex = useCallback(
    (index) => {
      if (readOnly) return;
      const newDates = [...selectedDates];
      if (index > -1) {
        newDates.splice(index, 1);
      }

      dispatch({ type: 'setSelectedDates', payload: newDates });
    },
    [selectedDates, dispatch, readOnly],
  );

  const dismiss = useCallback(
    () => {
      if (readOnly) return;
      dispatch({ type: 'setSelectedDates', payload: [...selectedDates, ...requestedDates] });
      dispatch({ type: 'setRequestedDates', payload: [] });
    },
    [dispatch, requestedDates, selectedDates],
  );

  const handleCancel = useCallback(
    (e) => {
      e.preventDefault();
      dismiss();
    },
    [dismiss],
  );

  const handleOk = useCallback(
    (e) => {
      e.preventDefault();
      if (readOnly) return;
      onSubmit(selectedDates, notAvailableDates, requestedDates);
    },
    [onSubmit, selectedDates, notAvailableDates, readOnly],
  );

  const handleChat = useCallback(
    (e) => {
      e.preventDefault();
      if (readOnly) return;
      onSubmit(selectedDates, notAvailableDates, requestedDates, true);
    },
    [onSubmit, selectedDates, notAvailableDates, readOnly],
  );

  useEffect(
    () => {
      dispatch({
        type: 'setSelectedDates',
        payload: outerSelectedDates != null ? outerSelectedDates : [],
      });
      dispatch({
        type: 'setNotAvailableDates',
        payload: outerNotAvailableDates != null ? outerNotAvailableDates : [],
      });
      dispatch({
        type: 'setRequestedDates',
        payload: [],
      });
    },
    [outerSelectedDates, outerNotAvailableDates],
  );

  return (
    <CalendarDetailsPage
      availableDates={selectedDates}
      initialDate={initialDate}
      notAvailableDates={notAvailableDates}
      requestedDates={requestedDates}
      confirmedDates={confirmedDates}
      pendingDates={pendingDates}
      onSelect={onSelect}
      onRemoveAtIndex={onRemoveAtIndex}
      onRemoveAll={dismiss}
      minDate={minDate}
      maxDate={maxDate}
      onClearAll={handleCancel}
      onOk={handleOk}
      onChat={handleChat}
      readOnly={readOnly}
      cancelButtonText={cancelButtonText}
      submitButtonText={submitButtonText}
      selectedDatesTitle={selectedDatesTitle}
      disabledConnect={disabledConnect}
      isFastpass={isFastpass}
      chatButtonText={chatButtonText}
      warningMsg={warningMsg}
      isMobile={isMobile}
      calendarData={calendarData}
    />
  );
};

CalendarContainer.propTypes = {
  readOnly: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedDates: PropTypes.array,
  pendingDates: PropTypes.array,
  cancelButtonText: PropTypes.string,
  submitButtonText: PropTypes.string,
  selectedDatesTitle: PropTypes.string,
};

export default CalendarContainer;
