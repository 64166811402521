import React from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';

const releaseNoteDialogStyles = theme => ({
  title: {
    marginBottom: '30px',
    align: 'left',
  },
  subTitle: {
    marginBottom: '10px',
  },
  releaseDate: {
    fontSize: '14px',
    display: 'block',
  },
  content: {
    fontSize: 16,
    margin: '12px 0px',
  },
});

const styles = releaseNoteDialogStyles();

const ReleaseNoteDialog = ({
  isDialogOpen,
  onCloseDialog,
  content,
}) => (
  <Dialog
    maxWidth="xl"
    open={isDialogOpen}
    onClose={() => onCloseDialog()}
  >
    <DialogContent>
      <Typography color="secondary" variant='h4' style={styles.title}>
        Release Update
      </Typography>
      {content && (
        <div>
          <Typography color="secondary" variant='h6' style={styles.subTitle}>
            Version:
            {' '}
            {content.version}
            <div style={styles.releaseDate}>{content.releaseDate}</div>
          </Typography>
          <Typography
            style={styles.content}
            dangerouslySetInnerHTML={{ __html: content.content }}
          />
        </div>
      )}
    </DialogContent>
  </Dialog>
);

export default ReleaseNoteDialog;
