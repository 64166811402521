import moment from 'moment';
import React, { Component } from 'react';
import { Prompt } from 'react-router';
import { withRouter } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import { uniq, isEmpty, isEqual, sortBy } from 'lodash';
import { Alert, Box, ButtonBase, Dialog, Drawer, Fab, Grid, IconButton, Slide, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { isReliefOrExternshipType, formatPostTitle, getJobRoleText, countUnreadMessagesByMatch } from '../../utils/Functions';
import DateUtilities from '../Datepicker/utils';
import MessagesInputBox from './subcomponents/MessageInputBox';
import RefreshPageDialog from '../subcomponents/RefreshPageDialog';
import { MessagesStyles } from './styles/messagesStyle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilterListIcon from '@mui/icons-material/FilterList';
import PlaceIcon from '@mui/icons-material/Place';
import classNames from 'classnames';
import MessageBubble from './subcomponents/MessageBubble';
import LocationGoogleMap from '../subcomponents/LocationGoogleMap';
import ShiftCalendarDialog from '../subcomponents/ShiftCalendarDialog';
import JobDetailsDialog from '../subcomponents/JobDetailsDialog';
import { IMG_STORAGE_LINK, DESKTOP_BREAKPOINT, CORONADO_BADGE_TITLE } from '../constants/Constants';
import SingleCalendarPicker from '../Datepicker/SingleCalendarPicker';
import LensIcon from '@mui/icons-material/Lens';
import { orangeLight, bgLightGray } from '../../css/style';
import EventIcon from '@mui/icons-material/Event';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

const TIMEOUT_DURATION = 400;

const fastpassIcon = `${IMG_STORAGE_LINK}green-ticket.png`;

const UpTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobTitle: '',
      practiceName: '',
      messages: [],
      newMessage: '',
      pendingDates: [],
      isDeleted: false,
      datePicker: false,
      loadingMessages: false,
      openRefreshDialog: false,
      isLeftDrawerOpened: true,
      isRightDrawerOpened: true,
      messageActivated: null,
      jobPosting: null,
      practice: null,
      markers: [],
      messagesResponse: null,
      openMobileChat: false,
      openMobleDetail: false,
      isShowMobileCalenderPopup: false,
      isLoading: false,
      isNewOnly: false,
      newMessageCards: [],
      allMessages: [],
      dateRequested: undefined,
    };
  }

  componentDidUpdate = async (prevProps) => {
    const { resources, match } = this.props;
    const { matchId } = match.params; // resourceId is connection id
    const { messages } = resources;
    if (prevProps.resources && resources && !isEqual(prevProps.resources.messages, messages)) {
      const preMsgsForTheMatch = prevProps.resources.messages && prevProps.resources.messages.filter(msg => msg?.match?.id === parseInt(matchId));
      if (isEmpty(preMsgsForTheMatch)) { //only update msg for newly connecting user
        await this.getMessages();
      }
    }

    if (matchId !== prevProps.match.params.matchId) {
      this.setState({ isLoading: true });
      await this.getMessages();
    }
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    await this.getMessageList();
    await this.getMessages();
  }

  getMessageList = async () => {
    const { actions, user, match } = this.props;
    actions.getAllResources(user.token, 'messages').then((res) => {
      const messagesResponse = res.response;
      const messages = messagesResponse?.filter(x => x.match && !x.match.invisibleToTalent);
      const unreadMsgCounts = countUnreadMessagesByMatch(messages, user);

      // the messages have been sorted now, then pick the lastest message for each match
      let allMessageCards = sortBy(messages, 'id').reverse();

      const results = [];
      allMessageCards = allMessageCards.filter((msg) => {
        if (results.findIndex(res => msg.match.id === res) === -1) {
          results.push(msg.match.id);
          msg.unreadCount = unreadMsgCounts[msg.match.id] || 0;
          return msg;
        }
      });

      const { matchId } = match.params;

      if (!matchId && allMessageCards.length > 0) {
        this.props.history.push(`/messages/${allMessageCards[0].match.id}`);
      } else {
        this.setState({ openMobileChat: true });
      }

      const newMessageCards = allMessageCards.filter(card => card.unreadCount > 0);

      this.setState({
        allMessages: allMessageCards,
        messagesResponse,
        newMessageCards,
      });
    });
  }

  getMessages = async () => {
    const {
      match, resources, user, actions, location, isDesktop,
    } = this.props;
    const { matchId } = match.params; // resourceId is connection id
    this.setState({ messageActivated: matchId });
    const dates = new URLSearchParams(location.search).get('dates');
    const isChatOnly = new URLSearchParams(location.search).get('chat') === 'true';
    const { matches } = resources;
    let messages = this.state.messagesResponse;

    if (!messages) {
      const messagesResp = await actions.getAllResources(user.token, 'messages');
      messages = messagesResp?.response || [];
    }

    const messagesList = [...messages];
    const messagesForTheMatch = messagesList ? messagesList.filter(msg => msg?.match?.id === parseInt(matchId)) : [];
    const unreadMsgs = messagesForTheMatch.filter(msg => msg.isUnread && msg.creator_id !== user.id);
    if (unreadMsgs.length !== 0) {
      const payload = {
        message_ids: unreadMsgs.map(msg => msg.id),
      };
      actions.updateResource('messages', user.token, payload, 'messages', 'set-as-read').then((res) => {
        if (!res.error) {
          actions.getAllResources(user.token, 'messages');
        }
      });
    }
    // retrieve a random msg to get the practice & job info
    const randomMessage = messagesForTheMatch && messagesForTheMatch.length > 0 && messagesForTheMatch[0];
    const jobPosting = randomMessage && randomMessage.match ? randomMessage.match.JobPosting : {};
    const isDeleted = jobPosting && jobPosting.deletedAt && jobPosting.isDeleted;
    const jobId = jobPosting.id;
    let availableDates = [];
    let takenDates = [];
    let pendingDates = [];
    let fastpassPractice = {};
    let userTakenDates = [];

    const theMatch = matches ? matches.find(m => m.id === parseInt(matchId)) : {};
    const isCoronadoOnlyPost = theMatch?.badges?.length === 1 && theMatch.badges[0].title === CORONADO_BADGE_TITLE;
    this.setState({ isCoronadoOnlyPost });

    // Get dateList array from the job-posting-calendar
    if (isReliefOrExternshipType(jobPosting.typeOfEmployment)) {
      const cData = await this.retrieveCalendarData({
        typeOfEmployment: jobPosting.typeOfEmployment,
        jobId: jobPosting.id,
      });

      if (cData) {
        availableDates = cData.availableDates;
        takenDates = cData.takenDates;
        pendingDates = cData.pendingDates;
        userTakenDates = cData.userTakenDates;
        this.setState({ calendarData: cData.calendarData });
      }

      if (jobPosting.typeOfEmployment !== 'Externship') { // relief type  --> retrieve fastpassData
        let activeFastpass = [];
        const fastpassRes = await actions.getAllResources(user.token, `fastpass/user/${user.id}`);
        activeFastpass = (fastpassRes?.response || []).filter(r => !r.deletedAt);
        fastpassPractice = randomMessage?.practice?.id ? activeFastpass.find(fp => fp.practice_id === randomMessage?.practice?.id) : {};
        this.setState({ fastpassPractice });

      }
      if (jobId) {
        actions.getAllResources(user.token, `date-request-trackings/job-posting/${jobId}`).then((dateRes) => {
          const requestTrackingDates = dateRes?.response || [];
          const declinedTrackingDates = requestTrackingDates.filter(r => r.user_id === user.id && r.status === 'declined' && r.match_id === Number(matchId)).map(r => DateUtilities.midDayDate(r.date));
          this.setState({ declinedTrackingDates });
        });
      }
      await this.getCalendarMatches();
    }
    
    const jobRoleText = getJobRoleText(jobPosting?.talentType, theMatch?.jobPostingCriteriaItems);
    const jobTitle = formatPostTitle(randomMessage.jobTitle);
    const practiceName = randomMessage.practice ? randomMessage.practice.name : '';
    const formattedDates = dates ? dates.split(',').map(d => moment(d).format('MM/DD')) : [];

    let newMessage = dates ? `Please confirm these dates: ${formattedDates.join(',')}` : '';
    if (dates && jobPosting.typeOfEmployment !== 'Externship' && !isEmpty(fastpassPractice)) { // isFastpass
      newMessage = !isChatOnly
        ? `Hi there, I have FastPass for 
          ${fastpassPractice.practiceName} and I'm confirming ${formattedDates.length > 1 ? 'these' : 'this'}
          shift ${formattedDates.length > 1 ? 'dates' : 'date'} as taken: ${formattedDates.join(',')}`
        : `I'm interested in discussing
          ${formattedDates.length > 1 ? 'these dates' : 'this date'} with you,
          but am not claiming ${formattedDates.length > 1 ? 'them' : 'it'} yet: ${formattedDates.join(',')}.`;
    }

    const address = randomMessage.practice && `${randomMessage.practice.location} - ${randomMessage.practice.city} - ${randomMessage.practice.state}`;
    const markers = randomMessage.practice ? [{ id: randomMessage.practice.id, name: randomMessage.practice.name, position: { lat: randomMessage.practice.lat, lng: randomMessage.practice.lng } }] : [];
    this.setState({
      messages: messagesForTheMatch,
      jobTitle,
      jobId,
      practiceName,
      availableDates,
      takenDates,
      calendarInitDisplayDate: this.getEarliestSelectedDate(availableDates, takenDates, pendingDates),
      newMessage,
      calendarMessage: !!dates,
      pendingDates,
      confirmedDates: userTakenDates,
      isDeleted,
      loadingMessages: false,
      jobRoleText,
      address,
      typeOfEmployment: jobPosting.typeOfEmployment,
      jobPosting,
      practice: randomMessage.practice,
      markers,
      isLoading: false,
      isDesktop,
    }, () => {
      const element = !isDesktop ? document.getElementById('mobileMain') : document.getElementById('desktopMain');

      if (element) {
        element.scrollTop = element.scrollHeight;
      }

      // Click the Send button & and save requestedDates as pending if it's Externship type
      if (dates && (jobPosting.typeOfEmployment === 'Externship' || !isEmpty(fastpassPractice))) {
        const requestedDates = dates.split(',').map(d => DateUtilities.midDayDate(d));
        this.onClickSendButton(requestedDates, !isEmpty(fastpassPractice) && jobPosting.typeOfEmployment !== 'Externship', isChatOnly);
      }
    });
  }

  retrieveCalendarData = async ({ typeOfEmployment, jobId }) => {
    const { user, actions } = this.props;

    if (!jobId || !isReliefOrExternshipType(typeOfEmployment)) return;

    let availableDates = [];
    let takenDates = [];
    let pendingDates = [];
    let userTakenDates = [];

    const calendarResp = await actions.getAllResources(user.token, `jobpostings/${jobId}/calendar`);
    if (calendarResp && calendarResp.response) {
      userTakenDates = calendarResp.response
        .filter(
          (row) =>
            row.status === "taken" &&
            row.talent_user_id === user.id &&
            row.confirmation
        )
        .map((a) => DateUtilities.midDayDate(a.date));
      const avaiUniqDates = uniq(calendarResp.response.filter(a => a.status === 'available').map(a => a.date));
      availableDates = avaiUniqDates.map(a => DateUtilities.midDayDate(a));

      // Filter out avai dates which have been taken by the user
      if (!isEmpty(userTakenDates)) {
        availableDates = availableDates.filter(date => !DateUtilities.dateIn(userTakenDates, date));
      }

      const takenUniqDates = uniq(calendarResp.response.filter(t => t.status === 'taken').map(t => t.date));
      takenDates = takenUniqDates.map(a => DateUtilities.midDayDate(a));
      takenDates = takenDates.filter(date => !DateUtilities.dateIn(availableDates, date));

      if (typeOfEmployment === 'Externship') {
        // Get pending date rows
        let pendingRows = calendarResp.response.filter(row => row.status === 'pending');
        pendingRows = pendingRows.map(r => ({
          ...r,
          date: DateUtilities.midDayDate(r.date),
        }));
        // Filter out available shifts which are not requested by the user
        pendingRows = pendingRows.filter(pendingRow => pendingRow.talent_user_id === user.id
          || !DateUtilities.dateIn(availableDates, pendingRow.date));

        pendingDates = pendingRows.map(r => r.date);
      }
      return { calendarData: calendarResp.response, availableDates, takenDates, pendingDates, userTakenDates };
    }
  };

  setDateListForMatches = (matches, avaiObj, confirmedObj) => {
    if (isEmpty(matches) || (isEmpty(avaiObj) && isEmpty(confirmedObj))) return matches;
    const avaiKeys = Object.keys(avaiObj);
    const confirmedKeys = Object.keys(confirmedObj);

    let matchIdsObj = {};

    avaiKeys.forEach((dateKey) => {
      const ids = avaiObj[dateKey];
      ids.forEach((id) => {
        if (matchIdsObj[id]) {
          matchIdsObj[id] = matchIdsObj[id].concat([dateKey]);
        } else {
          matchIdsObj[id] = [dateKey];
        }
      });
    });
    let confirmedMatchIdsObj = {};
    !isEmpty(confirmedKeys) && confirmedKeys.forEach((dateKey) => {
      const ids = confirmedObj[dateKey];
      ids.forEach((id) => {
        if (confirmedMatchIdsObj[id]) {
          confirmedMatchIdsObj[id] = confirmedMatchIdsObj[id].concat([dateKey]);
        } else {
          confirmedMatchIdsObj[id] = [dateKey];
        }
      });
    });

    matches = matches.map((match) => {
      let dateList;
      let confirmedDateKeyList;
      let dates = [];

      if (isReliefOrExternshipType(match?.jobPosting?.typeOfEmployment)) {
        if (matchIdsObj[match.id]) {
          dates = matchIdsObj[match.id];
          dateList = dates.map(a => moment(a).format('MMM.DD')).join(', ');
        }
        if (confirmedMatchIdsObj[match.id]) {
          confirmedDateKeyList = confirmedMatchIdsObj[match.id];
        }
      }
      return { ...match, dateList, avaiDateKeyList: dates, confirmedDateKeyList }
    });
    return matches;
  };

  getCalendarMatches = async () => {
    const { actions, user, resources } = this.props;
    const { isCoronadoOnlyPost, fastpassPractice } = this.state;
    const { matches } = resources;
    const calendarMatches = await actions.getAllResources(user.token, 'jobPostingCalendars/getCurrentCalendarMatches').then(res => res.response);

    const allConfirmedDates = calendarMatches
      && calendarMatches.confirmed ? Object.keys(calendarMatches.confirmed).map(d => DateUtilities.midDayDate(d)) : [];

    if (isCoronadoOnlyPost && !isEmpty(fastpassPractice)) {
      const coronadoJobMatches = [...matches].filter(match => match?.badges?.length && match.badges.find(b => b.title === CORONADO_BADGE_TITLE));
      let shiftCalendarMatches =  [...coronadoJobMatches].filter(match => match?.practice?.id === fastpassPractice?.practice_id && match?.status === 'connected');
      shiftCalendarMatches = this.setDateListForMatches(shiftCalendarMatches, calendarMatches?.available, calendarMatches?.confirmed);
      this.setState({ shiftCalendarMatches });
    }

    this.setState({ allConfirmedDates, calendarMatches });
  };

  getEarliestSelectedDate = (availableDates, takenDates, pendingDates) => {
    const sortedDateList = [...availableDates, ...takenDates, ...pendingDates].sort((date1, date2) => (date1 - date2));
    return sortedDateList.length ? sortedDateList[0] : null;
  }

  handleTakeShift = async () => {
    const { actions, user, match } = this.props;
    const { matchId } = match.params; // resourceId is connection id
    const { selectedEvent, fastpassPractice } = this.state;

    if (isEmpty(selectedEvent) || !selectedEvent.jobId) return;

    const newMessage = `Hi there, I have FastPass for ${fastpassPractice?.practiceName} and I'm confirming this
      shift date as taken: ${moment(selectedEvent.date).format('MM/DD')}`;

    if (Number(selectedEvent.matchId) === Number(matchId)) { // the selected event is the current match
      this.setState({
        newMessage,
        calendarMessage: true,
        datePicker: false,
      }, async () => {
        this.onClickSendButton([DateUtilities.midDayDate(selectedEvent.date)], true);
        this.setState({
          openDetailsDialog: false,
          openShiftCalendar: false,
          selectedEvent: {},
        });
        setTimeout(async () => {
          await this.getCalendarMatches();
          this.setState({ openShiftCalendar: true });
        }, TIMEOUT_DURATION);
      });
    } else {
      // Check if the calendar data is latest
      const calendarResp = await actions.getAllResources(user.token, `jobpostings/${selectedEvent.jobId}/calendar`);
      if (calendarResp && calendarResp.response) {
        const avaiDataOnDate = calendarResp.response
        && calendarResp.response.find(row => row.status === 'available' && selectedEvent.date === row.date);

        if (!avaiDataOnDate) return;

        let payload = {
          body: newMessage,
          match_id: parseInt(selectedEvent.matchId),
          isUnread: true,
        };

        payload.takenDates = [{
          id: avaiDataOnDate.id,
          status: 'taken',
          date: avaiDataOnDate.date,
          job_posting_id: avaiDataOnDate.job_posting_id,
          talent_user_id: user.id,
          confirmation_category: 'known_taken',
          confirmation: `${user.name} (${user.email})`,
          confirmation_date: new Date(),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }];

        actions.addResource('messages', user.token, payload, 'messages').then((res) => {
          actions.addResource('log-event', user.token, { action: 'talent_message' }, 'users', 'log-event')
          .then(() => {
            this.setState({
              openDetailsDialog: false,
              openShiftCalendar: false,
              selectedEvent: {},
            }, async () => {
              await this.getCalendarMatches();
              this.setState({ openShiftCalendar: true });
            });
          });
        });
      }
    }
  }

  onClickSendButton = (requestedDates, isFastpass, isChatOnly) => {
    const { match, actions, user, history, location, width } = this.props;
    const { matchId } = match.params;
    const {
      messages,
      newMessage,
      calendarData,
      typeOfEmployment,
      pendingDates,
      confirmedDates,
      availableDates,
      dateRequested,
      isDesktop,
    } = this.state;

    const element = !isDesktop ? document.getElementById('mobileMain') : document.getElementById('desktopMain');

    if (!newMessage.trim()) {
      element.scrollTop = element.scrollHeight;
      return; // prevent sending blank messages
    }

    let payload = {
      body: newMessage,
      match_id: parseInt(matchId),
      isUnread: true,
    };
    

    if (typeOfEmployment === 'Relief' && !isFastpass) {
      const dateRequestedFormat = dateRequested && dateRequested.split(',').map(d => {
        if (d.length === 5) return moment(`${d}/${moment().format('YYYY')}`).format('YYYY-MM-DD')
        return moment(d).format('YYYY-MM-DD')
      });

      payload.requestedDates = dateRequestedFormat;
      payload.requestStatus = 'requested';
    }

    if ((typeOfEmployment === 'Externship' || isFastpass) && !isEmpty(requestedDates)) {
      let pendingRows = [];
      let takeShiftRows = [];
      requestedDates.forEach((date) => {
        const avaiDataOnDate = calendarData
          && calendarData.find(row => row.status === 'available' && DateUtilities.isSameDay(date, DateUtilities.midDayDate(row.date)));
        if (avaiDataOnDate) {
          if (isFastpass && !isChatOnly) {
            takeShiftRows.push({
              id: avaiDataOnDate.id,
              status: 'taken',
              date: avaiDataOnDate.date,
              job_posting_id: avaiDataOnDate.job_posting_id,
              talent_user_id: user.id,
              confirmation_category: 'known_taken',
              confirmation: `${user.name} (${user.email})`,
              confirmation_date: new Date(),
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
          } else if (typeOfEmployment === 'Externship') { // pending for externship
            pendingRows.push({
              id: avaiDataOnDate.id,
              status: 'pending',
              date: avaiDataOnDate.date,
              job_posting_id: avaiDataOnDate.job_posting_id,
              talent_user_id: user.id,
            });
          }
        }
      });

      if (isFastpass && !isChatOnly) {
        payload.takenDates = takeShiftRows;
        this.setState({
          confirmedDates: [...confirmedDates, ...requestedDates],
          availableDates: availableDates.filter(date => !DateUtilities.dateIn(requestedDates, date)),
        });
      } else if (typeOfEmployment === 'Externship') {
        payload.pendingDates = pendingRows;
        this.setState({ pendingDates: [...pendingDates, ...requestedDates] });
      }
    }

    actions.addResource('messages', user.token, payload, 'messages').then((res) => {
      actions.addResource('log-event', user.token, { action: 'talent_message' }, 'users', 'log-event');
      const sentMsg = res;
      this.setState({
        messages: [...messages, { body: sentMsg.body, creator_id: sentMsg.creator_id, updatedAt: sentMsg.updatedAt }],
        newMessage: '',
        calendarMessage: false,
        dateRequested: undefined,
      });
      element.scrollTop = element.scrollHeight;
    }).then(() => {
      history.replace({ pathname: location.pathname, search: '' });
      this.getCalendarMatches();
    });
  }

  onChangeTextField = event => this.setState({ newMessage: event.target.value, calendarMessage: false });

  onClickBackButton = () => {
    const { history } = this.props;
    //history.goBack();
    history.push('/');
  }

  setDatePicker(open) {
    this.setState({ datePicker: true });
  }

  onClickCard = (matchId, isMobile, isUnread) => {
    this.setState({ messageActivated: matchId });
    this.props.history.push(`/messages/${matchId}`);
    if (isUnread) {
      this.getMessageList();
      this.refreshUnreadMsgCount();
    }
    if (isMobile) {
      this.setState({ openMobileChat: true });
    }
  }

  refreshUnreadMsgCount = () => {
    const { actions, user } = this.props;
    actions.getAllResources(user.token, 'messages/count-unread-msg-by-match');
  };

  renderFilterButton = () => {
    const { isNewOnly } = this.state;
    const { classes } = this.props;

    return (
      <Tooltip title={isNewOnly ? 'New Only' : 'All Messages'} placement='top' >
        <IconButton
          className={classNames({ [classes.filterButton]: isNewOnly })}
          aria-label="filter" onClick={() => this.setState({ isNewOnly: !isNewOnly })}
          style={{ padding: '4px' }}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    )
  }

  renderMessageCard = (message, savedSearch, messageActivated, isToday, isMobile) => {
    const { classes } = this.props;
    const isUnread = message.unreadCount > 0 && (this.state.msgIdClicked !== message.id) && (messageActivated !== message.id);
    return (
      <>
        <div className={classes.messageCard}
          onClick={() => {
            this.setState({ msgIdClicked: message.id });
            this.onClickCard(message.match.id, isMobile, message.unreadCount > 0)
          }}
          style={{ backgroundColor: +messageActivated === +message.match.id ? '#E1E5EA' : 'unset' }}
        >
          {isUnread && (this.state.msgIdClicked !== message.id ) && (
            <div className={classes.unread}>
              <LensIcon style={{ width: 12, color: orangeLight }} />
            </div>
          )}
          <Grid container className={classes.cardHeader}>
            <Grid item xs={9}>
              <Tooltip title={message.practice && message.practice.name} placement='top'>
                <Typography
                  className={classNames(classes.cardTitle, {
                    [classes.active]: +messageActivated === +message.match.id,
                    [classes.unreadText]: isUnread
                  })}
                >
                  {message.practice.name}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title={savedSearch && savedSearch.title} placement='top'>
                <Typography className={classes.cardSubTitle}>
                  {savedSearch && savedSearch.title}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={9}>
              <Tooltip title={formatPostTitle(message.jobTitle)} placement='top'>
                <Typography className={classNames(classes.cardTextBody, {
                  [classes.active]: +messageActivated === +message.match.id,
                  [classes.unreadText]: isUnread
                })}>
                  {formatPostTitle(message.jobTitle)}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={3}
              className={classNames(classes.cardTextBody, classes.contentRight, {
                [classes.active]: +messageActivated === +message.match.id,
                [classes.unreadText]: isUnread
              })}>
              {isUnread ? (<strong>{isToday ? moment(message.createdAt).format('LT') : moment(message.createdAt).format('L')}</strong>) : (isToday ? moment(message.createdAt).format('LT') : moment(message.createdAt).format('L'))}
            </Grid>
          </Grid>
          <Typography className={classes.messageText}>
            <strong>{message.creatorName}</strong> - {message.body}
          </Typography>
        </div>
      </>
    )
  }

  renderJobDetail = (jobPosting, jobRoleText, destination, address, markers, practice) => {
    const { classes } = this.props;
    const { isDeleted } = this.state;
    const isArchived = jobPosting && jobPosting.deletedAt && !jobPosting.isDeleted;
    const rotationTypes = jobPosting && jobPosting.rotationTypes && JSON.parse(jobPosting.rotationTypes);
    return (
      <>
        {isArchived && (
          <Alert variant="filled" severity="warning">The underlying connected match is now archived</Alert>
        )}
        { isDeleted && (
            <Alert variant="filled" severity="error">The underlying connected match is now deleted</Alert>
        )}

        <div className={classes.detailCard}>
          <Typography className={classes.sectionTitle}>Description</Typography>
          <Typography
            className={classes.jobDescription}
            dangerouslySetInnerHTML={{ __html: jobPosting && jobPosting.description }}
          />
          <Typography><strong>Role:</strong> {jobRoleText}</Typography>
          { jobPosting?.typeOfEmployment === 'Externship' && rotationTypes && rotationTypes.length > 0 && <Typography><strong>Externship Rotation:</strong> {rotationTypes?.join(', ')}</Typography>}
        </div>

        <div className={classes.detailCard}>
          <Typography className={classes.sectionTitle}>About</Typography>
          <Typography
            className={classes.jobDescription}
            dangerouslySetInnerHTML={{ __html: practice && practice.description }}
          />
          <Grid container className={classes.locationTitle}>
            <Grid item xs={6}>
              <Typography className={classes.sectionTitle}>Location</Typography>
            </Grid>
            <Grid item xs={6}>
              <ButtonBase
                target='_blank'
                href={`https://www.google.com/maps/dir/?api=1&destination=${destination}`}
                className={classes.directionLink}
              >
                <Typography className={classes.directionLink}><PlaceIcon /> Get Directions</Typography>
              </ButtonBase>
            </Grid>
          </Grid>
          <Typography className={classes.address}>{address}</Typography>
          <div className={classes.map}>
            <LocationGoogleMap
              markers={markers}
              containerStyle={{
                padding: 0,
                margin: 0,
                with: '100%',
                height: '100%',
                overflowY: 'visible',
              }}
            />
          </div>
        </div>
      </>
    )
  }

  renderDesktopMode = () => {
    const { history, classes, user, resources } = this.props;
    const { matches } = resources;
    const {
      jobTitle,
      messages,
      newMessage,
      jobId,
      availableDates,
      takenDates,
      confirmedDates,
      calendarInitDisplayDate,
      calendarMessage,
      pendingDates,
      typeOfEmployment,
      isDeleted,
      jobRoleText,
      fastpassPractice,
      allConfirmedDates,
      address,
      isLeftDrawerOpened,
      isRightDrawerOpened,
      messageActivated,
      allMessages,
      jobPosting,
      practice,
      markers,
      isLoading,
      newMessageCards,
      isNewOnly,
      practiceName,
      calendarData,
      isCoronadoOnlyPost,
      calendarMatches,
      declinedTrackingDates,
    } = this.state;

    const isFastpass = typeOfEmployment === 'Relief' && !isEmpty(fastpassPractice);
    let destination = practice?.location;
    destination = destination?.replace(/\//g, '%2F');
    destination = destination?.replace(/:/g, '%3A');

    const cardsToDisplay = isNewOnly ? newMessageCards : allMessages;
    const showShiftCalendar = isFastpass && isCoronadoOnlyPost;

    return (
      <div className={classes.desktop}>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={isLeftDrawerOpened}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <Grid container className={classes.messagesHeader}>
              <Grid item xs={10}>
                Messages
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'right' }}>
                {this.renderFilterButton()}
              </Grid>
            </Grid>
          </div>
          <div style={{ overflowY: 'auto' }}>
            {cardsToDisplay && cardsToDisplay.map(message => {
              const isToday = moment(message.createdAt).isSame(moment(), 'day');
              const savedSearch = message.match && message.match.savedSearchSnapshop && JSON.parse(message.match.savedSearchSnapshop);
              return this.renderMessageCard(message, savedSearch, messageActivated, isToday);
            })}
          </div>
        </Drawer>
        <div
          className={classNames(classes.content, {
            [classes.contentShiftLeft]: isLeftDrawerOpened,
            [classes.contentShiftRight]: isRightDrawerOpened,
          })}
        >
          <div className={classes.messagesContainer}>
            <div className={classes.mainHeader} onClick={() => history.push(`/matches/${jobId}`)}>
              <div style={{ display: 'inline-flex'}}>
                <Typography className={classes.jobTitle}>{formatPostTitle(jobTitle)}</Typography>
                {isFastpass && (<img className={classes.fastpassImg} src={fastpassIcon} alt='fastpass' title='Fast Pass' />)}
              </div>
              <Typography className={classes.jobSubTitle}>{practiceName}</Typography>
            </div>
            <div className={classes.mainContent} id='desktopMain'>
              {isLoading && <div className={classes.loading}><CircularProgress color="inherit" /></div>}
              {!isLoading && messages.map((msg, index) => (
                <MessageBubble
                  key={index}
                  content={msg.body}
                  senderName={msg.creatorName}
                  type={msg.creator_id == user.id ? 'sender' : 'receiver'}
                  date={msg.createdAt}
                  creatorName={msg.creatorName}
                />
              ))}
            </div>
            <div
              className={classNames(classes.mainChatbox, {
                [classes.fixedLeftContent]: isLeftDrawerOpened,
                [classes.fixedRightContent]: isRightDrawerOpened,
              })}
            >
              <MessagesInputBox
                onClickSendButton={this.onClickSendButton}
                onChangeTextField={this.onChangeTextField}
                calendarMessage={calendarMessage}
                textFieldValue={newMessage}
                disabled={isDeleted}
              />
            </div>
            <Prompt
              when={!!(newMessage)}
              message='Leave the page without sending message?'
            />
            <RefreshPageDialog
              open={this.state.openRefreshDialog}
              handleClose={() => this.setState({ openRefreshDialog: false })}
            />
          </div>
        </div>
        {!isRightDrawerOpened && (
          <Fab
            variant="extended"
            className={classes.drawerFabBtn} style={{ right: -20, top: 285, rotate: 'z 90deg', }}
            onClick={() => this.setState({ isRightDrawerOpened: true })}
          >
            Details
          </Fab>
        )}
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={isRightDrawerOpened}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Box className={classes.rightDrawerCloseBtn} >
            <IconButton onClick={() => this.setState({ isRightDrawerOpened: false })} size='small'>
              <ChevronRightIcon style={{ fontSize: '16px'}}/>
            </IconButton>
          </Box>

          <div className={classes.drawerHeader}>
            <Grid container className={classes.messagesHeader}>
              <Grid item xs={6}>
                Details
              </Grid>
              <Grid item xs={6}>
                <ButtonBase
                  target='_blank'
                  href={`https://www.google.com/maps/dir/?api=1&destination=${destination}`}
                  className={classes.directionLink}
                >
                  <Typography className={classes.directionLink}><PlaceIcon /> Get Directions</Typography>
                </ButtonBase>
              </Grid>
            </Grid>
          </div>
          <div style={{ overflowY: 'auto' }}>
            <div className={classes.detailCard}>
              <SingleCalendarPicker
                selectedDates={availableDates}
                declinedTrackingDates={declinedTrackingDates}
                notAvailableDates={takenDates?.filter(date => !DateUtilities.dateIn(confirmedDates, date))}
                pendingDates={pendingDates}
                confirmedDates={confirmedDates}
                allConfirmedDates={allConfirmedDates || []}
                initialDate={calendarInitDisplayDate}
                cancelButtonText='Clear All'
                onCancel={(e) => { }}
                onSubmit={async (requestedDates) => {
                  const dates = requestedDates.map(d => moment(d).format('MM/DD')).join(',');
                  if (dates) {
                    // Check if the calendar data is latest
                    const cData = await this.retrieveCalendarData({ typeOfEmployment, jobId });

                    if (!isEqual(cData?.calendarData, this.state.calendarData)) {
                      this.setState({ openRefreshDialog: true });
                      return;
                    }

                    let datesList = dates.split(',');
                    datesList = sortBy(datesList)

                    this.setState({
                      newMessage: isFastpass
                        ? `Hi there, I have FastPass for 
                          ${fastpassPractice.practiceName} and I'm confirming ${datesList.length === 1 ? 'this' : 'these'}
                          shift ${datesList.length === 1 ? 'date' : 'dates'} as taken: ${datesList.join(' ')}`
                        : `Please confirm these dates: ${datesList.join(' ')}`,
                      calendarMessage: !!dates,
                      datePicker: false,
                      pendingDates: typeOfEmployment === 'Externship' ? [...pendingDates, ...requestedDates] : [],
                      dateRequested: dates,
                    }, () => {
                      if (typeOfEmployment === 'Externship') {
                        return this.onClickSendButton(requestedDates);
                      } else if (isFastpass) {
                        return this.onClickSendButton(requestedDates, true);
                      }
                      return;
                    });
                  }
                }}
                onBackdropClick={() => this.setState({ datePicker: false })}
                isFastpass={isFastpass}
                submitButtonText={isFastpass ? 'Claim Dates' : 'Request'}
                calendarData={calendarData}
                practice={practice}
                showShiftCalendar={showShiftCalendar}
                onShiftCalendarClick={() => {
                  this.setState({ openShiftCalendar: true });
                }}
              />
            </div>
            {this.renderJobDetail(jobPosting, jobRoleText, destination, address, markers, practice)}
          </div>
        </Drawer>

        {this.state.openShiftCalendar &&
          <ShiftCalendarDialog
            open={this.state.openShiftCalendar}
            handleClose={() => this.setState({ openShiftCalendar: false })}
            handleEventSelect={(event) => this.setState({ selectedEvent: event, openDetailsDialog: true })}
            shiftCalendarPractice={practice}
            shiftCalendarMatches={this.state.shiftCalendarMatches}
          />
        }
        {this.state.openDetailsDialog && (
          <JobDetailsDialog
            open={this.state.openDetailsDialog}
            handleClose={() => this.setState({ openDetailsDialog: false })}
            handleTakeShift={() => this.handleTakeShift()}
            matchData={this.state.shiftCalendarMatches?.find(match => match.id === this.state.selectedEvent?.matchId) || {}}
            selectedEvent={this.state.selectedEvent}
          />
        )}
      </div>
    )
  }

  renderMobileMode = () => {
    const {
      classes,
      user,
    } = this.props;
    const {
      openMobileChat,
      jobTitle,
      messages,
      newMessage,
      jobId,
      availableDates,
      takenDates,
      confirmedDates,
      calendarInitDisplayDate,
      calendarMessage,
      pendingDates,
      typeOfEmployment,
      isDeleted,
      jobRoleText,
      fastpassPractice,
      allConfirmedDates,
      address,
      messageActivated,
      allMessages,
      jobPosting,
      practice,
      markers,
      isShowMobileCalenderPopup,
      openMobleDetail,
      isLoading,
      newMessageCards,
      isNewOnly,
      practiceName,
      calendarData,
      isCoronadoOnlyPost,
      declinedTrackingDates,
    } = this.state;
    const isFastpass = typeOfEmployment === 'Relief' && !isEmpty(fastpassPractice);
    let destination = practice?.location;
    destination = destination?.replace(/\//g, '%2F');
    destination = destination?.replace(/:/g, '%3A');
    const cardsToDisplay = isNewOnly ? newMessageCards : allMessages;
    const showShiftCalendar = isFastpass && isCoronadoOnlyPost;

    return (
      <div className={classes.mobile}>
        <div className={classes.messageList}>
          <div className={classes.drawerHeader}>
            <Grid container className={classes.messagesHeader}>
              <Grid item xs={10}>
                <IconButton className={classes.backBtn} onClick={() => this.onClickBackButton()}>
                  <ChevronLeftIcon />
                </IconButton>
                Messages
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'right' }}>
                {this.renderFilterButton()}
              </Grid>
            </Grid>
          </div>
          <div style={{ overflowY: 'auto', maxHeight: '750px'}}>
            {cardsToDisplay && cardsToDisplay.map(message => {
              const isToday = moment(message.createdAt).isSame(moment(), 'day');
              const savedSearch = message.match && message.match.savedSearchSnapshop && JSON.parse(message.match.savedSearchSnapshop);
              return this.renderMessageCard(message, savedSearch, messageActivated, isToday, true);
            })}
          </div>
        </div>
        <Drawer
          className={classes.mobileDrawer}
          variant="persistent"
          anchor="right"
          open={openMobileChat}
          classes={{
            paper: classes.mobileDrawerPaper,
          }}
          PaperProps={{
            id: 'mobileMain'
          }}
        >
          <div className={classes.mChatHeader}>
            <div className={classes.mChatHeaderContainer}>
              <IconButton className={classes.backBtn} onClick={() => this.setState({ openMobileChat: false })}>
                <ChevronLeftIcon />
              </IconButton>
              <div onClick={() => this.setState({ openMobleDetail: true })}>
                <Typography className={classes.mJobTitle}>{formatPostTitle(jobTitle)} {isFastpass && (<img className={classes.fastpassImg} src={fastpassIcon} alt='fastpass' title='fastpass' />)}</Typography>
                <Typography className={classes.jobSubTitle}>{practiceName}</Typography>
              </div>
              <IconButton disabled={isShowMobileCalenderPopup} onClick={() => this.setState({ isShowMobileCalenderPopup: true })} className={classes.calendarBtn}>
                <EventIcon />
              </IconButton>
            </div>
          </div>
          <div>
            <div className={classes.mMainContent}>
              {isLoading && <div className={classes.loading}><CircularProgress color="inherit" /></div>}
              {!isLoading && messages.map((msg, index) => (
                <MessageBubble
                  key={index}
                  content={msg.body}
                  senderName={msg.creatorName}
                  type={msg.creator_id == user.id ? 'sender' : 'receiver'}
                  date={msg.createdAt}
                  creatorName={msg.creatorName}
                />
              ))}
            </div>
            <div className={classes.mMainChatbox}>
              <MessagesInputBox
                onClickSendButton={this.onClickSendButton}
                onChangeTextField={this.onChangeTextField}
                calendarMessage={calendarMessage}
                textFieldValue={newMessage}
                disabled={isDeleted}
              />
            </div>
          </div>
          <Dialog
            open={isShowMobileCalenderPopup}
            TransitionComponent={UpTransition}
            keepMounted
            fullWidth={true}
            maxWidth={'md'}
            onClose={() => this.setState({ isShowMobileCalenderPopup: false })}
            aria-describedby="alert-dialog-slide-description"
            BackdropProps={{ invisible: true }}
            className={classes.mCalendarDialog}
          >
            <div className={classes.mCalendarDialogHeader}>
              <Typography className={classes.mCalendarTextDialogHeader}>
                Select available dates and request:
              </Typography>
              <IconButton onClick={() => this.setState({ isShowMobileCalenderPopup: false })}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className={classes.mCalendarDialogBody}>
              <SingleCalendarPicker
                selectedDates={availableDates}
                declinedTrackingDates={declinedTrackingDates}
                notAvailableDates={takenDates?.filter(date => !DateUtilities.dateIn(confirmedDates, date))}
                pendingDates={pendingDates}
                confirmedDates={confirmedDates}
                allConfirmedDates={allConfirmedDates || []}
                initialDate={calendarInitDisplayDate}
                cancelButtonText='Clear All'
                onCancel={(e) => { }}
                isMobile
                onSubmit={async (requestedDates) => {
                  const dates = requestedDates.map(d => moment(d).format('MM/DD')).join(',');
                  if (dates) {
                    // Check if the calendar data is latest
                    const cData = await this.retrieveCalendarData({ typeOfEmployment, jobId });

                    if (!isEqual(cData?.calendarData, this.state.calendarData)) {
                      this.setState({ openRefreshDialog: true });
                      return;
                    }

                    this.setState({
                      newMessage: isFastpass
                        ? `Hi there, I have FastPass for 
                        ${fastpassPractice.practiceName} and I'm confirming ${dates.split(',').length > 1 ? 'these' : 'this'}
                        shift ${dates.split(',').length > 1 ? 'dates' : 'date'} as taken: ${dates.split(',').join(' ')}`
                        : `Please confirm these dates: ${dates.split(',').join(' ')}`,
                      calendarMessage: !!dates,
                      datePicker: false,
                      pendingDates: typeOfEmployment === 'Externship' ? [...pendingDates, ...requestedDates] : [],
                      dateRequested: dates
                    }, () => {
                      if (typeOfEmployment === 'Externship') {
                        return this.onClickSendButton(requestedDates);
                      } else if (isFastpass) {
                        return this.onClickSendButton(requestedDates, true);
                      }
                      return;
                    });
                  }

                  this.setState({ isShowMobileCalenderPopup: false })
                }}
                onBackdropClick={() => this.setState({ datePicker: false })}
                isFastpass={isFastpass}
                submitButtonText={isFastpass ? 'Claim Dates' : 'Request'}
                calendarData={calendarData}
                practice={practice}
                showShiftCalendar={showShiftCalendar}
                onShiftCalendarClick={() => {
                  this.setState({ openShiftCalendar: true });
                }}
              />
            </div>
          </Dialog>
        </Drawer>
        <Drawer
          className={classes.mobileDrawer}
          variant="persistent"
          anchor="right"
          open={openMobleDetail}
          classes={{
            paper: classes.mobileDrawerPaper,
          }}
        >
          <div className={classes.mChatHeader}>
            <div className={classes.mDetailHeaderContainer} style={{ justifyContent: 'flex-start' }}>
              <IconButton className={classes.backBtn} onClick={() => this.setState({ openMobleDetail: false })}>
                <ChevronLeftIcon />
              </IconButton>
              <div>
                <Typography className={classes.mJobTitle}>Details</Typography>
                <ButtonBase
                  target='_blank'
                  href={`https://www.google.com/maps/dir/?api=1&destination=${destination}`}
                  className={classes.directionLink}
                >
                  <Typography className={classes.mDetailSubTitle}><PlaceIcon /> Get Directions</Typography>
                </ButtonBase>
              </div>
            </div>
          </div>
          <div className={classes.mCalendarDetailBody}>
            <SingleCalendarPicker
              selectedDates={availableDates}
              declinedTrackingDates={declinedTrackingDates}
              notAvailableDates={takenDates?.filter(date => !DateUtilities.dateIn(confirmedDates, date))}
              pendingDates={pendingDates}
              confirmedDates={confirmedDates}
              allConfirmedDates={allConfirmedDates || []}
              initialDate={calendarInitDisplayDate}
              cancelButtonText='Clear All'
              onCancel={(e) => { }}
              isMobileTop
              onSubmit={async (requestedDates) => {
                const dates = requestedDates.map(d => moment(d).format('MM/DD')).join(',');
                if (dates) {
                  // Check if the calendar data is latest
                  const cData = await this.retrieveCalendarData({ typeOfEmployment, jobId });

                  if (!isEqual(cData?.calendarData, this.state.calendarData)) {
                    this.setState({ openRefreshDialog: true });
                    return;
                  }

                  let datesList = dates.split(',');
                  datesList = sortBy(datesList)

                  this.setState({
                    newMessage: isFastpass
                      ? `Hi there, I have FastPass for 
                        ${fastpassPractice.practiceName} and I'm confirming ${datesList.length === 1 ? 'this' : 'these'}
                        shift ${datesList.length === 1 ? 'date' : 'dates'} as taken: ${datesList.join(' ')}`
                      : `Please confirm these dates: ${datesList.join(' ')}`,
                    calendarMessage: !!dates,
                    datePicker: false,
                    pendingDates: typeOfEmployment === 'Externship' ? [...pendingDates, ...requestedDates] : [],
                    dateRequested: dates
                  }, () => {
                    if (typeOfEmployment === 'Externship') {
                      return this.onClickSendButton(requestedDates);
                    } else if (isFastpass) {
                      return this.onClickSendButton(requestedDates, true);
                    }
                    return;
                  });
                }

                this.setState({ openMobleDetail: false })
              }}
              onBackdropClick={() => this.setState({ datePicker: false })}
              isFastpass={isFastpass}
              submitButtonText={isFastpass ? 'Claim Dates' : 'Request'}
              calendarData={calendarData}
              practice={practice}
              showShiftCalendar={showShiftCalendar}
              onShiftCalendarClick={() => {
                this.setState({ openShiftCalendar: true });
              }}
            />
          </div>
          {this.renderJobDetail(jobPosting, jobRoleText, destination, address, markers, practice)}
        </Drawer>
        {this.state.openShiftCalendar &&
          <ShiftCalendarDialog
            open={this.state.openShiftCalendar}
            handleClose={() => this.setState({ openShiftCalendar: false })}
            handleEventSelect={(event) => this.setState({ selectedEvent: event, openDetailsDialog: true })}
            shiftCalendarPractice={practice}
            shiftCalendarMatches={this.state.shiftCalendarMatches}
            isMobile
          />
        }
        {this.state.openDetailsDialog && (
          <JobDetailsDialog
            open={this.state.openDetailsDialog}
            handleClose={() => this.setState({ openDetailsDialog: false })}
            handleTakeShift={() => this.handleTakeShift()}
            matchData={this.state.shiftCalendarMatches?.find(match => match.id === this.state.selectedEvent?.matchId) || {}}
            selectedEvent={this.state.selectedEvent}
            isMobile
          />
        )}
      </div>
    )
  }

  render() {
    const { isDesktop } = this.props;
    return !isDesktop
      ? this.renderMobileMode()
      : this.renderDesktopMode();
  }
}

Messages.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
};

Messages.defaultProps = {};

function MessagesWrapper({ theme, ...rest }) {
  const isDesktop = theme ? useMediaQuery(theme.breakpoints.up('md')) : true;
  return <Messages {...rest} theme={theme} isDesktop={isDesktop} />;
}

export default withStyles(MessagesStyles, { withTheme: true })(withRouter(MessagesWrapper));