import React, { Component } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { isEmpty, uniqBy, uniq, flatMapDeep, isEqual, sortBy, pick } from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';
import {
  Typography,
  Grid,
  Box,
  Drawer,
  IconButton,
  Fab,
  Select,
  MenuItem,
  OutlinedInput,
} from '@mui/material/';
import { withStyles } from '@mui/styles';
import Toolbar from '@mui/material/Toolbar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import PlaceIcon from '@mui/icons-material/Place';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteConfirmDialog from '../subcomponents/DeleteConfirmDialog';

import { IMG_STORAGE_LINK, DESKTOP_BREAKPOINT } from '../constants/Constants';
import types from '../../actions/ActionTypes';
import { orange, iconGray, bgGray, tiffany, lightGray } from '../../css/style';
import { MatchesComponentStyle } from './styles/matchesComponentStyle';
import ConfirmEmail from '../subcomponents/ConfirmEmail';
import MatchesSearchBar from '../subcomponents/MatchesSearchBar';
import JobMatchCard from '../subcomponents/JobMatchCard';
import CalendarFilter from '../Datepicker/CalendarFilter';
import LocationGoogleMap from '../subcomponents/LocationGoogleMap';
import DateUtilities from '../Datepicker/utils';
import { convertHTMLToText, formatPostTitle, isReliefOrExternshipType } from '../../utils/Functions';
import MatchFilter from './MatchFilter';
import { renderConnectModal } from '../core/renderHelper';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Redirect } from 'react-router';
import OpenExternJobCard from '../subcomponents/OpenExternJobCard';
import { LocationSearchInput } from '../subcomponents/LocationSearchInput';

const TIMEOUT_DURATION = 400;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 250,
      transform: 'translateY(50px)',
    },
  },
};

class ExternHome extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props;

    const filterDatesInStr = localStorage.getItem('filterDatesInStr');
    let filteredDateArr = filterDatesInStr ? filterDatesInStr.split(',').map(dateText => DateUtilities.midDayDate(dateText)) : [];

    this.state = {
      openConfirmationModal: false,
      filter: 'all',
      openSuccessModal: false,
      openDeleteConfirmDialog: false,
      matchToBeDeleted: {},
      isMatchDeleted: false,
      filterDates: filteredDateArr,
      dateFilterMatches: [],
      searchTerm: '',
      fastpassData: [],
      openConfirmedShiftsDialog: false,
      calendarDrawerOpen: true,
      searchDrawerOpen: true,
      mapView: false,
      selectPracticeEnabled: user.privateAccess && (user.privateAccess.indexOf('HQ Admin') >= 0 || user.privateAccess.indexOf('Private User') >= 0),
      openSearchDialog: false,
      openMobileCalendar: true,
      openPhoneVerification: false,
      isLoading: false,
      rotationType: '',
      filteredMatches: [],
      calendarMatches: {
        available: {},
        confirmed: {},
      },
      postAvaiDates: [],
      filteredPosts: [],
      searchByState: '',
    };
  }

  refreshItems = () => {
    const { actions, user, resources } = this.props;
    this.setState({ isLoading: true });
    Promise.all([
      actions.getAllResources(user.token, 'matches'),
      actions.getAllResources(user.token, 'jobpostings/open-externships'),
    ]).then(() => {
      this.getCalendarMatches();
      this.setState({ isLoading: false });

    });
    this.refreshStats();

    if (!resources?.practices) {
      actions.getAllResources(user.token, 'practices');
    }

    if (!resources?.talents) {
      actions.getAllResources(user.token, 'talents');
    }
  }

  componentDidMount() {
    const { resources } = this.props;
    const calendarMatches = resources['jobPostingCalendars/getCurrentCalendarMatches'];

    this.setState({ calendarMatches });
    this.refreshItems();
    this.retrieveFastpassData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { resources } = this.props;
    const calendarMatches = resources['jobPostingCalendars/getCurrentCalendarMatches'];
    const prevCalendarMatches = prevProps.resources['jobPostingCalendars/getCurrentCalendarMatches'];

    if (!isEqual(prevCalendarMatches, calendarMatches)) {
      this.setState({ calendarMatches });
    }
  }

  retrieveFastpassData = () => {
    const { actions, user } = this.props;
    actions.getAllResources(user.token, `fastpass/user/${user.id}`).then((fastpassRes) => {
      const activeFastpass = (fastpassRes?.response || []).filter(r => !r.deletedAt);
      this.setState({ fastpassData: activeFastpass });
    });
  };

  refreshStats() {
    const { actions, user } = this.props;
    actions.getAllResources(user.token, 'matches/stats');
    actions.getAllResources(user.token, 'messages/count-unread-msg-by-match')
      .then((res) => {
        if (!res.error) {
          const unreadMsgCounts = res.response || {};
          this.setState({ unreadMsgCounts });
        }
      });
  }

  getCalendarMatches() {
    const { actions, user } = this.props;
    actions.getAllResources(user.token, 'jobPostingCalendars/getCurrentCalendarMatches').then(() => {
      this.setCalendarFilterMatches();
    });
  }

  confirmConnectToMatch = (item) => {
    this.setState({
      openConfirmationModal: true,
      currentItem: item,
    });
  };

  connectToMatch = (item) => {
    const { history, actions, user } = this.props;

    if (item.jobPosting) {
      actions.updateResource('matches', user.token, {
        id: item.id,
        status: 'connected',
        connectDate: new Date(),
      }, 'matches', item.id)
        .then((e) => {
          actions.addResource('log-event', user.token, { action: 'talent_connect' }, 'users', 'log-event');
          if (e.type === types.UPDATE_RESOURCE_SUCCESS) {
            this.setState({ openConfirmationModal: false });
          }
          actions.getAllResources(user.token, 'messages').then(() => {
            history.push(`/messages/${this.state.currentItem.id}`);
          });
        });
    } else { // for open externships posts --> create a match
      let payload = {
        job_posting_id: item.id,
        status: 'connected',
        connectDate: new Date(),
        savedSearchSnapshop: '{}',
      };
      actions.addResource('matches', user.token, payload, 'matches').then((res) => {
        actions.addResource('log-event', user.token, { action: 'talent_connect' }, 'users', 'log-event');
        if (res?.id) {
          this.setState({ openConfirmationModal: false });
          this.refreshItems();
          actions.getAllResources(user.token, 'messages').then(() => {
            history.push(`/messages/${res?.id}`);
          });
        }
      });
    }
  };

  renderConfirmEmail = (isMobile) => {
    const { user, classes, actions } = this.props;
    if (user && !user.isVerified) {
      return (
        <div className={classes.confirmEmailContainer} style={!isMobile ? { marginLeft: '26px' }: {}}>
          <img className={classes.warningIcon} src={`${IMG_STORAGE_LINK}icons/warning.svg`} alt="warning" />
          <ConfirmEmail user={user} actions={actions} />
        </div>
      );
    }
    return null;
  };

  handleDeleteMatch = () => {
    const { actions, user, resources } = this.props;
    const { matchToBeDeleted } = this.state;

    this.setState({
      openDeleteConfirmDialog: false,
      isMatchDeleted: true,
    }, () => {
      setTimeout(() => actions.deleteResource('matches', user.token, 'matches', matchToBeDeleted.id)
        .then(() => {
          this.getCalendarMatches();
          this.refreshStats();
        })
        .then(() => this.setState({ isMatchDeleted: false })), TIMEOUT_DURATION);
    });
  };

  updateCalendarDates(filteredPosts, filteredMatches) {
    const { resources } = this.props;

    // matches
    const calendarMatches = resources['jobPostingCalendars/getCurrentCalendarMatches'];
    let calendarMatchesUpdated = {};

    if (calendarMatches) {
    const originalAvailable = calendarMatches?.available;
    const originalConfirmed = calendarMatches?.confirmed;

    const available = this.filterDateByMatches(originalAvailable, filteredMatches);
    const confirmed = this.filterDateByMatches(originalConfirmed, filteredMatches);
    calendarMatchesUpdated = { available, confirmed };
    }

    // posts
    const openExternJobPostings = resources['jobpostings/open-externships'];
    let newPostAvaiDates = [];

    if (openExternJobPostings) {
      let allAvailableDates = [];
      openExternJobPostings.forEach(jp => {
        if (!isEmpty(jp?.available)) allAvailableDates = allAvailableDates.concat(jp?.available);
      });
      allAvailableDates = uniq(allAvailableDates).map(d => DateUtilities.midDayDate(d));

      let filteredAvaiStrDates = (filteredPosts || []).map(jp => jp?.available || []).flat();
      filteredAvaiStrDates = uniq(filteredAvaiStrDates);

      const filteredAvaiDates = filteredAvaiStrDates.map(date => DateUtilities.midDayDate(date));
      newPostAvaiDates = ([...allAvailableDates] || []).filter(date => DateUtilities.dateIn(filteredAvaiDates, date));
    }

    return { calendarMatchesUpdated, newPostAvaiDates };
  }

  filterDateByMatches(object, matches) {
    return object && Object.fromEntries(
      Object.entries(object).filter(([key, value]) => {
        return value.some(item => this.isInArray(item, matches))
      })
    )
  }

  isInArray = (value, array) => array && array.some(item => item?.id === value);

  filterItemsByState = (matches, posts) => {
    const { rotationType, searchByState } = this.state;

    let newFilteredMatches = [];
    let newFilteredPosts = [];

    if (searchByState) {
      newFilteredMatches = newFilteredMatches.concat(matches.filter(item => item?.practice?.state && item.practice.state.toLowerCase() === (searchByState).toLowerCase()));
      newFilteredPosts = newFilteredPosts.concat(posts.filter(item => item?.practice?.state && item.practice.state.toLowerCase() === (searchByState).toLowerCase()));
    }

    // Set by rotationType also
    if (rotationType) {
      newFilteredMatches = !isEmpty(newFilteredMatches)
        ? newFilteredMatches.filter(item => item?.jobPosting?.rotationTypes && (JSON.parse(item.jobPosting.rotationTypes)).includes(rotationType))
        : [];
      newFilteredPosts = !isEmpty(newFilteredPosts)
        ? newFilteredPosts.filter(item => item?.rotationTypes && (JSON.parse(item.rotationTypes)).includes(rotationType))
        : [];
    }
    newFilteredMatches = uniqBy(newFilteredMatches, 'id');
    newFilteredPosts = uniqBy(newFilteredPosts, 'id');
    return { newFilteredMatches, newFilteredPosts };
  };

  filterMatchesByRotationType = (matches, rotationType) => {
    const { searchByState } = this.state;
    if (isEmpty(rotationType) || isEmpty(matches)) return matches;
    let filteredMatches = [];

    if (rotationType) {
      filteredMatches = filteredMatches.concat(matches.filter(item => item?.jobPosting?.rotationTypes && (JSON.parse(item.jobPosting.rotationTypes)).includes(rotationType)));
    }
    if (searchByState && !isEmpty(filteredMatches)) {
      filteredMatches = filteredMatches.filter(item => item?.practice?.state?.toLowerCase() === searchByState.toLowerCase());
    }
    filteredMatches = uniqBy(filteredMatches, 'id');
    return filteredMatches;
  };

  filterPostsByRotationType = (posts, rotationType) => {
    const { searchByState } = this.state;
    if (isEmpty(rotationType) || isEmpty(posts)) return posts;
    let filteredPosts = [];

    if (rotationType) {
      filteredPosts = filteredPosts.concat(posts.filter(item => item?.rotationTypes && (JSON.parse(item.rotationTypes)).includes(rotationType)));
    }
    if (searchByState && !isEmpty(filteredPosts)) {
      filteredPosts = filteredPosts.filter(item => item?.practice?.state?.toLowerCase() === searchByState.toLowerCase());
    }
    filteredPosts = uniqBy(filteredPosts, 'id');
    return filteredPosts;
  };

  filterItemsByDates = (allItems, filteredIds) => (allItems || []).filter((item) => {
    return filteredIds.includes(item.id);
  });

  calcNewMessageCount = (unreadMsgCounts) => {
    return !isEmpty(unreadMsgCounts) ? Object.keys(unreadMsgCounts).length : 0;
  }

  sortMatchesByCreatedAtInDescent = matches => [...matches].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  setCalendarFilterMatches = () => {
    const { calendarMatches } = this.state;
    const { resources } = this.props;

    if (isEmpty(calendarMatches) && (!resources || isEmpty(resources['jobpostings/open-externships']))) return;

    const avaiJobPostingResult = this.setFilteredPosts();
    const avaiFilterResult = this.setFilteredMatchItems(calendarMatches.available);
    const confirmedFilterResult = this.setFilteredMatchItems(calendarMatches.confirmed, true);
    const matchList = [...confirmedFilterResult.matchList, ...avaiFilterResult.matchList];

    let newFilteredDates = [
      ...confirmedFilterResult.calendarFilterDates,
      ...avaiFilterResult.calendarFilterDates,
      ...avaiJobPostingResult.calendarFilterDates,
    ];
    newFilteredDates = uniq(newFilteredDates);

    this.setState({ dateFilterMatches: matchList, dateFilterPosts: avaiJobPostingResult.jobPostingList || []});

    // Reset localStorage filterDatesInStr
    this.setState({ filterDates: newFilteredDates.map(d => DateUtilities.midDayDate(d)) });
    localStorage.setItem('filterDatesInStr', newFilteredDates.join(','));
  };

  setFilteredMatchItems = (calendarObj, isConfirmed) => {
    const { resources } = this.props;
    const { matches } = resources;
    const { filterDates } = this.state;
    const returnObj = { matchList: [], calendarFilterDates: [] };

    if (isEmpty(filterDates) || isEmpty(calendarObj)) return returnObj;

    const calendarKeys = Object.keys(calendarObj);
    if (isEmpty(calendarKeys)) return returnObj;

    const filteredMatches = [...matches];

    filterDates.forEach((filterDate) => {
      const foundDateKeys = calendarKeys.filter(dateKey => DateUtilities.isSameDay(filterDate, DateUtilities.midDayDate(dateKey)));
      let matchIds = [];
      foundDateKeys.forEach((key) => matchIds = matchIds.concat(calendarObj[key]));

      !isEmpty(matchIds) && matchIds.forEach((id) => {
        const matchObj = filteredMatches.find(m => m.id === id);
        if (matchObj) {
          returnObj.matchList.push({
            id,
            title: formatPostTitle(matchObj.jobPosting.title),
            practiceName: matchObj.practice.name,
            practiceId: matchObj.practice.id,
            practice: matchObj.practice,
            badges: matchObj.badges,
            jobPosting: matchObj.jobPosting,
            isConnected: matchObj.status === 'connected',
            isConfirmed
          });
        }
      });
    });
    returnObj.matchList = uniqBy(returnObj.matchList, 'id');
    const calendarDates = calendarKeys.map(d => DateUtilities.midDayDate(d));
    const dates = filterDates.filter(d => DateUtilities.dateIn(calendarDates, d));
    returnObj.calendarFilterDates = dates.map(d => moment(d).format('YYYY-MM-DD'));

    return returnObj;
  };

  setFilteredPosts = () => {
    const { resources } = this.props;
    const { filterDates } = this.state;
    const returnObj = { jobPostingList: [], calendarFilterDates: [] };

    if (!resources || isEmpty(resources['jobpostings/open-externships'])) return returnObj;

    const openExternJobPostings = resources['jobpostings/open-externships'];
    if (isEmpty(filterDates)) return returnObj;

    let calendarDates = [];
    filterDates.forEach((filterDate) => {
      const jpList =  openExternJobPostings.filter(jp => {
        const dateList = jp?.available?.map(d => DateUtilities.midDayDate(d));
        return DateUtilities.dateIn(dateList, filterDate);
      });

      // Add available dates
      !isEmpty(jpList) && jpList.forEach(jobPosting => {
        if (!isEmpty(jobPosting?.available)) {
          returnObj.jobPostingList.push({
            id: jobPosting.id,
            title: formatPostTitle(jobPosting.title),
            practiceName: jobPosting.practice.name,
            practiceId: jobPosting.practice.id,
            practice: jobPosting.practice,
            badges: jobPosting.badges,
          });
          calendarDates.push(...jobPosting.available);
        }
      })
    });
    returnObj.jobPostingList = uniqBy(returnObj.jobPostingList, 'id');
    calendarDates = uniq(calendarDates).map(d => DateUtilities.midDayDate(d));
    const dates = filterDates.filter(d => DateUtilities.dateIn(calendarDates, d));
    returnObj.calendarFilterDates = dates.map(d => moment(d).format('YYYY-MM-DD'));
    return returnObj;
  };

  setDateListForMatches = (matches, avaiObj) => {
    if (isEmpty(matches) || isEmpty(avaiObj)) return matches;
    const avaiKeys = Object.keys(avaiObj);
    let matchIdsObj = {};

    avaiKeys.forEach((dateKey) => {
      const ids = avaiObj[dateKey];
      ids.forEach((id) => {
        if (matchIdsObj[id]) {
          matchIdsObj[id] = matchIdsObj[id].concat([dateKey]);
        } else {
          matchIdsObj[id] = [dateKey];
        }
      });
    });

    matches = matches.map((match) => {
      let dateList;
      if (isReliefOrExternshipType(match?.jobPosting?.typeOfEmployment) && matchIdsObj[match.id]) {
        let dates = matchIdsObj[match.id];
        dateList = dates.map(a => moment(a).format('MMM.DD')).join(', ');
      }
      return { ...match, dateList }
    });
    return matches;
  };

  getEarliestDate = (dateList) => {
    const sortedDateList = dateList ? dateList.sort((date1, date2) => (date1 - date2)) : [];
    return sortedDateList.length ? sortedDateList[0] : null;
  }

  matchesOnSearch = matchesToDisplay => matchesToDisplay.filter(item => convertHTMLToText(item?.jobPosting?.description?.toLowerCase()).includes(this.state.searchTerm.toLowerCase()));

  postsOnSearch = postsToDisplay => postsToDisplay.filter(item => convertHTMLToText(item?.description?.toLowerCase()).includes(this.state.searchTerm.toLowerCase()));

  itemsOnSearchByState = itemsToDisplay => itemsToDisplay.filter(item => item?.practice?.state?.toLowerCase() === this.state.searchByState?.toLowerCase());

  getItemPractices = (item) => {
    const { practices, state } = item;
    if (practices && practices.length) {
      let practiceNames = practices.map(practice => practice.name);
      if (practiceNames.length > 3) {
        return `${practiceNames.slice(0, 3).join(', ')}...`;
      } else {
        return practiceNames.join(', ');
      }
    }
    return state || '';
  }

  confirmationModalClicked = () => {
    const { actions, user, resources } = this.props;
    const { mobileVerificationCode } = this.state;
    const updatedUser = {
      mobileVerificationCode,
    };
    actions.updateResource('updateUserMobileCode', user.token, updatedUser, 'users', user.id)
      .then(this.setState({ openPhoneVerification: false }));
  }

  setMarkers = (matchesToDisplay, filteredPostItems) => {
    const list = [...matchesToDisplay, ...filteredPostItems];
    const totalItems = !isEmpty(list) ? list.map((i, index) => ({
      ...i,
      practiceId: i?.practice?.id,
      matchId: i.jobPosting ? i.id : null,
      postId : !i.jobPosting ? i.id : null,
      index: index,
    })) : [];
    const allLocations = !isEmpty(totalItems) ? uniqBy(totalItems, 'practiceId').map(item => item.practice) : [];
    const markers = !isEmpty(allLocations) ? allLocations.filter(l => l && l.lat && l.lng)
      .map(l => {
        if (!l) return null;
        const matchCount = totalItems.filter(m => m.practiceId === l.id).length;
        return { id: l.id, name: l.name, position: { lat: l.lat, lng: l.lng }, matchCount };
      }) : [];
    return { markers, totalItems };
  };

  getRotationTypes = (matches, posts) => {
    let rotationTypes = [];
    matches && matches.forEach(match => {
      if (match.jobPosting && match.jobPosting.typeOfEmployment === 'Externship' && match.jobPosting.rotationTypes !== null) {
        rotationTypes = [...rotationTypes, JSON.parse(match.jobPosting.rotationTypes)]
      }
    })
    posts && posts.forEach(post => {
      if (post.rotationTypes !== null) {
        rotationTypes = [...rotationTypes, JSON.parse(post.rotationTypes)]
      }
    })
    return uniq(flatMapDeep(rotationTypes));
  }

  renderMobile = () => {
    const {
      classes, resources, actions, user, history,
    } = this.props;
    const {
      filterDates,
      fastpassData,
      practiceFilterMatchIds,
      unreadMsgCounts,
      calendarDrawerOpen,
      searchDrawerOpen,
      mapView,
      openMobileCalendar,
      searchByState,
      isMatchDeleted,
      matchToBeDeleted,
    } = this.state;

    const {
      rotationTypes,
      allMatches,
      allPosts,
      filteredPostItems,
      matchesToDisplayOnSearch,
      newPostAvaiDates,
      availableDates,
      confirmedDates,
      markers,
      totalItems,
    } = this.prepareData();
    const element = document.getElementById('mobileJobCardContainer');

    return (
      <>
        <div style={{ position: 'fixed', zIndex: 200 }}>
          <MatchFilter
            isExternshipUser
            isMobile
            history={history}
            practiceFilterMatchIds={practiceFilterMatchIds}
            filterSearchTitles={[]}
            filterBtns={[]}
            filterDates={filterDates}
            resetFilter={() => {}}
            searchTerm={this.state.searchTerm}
            setSearchTerm={(value) => this.setState({ searchTerm: value })}
            searchByStateHandle={() => {}}
            disableCalendarIcon={openMobileCalendar}
            handleSetState={() => this.setState({ openMobileCalendar: true })}
            searchHandle={() => {}}
            rotationTypes={rotationTypes}
            onChangeRotationType={(title) => {
              this.setState({ rotationType: title });
              const filteredPosts = this.filterPostsByRotationType(allPosts, title);
              const filteredMatches = this.filterMatchesByRotationType(allMatches, title);
              this.updateCalendarDates(filteredPosts, filteredMatches);
            }}
            resources={resources}
          />
        </div>
        <Grid container className={classes.externMobileRoot}>
          {user && !user.isVerified && (<Grid item >{this.renderConfirmEmail(true)}</Grid>)}
          {openMobileCalendar && (<>
            <Grid item style={{ borderBottom: `1px solid ${lightGray}` }}>
              <CalendarFilter
                availableDates={ [...availableDates, ...newPostAvaiDates] || []}
                confirmedDates={confirmedDates || []}
                initialDate={filterDates.length ? this.getEarliestDate(filterDates) : this.getEarliestDate(availableDates)}
                handleStateChange={(filterDates) => {
                  this.setState({
                    filterDates,
                  }, () => {
                    this.setCalendarFilterMatches();
                  });
                }}
                outerFilterDates={[...filterDates]}
                isMobile
                buttonText='Reset'
              />
            </Grid>
            <Grid item className={classes.closeIconContainer}>
              <Box className={classes.topDrawerCloseBtn}>
                <IconButton onClick={() => this.setState({ openMobileCalendar: false })} size='small'>
                  <KeyboardArrowUpIcon style={{ fontSize: '16px' }} />
                </IconButton>
              </Box>
            </Grid></>
          )}

          <Grid item className={classes.matchMobileContainer}>
            {/* Map view */}
            {mapView && (<>
              <div style={{ padding: '0px 16px 16px 16px', display: 'flex', justifyContent: 'center' }}>
                <MatchesSearchBar
                  value={this.state.searchTerm}
                  onChange={value => this.setState({ searchTerm: value })}
                  placeholder='Search description'
                  onClickClearIcon={() => this.setState({ searchTerm: '' })}
                  className={classes.matchSeachBar}
                />
              </div>
              {!isEmpty(markers) && (
              <div className={classes.mMapContainer}>
                <LocationGoogleMap
                  isMobile
                  markers={markers}
                  totalItems={totalItems}
                  fastpassData={fastpassData}
                  containerStyle={{
                    padding: 0,
                    margin: 0,
                    with: '100%',
                    height: '100%',
                    overflowY: 'visible',
                  }}
                />
              </div>)
              }
            </>)}
            {/* Card view */}
            {!mapView && (
              <Grid item className={classes.mExternJobCardContainer} id='mobileJobCardContainer'
                style={openMobileCalendar ?  { maxHeight: '600px' } : {} }
                onScroll={(e) => {
                  if (element?.scrollTop >= 10) {
                    this.setState({ openMobileCalendar: false });
                  }
                }}
              >
                <Grid container className={classes.jobMatchContainer}>
                  <Grid item xs={12} style={{ padding: '0px 16px 8px 16px', display: 'flex', justifyContent: 'center' }}>
                    <div className={classes.rotationSelectContainer}>
                      <div className={classes.rotationIcon} style={{ color: !!this.state.rotationType ? '#4bbdad' : 'rgba(0,0,0,.5)'}}><AddBoxIcon /></div>
                      <Select
                        variant="outlined"
                        value={this.state.rotationType}
                        onChange={(event) => {
                          const title = event?.target?.value;
                          this.setState({ rotationType: title });
                          const filteredPosts = this.filterPostsByRotationType(allPosts, title);
                          const filteredMatches = this.filterMatchesByRotationType(allMatches, title);
                          this.updateCalendarDates(filteredPosts, filteredMatches);
                        }}
                        className={classes.rotationSelect}
                        displayEmpty
                        MenuProps={MenuProps}
                        input={<OutlinedInput />}
                      >
                        <MenuItem value="">
                          <em>Specialty</em>
                        </MenuItem>
                        {
                          rotationTypes && rotationTypes.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)
                        }
                      </Select>
                      </div>
                  </Grid>
                  <Grid item xs={12} style={{ padding: '0px 16px 8px 16px', display: 'flex', justifyContent: 'center' }}>
                    <div className={classes.rotationSelectContainer}>
                    <LocationSearchInput
                      variant="outlined"
                      address={searchByState}
                      onChange={(addressObj) => {
                        this.setState({ searchByState: addressObj?.state || ''}, () => {
                          const { newFilteredMatches, newFilteredPosts } = this.filterItemsByState(allMatches, allPosts);
                          this.updateCalendarDates(newFilteredPosts, newFilteredMatches);
                        });
                      }}
                      fullWidth
                      placeholder='Search by state'
                      isSearchBar
                      stateSearch
                    />
                    </div>
                  </Grid>
                  {this.state.isLoading
                    ? (<div className={classes.loading}><CircularProgress color="inherit" /></div>)
                    : isEmpty(matchesToDisplayOnSearch) && isEmpty(filteredPostItems)
                      ? (<Typography className={classes.noMatchesText}>No matches</Typography>)
                      : (<>
                      {matchesToDisplayOnSearch.map(item => item.jobPosting ? (
                        <Grid key={`${item.id}_m_match_card`} item
                          xs={12} lg={calendarDrawerOpen && searchDrawerOpen ? 6 : calendarDrawerOpen || searchDrawerOpen ? 4 : 3}
                          style={{ padding: '0px 16px 16px 16px' }}
                        >
                          <JobMatchCard
                            user={user}
                            refreshStats={() => this.refreshStats()}
                            actions={actions}
                            item={item}
                            reliefDates={this.state.dateFilterMatches}
                            confirmConnectToMatch={() => this.confirmConnectToMatch(item)}
                            unreadCount={!isEmpty(unreadMsgCounts) ? unreadMsgCounts[item.id] : 0}
                            deleteMatch={() => this.setState({
                              openDeleteConfirmDialog: true,
                              matchToBeDeleted: item,
                            })}
                            open={!(matchToBeDeleted.id === item.id && isMatchDeleted)}
                            fastpassData={fastpassData}
                            isMobile
                            externHome
                          />
                        </Grid>
                      ): null)}
                      {!isEmpty(filteredPostItems) && filteredPostItems.map(item => (
                      <Grid key={`${item.id}_m_posting`} item
                        xs={12} lg={calendarDrawerOpen && searchDrawerOpen ? 6 : calendarDrawerOpen || searchDrawerOpen ? 4 : 3}
                        style={{ padding: '0px 16px 16px 16px' }}
                      >
                        <OpenExternJobCard
                          user={user}
                          refreshStats={() => this.refreshStats()}
                          actions={actions}
                          item={item}
                          reliefDates={this.state.dateFilterMatches}
                          confirmConnectToMatch={() => this.confirmConnectToMatch(item)}
                          deleteMatch={() => {}}
                          open={!(matchToBeDeleted.id === item.id && isMatchDeleted)}
                          fastpassData={fastpassData}
                          isMobile
                        />
                      </Grid>
                    ))}
                    </>)
                  }
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Fab
          variant="extended"
          className={classes.mapViewFabBtn}
          onClick={() => {
            this.setState({ mapView: !mapView }, () => {
              if (this.state.mapView) {
                this.setState({ openMobileCalendar: false })
              }
            });
          }}
        >
          {mapView ? (<StorageOutlinedIcon className={classes.matchIteminfoIcon} color={orange} />) : <PlaceIcon className={classes.matchIteminfoIcon} />}
          <Typography>{mapView ? 'Card View' : 'Map View'}</Typography>
        </Fab>

        {renderConnectModal({
          ...this.props,
          openConfirmationModal: this.state.openConfirmationModal,
          currentItem: this.state.currentItem,
          onClose: () => this.setState({ openConfirmationModal: false }),
          onOk: () => this.connectToMatch(this.state.currentItem),
        })}
        <DeleteConfirmDialog
          resourceName="Job Post"
          open={this.state.openDeleteConfirmDialog}
          handleDelete={() => this.handleDeleteMatch()}
          handleClose={() => this.setState({ openDeleteConfirmDialog: false })}
          jobTitle={matchToBeDeleted && matchToBeDeleted.jobPosting && formatPostTitle(matchToBeDeleted.jobPosting.title)}
          practiceName={matchToBeDeleted && matchToBeDeleted.practice && matchToBeDeleted.practice.name}
          message="Are you sure you want to remove the post below - any new dates added to this post will not be visible!"
          hasCancelButton
        />
      </>
    );
  }

  calcPostAvaiDates = () => {
    const { resources } = this.props;
    const openExternJobPostings = resources['jobpostings/open-externships'];

    let allAvailableDates = [];
    openExternJobPostings.forEach(jp => {
      if (!isEmpty(jp?.available)) allAvailableDates = allAvailableDates.concat(jp?.available);
    });
    allAvailableDates = uniq(allAvailableDates).map(d => DateUtilities.midDayDate(d));
    return allAvailableDates;
  }

  prepareData = () => {
    const { resources } = this.props;
    const { matches } = resources;
    const {
      dateFilterMatches,
      dateFilterPosts,
      searchByState,
      calendarMatches,
    } = this.state;

    const allMatches = matches || [];
    const allPosts = (resources && resources['jobpostings/open-externships']) || [];
    const rotationTypes = this.getRotationTypes(matches, allPosts);
    let filteredMatchItems = allMatches || [];

    let matchesToDisplay = this.sortMatchesByCreatedAtInDescent(filteredMatchItems);
    matchesToDisplay = sortBy(matchesToDisplay, ['status'], ['desc']);
    let matchesToDisplayOnSearch = this.matchesOnSearch(matchesToDisplay);

    if (this.state.rotationType !== '') {
      matchesToDisplayOnSearch = this.filterMatchesByRotationType(filteredMatchItems, this.state.rotationType);
    }

    if (searchByState !== '') {
      matchesToDisplayOnSearch = this.itemsOnSearchByState(matchesToDisplayOnSearch);
    }

    let filteredPostItems = allPosts || [];
    // Exclude posts already in the match list
    filteredPostItems = !isEmpty(matchesToDisplayOnSearch)
      ? filteredPostItems.filter((item) => !matchesToDisplayOnSearch.find(m => m.job_posting_id === item.id)) : filteredPostItems;
      filteredPostItems = sortBy(filteredPostItems, ['updatedAt'], ['asc']);
    
    filteredPostItems = this.postsOnSearch(filteredPostItems);
    if (this.state.rotationType !== '') {
      filteredPostItems = this.filterPostsByRotationType(filteredPostItems, this.state.rotationType);
    }

    if (searchByState !== '') {
      filteredPostItems = this.itemsOnSearchByState(filteredPostItems);
    }

    const { calendarMatchesUpdated, newPostAvaiDates } = this.updateCalendarDates(filteredPostItems, matchesToDisplayOnSearch);
    const availableDates = calendarMatchesUpdated
    && calendarMatchesUpdated.available ? Object.keys(calendarMatchesUpdated.available).map(d => DateUtilities.midDayDate(d)) : [];
    const confirmedDates = calendarMatchesUpdated
      && calendarMatchesUpdated.confirmed ? Object.keys(calendarMatchesUpdated.confirmed).map(d => DateUtilities.midDayDate(d)) : [];

    matchesToDisplayOnSearch = !isEmpty(dateFilterMatches)
      ? this.filterItemsByDates(matchesToDisplayOnSearch, dateFilterMatches.map(item => item.id))
        : !isEmpty(dateFilterPosts)
          ? [] : matchesToDisplayOnSearch;

    if (!isEmpty(calendarMatchesUpdated?.available)) {
      matchesToDisplayOnSearch = this.setDateListForMatches(matchesToDisplayOnSearch, calendarMatches?.available);
    }

    // Set posts to display filtered ones by dates
    filteredPostItems = !isEmpty(dateFilterPosts)
      ? this.filterItemsByDates(filteredPostItems, dateFilterPosts.map(item => item.id))
        : !isEmpty(dateFilterMatches) ? [] : filteredPostItems;

    const { markers, totalItems } = this.setMarkers(matchesToDisplayOnSearch, filteredPostItems);

    return {
      rotationTypes,
      allMatches,
      allPosts,
      filteredPostItems,
      matchesToDisplayOnSearch,
      newPostAvaiDates,
      availableDates,
      confirmedDates,
      markers,
      totalItems,
    }
  }
  renderDesktop = () => {
    const {
      classes, resources, actions, user, search, history,
    } = this.props;
    const {
      filterDates,
      fastpassData,
      practiceFilterMatchIds,
      unreadMsgCounts,
      calendarDrawerOpen,
      searchDrawerOpen,
      mapView,
      searchByState
    } = this.state;
    const { isMatchDeleted, matchToBeDeleted } = this.state;
    
    const {
      rotationTypes,
      allMatches,
      allPosts,
      filteredPostItems,
      matchesToDisplayOnSearch,
      newPostAvaiDates,
      availableDates,
      confirmedDates,
      markers,
      totalItems,
    } = this.prepareData();

    return (
      <div className={classes.root}>
        <div style={{ position: 'fixed', zIndex: 200 }}>
          <MatchFilter
            isExternshipUser
            history={history}
            practiceFilterMatchIds={practiceFilterMatchIds}
            filterSearchTitles={[]}
            filterBtns={[]}
            filterDates={filterDates}
            resetFilter={() => {}}
            searchTerm={this.state.searchTerm}
            setSearchTerm={(value) => this.setState({ searchTerm: value })}
            searchByState={searchByState}
            searchByStateHandle={(value) => {
              this.setState({ searchByState: value }, () => {
                const { newFilteredMatches, newFilteredPosts } = this.filterItemsByState(allMatches, allPosts);
                this.updateCalendarDates(newFilteredPosts, newFilteredMatches);
              });
            }}
            resources={resources}
            handleSetState={() => this.setState({ openMobileCalendar: true })}
            rotationTypes={rotationTypes}
            onChangeRotationType={(title) => {
              this.setState({ rotationType: title });
              const filteredPosts = this.filterPostsByRotationType(allPosts, title);
              const filteredMatches = this.filterMatchesByRotationType(allMatches, title);
              this.updateCalendarDates(filteredPosts, filteredMatches);
            }}
          />
        </div>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={calendarDrawerOpen}
          classes={{
            paper: classes.externDrawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <Typography variant='subtitle1' className={classes.info}>Select available dates to filter matches!</Typography>
            <Box className={classes.leftDrawerCloseBtn}>
              <IconButton onClick={() => this.setState({ calendarDrawerOpen: false })} size='small'>
                <ChevronLeftIcon style={{ fontSize: '16px' }} />
              </IconButton>
            </Box>
          </div>
          <div>
            <CalendarFilter
              availableDates={ [...availableDates, ...newPostAvaiDates] || []}
              confirmedDates={confirmedDates || []}
              initialDate={filterDates.length ? this.getEarliestDate(filterDates) : this.getEarliestDate(availableDates)}
              handleStateChange={(filterDates) => {
                this.setState({
                  filterDates,
                }, () => {
                  this.setCalendarFilterMatches();
                });
              }}
              outerFilterDates={[...filterDates]}
              buttonText='Reset'
            />
          </div>
        </Drawer>
        {!calendarDrawerOpen && (
          <Fab
            variant="extended"
            className={classes.drawerFabBtn} style={{ left: -31, top: 285, rotate: 'z 90deg', }}
            onClick={() => this.setState({ calendarDrawerOpen: true })}
          >
            Calendar
          </Fab>
        )}

        <div
          className={classNames(classes.matchContainer, {
            [classes.contentShiftLeft]: calendarDrawerOpen,
            [classes.contentShiftRight]: searchDrawerOpen,
          })}
        >
          <Toolbar
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              padding: 0,
              marginTop: '60px',
              width: '100%',
              minHeight: '44px',
            }}
          >
            {this.renderConfirmEmail()}
            <div className={classes.bcRoot}>
              <div className={classes.matchTitleContainer}>
                <Typography color="secondary" className={classes.title}>Open Externships</Typography>
              </div>
            </div>
          </Toolbar>
          {/* Map view */}
          {mapView && !isEmpty(markers) && (
            <div className={classes.mapContainer}>
              <LocationGoogleMap
                markers={markers}
                totalItems={totalItems}
                fastpassData={fastpassData}
                containerStyle={{
                  padding: 0,
                  margin: 0,
                  with: '100%',
                  height: '100%',
                  overflowY: 'visible',
                }}
              />
            </div>
          )}
          {/* Card view */}
          {!mapView && (
            <div className={classes.jobCardContainer}>
              <Grid container className={classes.jobMatchContainer}>
                {this.state.isLoading
                  ? (<div className={classes.loading}><CircularProgress color="inherit" /></div>)
                  : isEmpty(matchesToDisplayOnSearch) && isEmpty(filteredPostItems)
                    ? (<Typography className={classes.noMatchesText}>No matches</Typography>)
                    : (<>
                      {matchesToDisplayOnSearch.map(item => item.jobPosting ? (
                      <Grid key={item.id} item
                        xs={12} lg={calendarDrawerOpen && searchDrawerOpen ? 6 : calendarDrawerOpen || searchDrawerOpen ? 4 : 3}
                        style={{ padding: '0px 16px 16px 16px' }}
                      >
                        <JobMatchCard
                          enableDelete
                          user={user}
                          refreshStats={() => this.refreshStats()}
                          actions={actions}
                          item={item}
                          reliefDates={this.state.dateFilterMatches}
                          confirmConnectToMatch={() => this.confirmConnectToMatch(item)}
                          unreadCount={!isEmpty(unreadMsgCounts) ? unreadMsgCounts[item.id] : 0}
                          deleteMatch={() => this.setState({
                            openDeleteConfirmDialog: true,
                            matchToBeDeleted: item,
                          })}
                          open={!(matchToBeDeleted.id === item.id && isMatchDeleted)}
                          fastpassData={fastpassData}
                          externHome
                        />
                      </Grid>
                    ): null)}
                    {!isEmpty(filteredPostItems) && filteredPostItems.map(item => (
                      <Grid key={`${item.id}_posting`} item
                        xs={12} lg={calendarDrawerOpen && searchDrawerOpen ? 6 : calendarDrawerOpen || searchDrawerOpen ? 4 : 3}
                        style={{ padding: '0px 16px 16px 16px' }}
                      >
                        <OpenExternJobCard
                          user={user}
                          refreshStats={() => this.refreshStats()}
                          actions={actions}
                          item={item}
                          reliefDates={this.state.dateFilterMatches}
                          confirmConnectToMatch={() => this.confirmConnectToMatch(item)}
                          deleteMatch={() => {}}
                          open={!(matchToBeDeleted.id === item.id && isMatchDeleted)}
                          fastpassData={fastpassData}
                        />
                      </Grid>
                    ))}
                    </>)
                }
              </Grid>
            </div>
          )}
        </div>

        <Fab
          variant="extended"
          className={classes.mapViewFabBtn}
          onClick={() => this.setState({ mapView: !mapView })}
        >
          {mapView ? (<StorageOutlinedIcon className={classes.matchIteminfoIcon} color={orange} />) : <PlaceIcon className={classes.matchIteminfoIcon} />}
          <Typography>{mapView ? 'Card View' : 'Map View'}</Typography>

        </Fab>

        {renderConnectModal({
          ...this.props,
          openConfirmationModal: this.state.openConfirmationModal,
          currentItem: this.state.currentItem,
          onClose: () => this.setState({ openConfirmationModal: false }),
          onOk: () => this.connectToMatch(this.state.currentItem),
        })}

        <DeleteConfirmDialog
          resourceName="Job Post"
          open={this.state.openDeleteConfirmDialog}
          handleDelete={() => this.handleDeleteMatch()}
          handleClose={() => this.setState({ openDeleteConfirmDialog: false })}
          jobTitle={matchToBeDeleted && matchToBeDeleted.jobPosting && formatPostTitle(matchToBeDeleted.jobPosting.title)}
          practiceName={matchToBeDeleted && matchToBeDeleted.practice && matchToBeDeleted.practice.name}
          message="Are you sure you want to remove the post below - any new dates added to this post will not be visible!"
          hasCancelButton
        />
      </div>
    );
  }

  render() {
    const { isDesktop } = this.props;

    return !isDesktop ? this.renderMobile() : this.renderDesktop();
  }
}

ExternHome.propTypes = {};

function ExternHomeWrapper({ theme, ...rest }) {
  const isDesktop = theme ? useMediaQuery(theme.breakpoints.up('md')) : true;
  return <ExternHome {...rest} theme={theme} isDesktop={isDesktop} />;
}

export default withStyles(MatchesComponentStyle, { withTheme: true })(withRouter(ExternHomeWrapper));
