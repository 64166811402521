import React, { Component } from 'react';
import OnboardingItemComponent from './OnboardingItemComponent';
import SimpleSelectableIconComponent from './SimpleSelectableIconComponent';
import { withStyles } from '@mui/styles';
import { AnimalTypeSlider, AnimalTypeStyle } from '../../../css/style';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

const PrettoSlider = withStyles(AnimalTypeSlider)(Slider);

class AnimalTypeComponent extends OnboardingItemComponent {
  constructor(props) {
    super(props);
    const { initState, type1, type2, handleStateChange, criteria } = props;
    const st = {};
    const items = [];
    initState.map((item) => {
      if (item.strValue === type1) {
        st.type1selected = true;
        st.type1Value = item.numValue;
      } else if (item.strValue === type2) {
        st.type2selected = true;
        st.type2Value = item.numValue;
      }
      items.push({ criteria, strValue: item.strValue, numValue: item.numValue });
    });
    handleStateChange && handleStateChange(items);
    this.state = st;
  }

  handleState(type1Value, type2Value) {
    const {
      type1, type2, handleStateChange, criteria,
    } = this.props;
    const items = [];
    if (type1Value) { items.push({ criteria, strValue: type1, numValue: type1Value }); }
    if (type2Value) { items.push({ criteria, strValue: type2, numValue: type2Value }); }
    handleStateChange && handleStateChange(items);
  }

  typeClicked(type) {
    const st = {};
    st[type] = !this.state[type];
    this.setState(st, () => {
      if (this.state.type1selected && this.state.type2selected) {
        this.handleState(50, 50);
      } else if (this.state.type1selected) {
        this.handleState(100, 0);
      } else if (this.state.type2selected) {
        this.handleState(0, 100);
      } else {
        this.handleState(0, 0);
      }
    });
  }

  render() {
    const { type1, type2 } = this.props;
    const { type1selected, type2selected, type1Value } = this.state;
    const type1Label = type1.replace('-', ' ').toUpperCase();
    const type2Label = type2.replace('-', ' ').toUpperCase();
    return (
      <Grid container spacing={1} justifyContent="center">
        <Grid item>
          <SimpleSelectableIconComponent
            icon={type1}
            selectedIcon={`${type1}-selected`}
            title={type1Label}
            selected={type1selected}
            onClick={e => this.typeClicked('type1selected')}
          />
        </Grid>
        <Grid item>
          <SimpleSelectableIconComponent
            icon={type2}
            selectedIcon={`${type2}-selected`}
            title={type2Label}
            selected={type2selected}
            onClick={e => this.typeClicked('type2selected')}
          />
        </Grid>
        {
          type1selected && type2selected
          && (
            <Grid item xs={12}>
              <div style={{ maxWidth: 290, margin: 'auto' }}>
                <PrettoSlider
                  valueLabelDisplay="auto"
                  aria-label="pretto slider"
                  defaultValue={type1Value || 50}
                  marks={[{ value: 0, label: type1Label }, { value: 101, label: type2Label }]}
                  onChange={(e, value) => this.handleState(value, 100 - value)}
                />
              </div>
            </Grid>
          )
        }
      </Grid>
    );
  }
}

AnimalTypeComponent.propTypes = {
  type1: PropTypes.string.isRequired,
  type2: PropTypes.string.isRequired,
  criteria: PropTypes.string.isRequired,
  initState: PropTypes.array.isRequired,
  handleStateChange: PropTypes.func.isRequired,
};

AnimalTypeComponent.defaultProps = {
  initState: [],
};
export default withStyles(AnimalTypeStyle)(AnimalTypeComponent);
