const handleResponse = (request, isPlain) => fetch(request)
  .then((response) => {
    if (response.status > 399) {
      if (response.status === 502) {
        return Promise.reject(new Error('Cannot process your request. Please try again in a few seconds.'));
      } else if (response.status === 401) {
        return Promise.reject(new Error('Token expired.'));
      }
    }
    if (response.status === 204) {
      return Promise.resolve({ data: null, response });
    }
    return (!isPlain ? response.json() : response.text()).then(data => ({
      data,
      response,
    }));
  })
  .then(({ data, response }) => {
    if (!response.ok) {
      return Promise.reject(!isPlain ? data.message : data);
    }
    if (response.status === 204) {
      return Promise.resolve();
    }
    const body = {
      data,
    };
    return Promise.resolve(body);
  })
  .catch((error) => {
    if (error.message === 'Failed to fetch') {
      return Promise.reject(new Error('Server is not responding'));
    }
    return Promise.reject(error);
  });

export default handleResponse;
