import handleResponse from '../ResponseHandler';

const baseUrl = window.api_host;
const headers = { 'Content-Type': 'application/json' };

export default class UsersApi {
  static loginUser(userData) {
    const path = `${baseUrl}users/login`;
    const request = (
      new Request(path, {
        method: 'POST',
        headers,
        body: JSON.stringify(userData),
      })
    );
    return handleResponse(request);
  }

  static signUpUser(userData) {
    const path = `${baseUrl}users/signup`;
    const request = (
      new Request(path, {
        method: 'POST',
        headers,
        body: JSON.stringify(userData),
      })
    );
    return handleResponse(request);
  }

  static oauthCallback(search, provider, action) {
    const callback = `${window.location.origin}/${action}/${provider}`;
    const path = `${baseUrl.replace('api', 'oauth')}${provider}/callback${search}&portal=talent&action=${action}&callbackUrl=${callback}`;
    const request = (
      new Request(path, {
        method: 'GET',
        headers,
      })
    );
    return handleResponse(request);
  }

  static oauth(action, provider) {
    const callback = `${window.location.origin}/${action}/${provider}`;
    window.location = `${baseUrl.replace('api', 'oauth')}${provider}?portal=talent&action=${action}&callbackUrl=${callback}`;
  }
}
