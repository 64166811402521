import {
  Container, Fade, Modal, Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import React, { Component } from 'react';
import { theme } from '../../components/App.jsx';
import {
  CERTIFIED_VET_COMFORT_LEVEL,
  CURRENT_ROLE_CRITERIA,
  NON_CLINICAL_CURRENT_ROLE_CRITERIA,
  VET_ASSISTANT_COMFORT_LEVEL,
  FIRST_WB_SEARCH_CONFIG,
  FIRST_EXTERN_WB_SEARCH_CONFIG, COLORS,
} from '../../config/enums';
import { OnboardingFlowStyle, orange } from '../../css/style';
import BackButton from '../subcomponents/BackButton';
import { JOB_TYPE_SUB_CATEGORY, IMG_STORAGE_LINK } from '../constants/Constants';
import { isTemporaryUser } from '../../utils/Functions';

class OnboardingFlowComponent extends Component {
  constructor(props, maxSteps) {
    super(props);
    this.state = {
      step: 0,
      onboardingItems: new Map(),
      maxSteps,
      continueDisabled: true,
      optionalSelectSteps: [],
      skippedSteps: [],
      modalOpen: false,
      defaultSavedSearches: [],
      useDefaultSavedSearches: false,
    };
  }

  componentDidMount = () => {
    try {
      window.scrollTo(0, 0);
      document.getElementById('main')
        .scrollTo(0, 0);
    } catch (e) {
      console.log(e);
    }
  };

  initSubCategoryItems = (st, selectedValue, criteria) => {
    const {
      talentprofileitems,
      criterias,
    } = this.props.resources;
    st[selectedValue] = this.findTalentProfileItemValue(criteria, talentprofileitems, criterias);
    st.jobTypeSubCategory = this.findTalentProfileItemValue(JOB_TYPE_SUB_CATEGORY, talentprofileitems, criterias);
    st.continueDisabled = !(!!st[selectedValue] && !!st.jobTypeSubCategory);
    if (st[selectedValue]) {
      const item = new Map();
      item.set(criteria, [{
        criteria,
        strValue: st[selectedValue],
      }]);
      st['step0-onboardingItems'] = item;
    }
    if (st.jobTypeSubCategory) {
      const item = new Map();
      item.set(JOB_TYPE_SUB_CATEGORY, [{
        criteria: JOB_TYPE_SUB_CATEGORY,
        strValue: st.jobTypeSubCategory,
      }]);
      st['step1-onboardingItems'] = item;
    }
  };

  /**
   * @param {index} step
   *  Adds specified step to a list of steps to skip
   *  if it doesn't already exist
   */
  addSkippedStep(step) {
    const { skippedSteps } = this.state;
    if (skippedSteps.indexOf(step) === -1) {
      skippedSteps.push(step);
    }
  }

  /**
   * @param {index} step
   *  Removes specified step from list of steps to skip
   */
  removeSkippedStep(step) {
    const { skippedSteps } = this.state;
    const stepIndex = skippedSteps.indexOf(step);
    if (stepIndex !== -1) {
      skippedSteps.splice(stepIndex, 1);
    }
  }

  getDefaultItems(key) {
    const { resources } = this.props;
    const {
      talentprofileitems,
      criterias,
    } = resources;
    if (!talentprofileitems || !criterias) {
      return [];
    }
    let keyItems = [key];
    if (key === 'current-roles') {
      keyItems = CURRENT_ROLE_CRITERIA.map(item => item.criteria);
    }
    if (key === 'comfort-level-certified-vet') {
      keyItems = CERTIFIED_VET_COMFORT_LEVEL.map(item => item.criteria);
    }
    if (key === 'comfort-level-vet-assistant') {
      keyItems = VET_ASSISTANT_COMFORT_LEVEL.map(item => item.criteria);
    }
    if (key === 'non-clinical-current-role') {
      keyItems = NON_CLINICAL_CURRENT_ROLE_CRITERIA.map(item => item.criteria);
    }
    const critIds = criterias.filter(c => keyItems.indexOf(c.name) >= 0)
      .map(item => item.id);
    const items = talentprofileitems.filter(item => critIds.indexOf(item.criteria_id) >= 0);
    return items;
  }

  getOnboardingItems(key, forceStep) {
    const { step } = this.state;
    const stp = forceStep != undefined ? forceStep : step;
    const onboardingItems = this.state[`step${stp}-onboardingItems`] || new Map();
    return onboardingItems.get(key) || this.getDefaultItems(key) || [];
  }

  setOnboardingItems(key, items) {
    const { step } = this.state;
    const onboardingItems = this.state[`step${step}-onboardingItems`] || new Map();
    onboardingItems.set(key, items);
    const st = {};
    st[`step${step}-onboardingItems`] = onboardingItems;

    if (key === 'student-defaults') {
      this.setState({ studentDefaults: onboardingItems });
    }
    return new Promise((resolve, reject) => this.setState(st, () => resolve()));
  }

  /**
   * @param {string} criteria
   * @param {number} step
   * @param {number} index
   *
   * Confirms that the specified criteria has been filled
   * Continue button is enabled if entire array is true
   */
  verifyCriteriaDataFilled(criteria, step, index) {
    const st = this.state;
    const onboardingItems = this.getOnboardingItems(criteria);
    // Check if criteria values are filled in
    const emptyItem = onboardingItems.find(item => !item.strValue && !item.str2Value
      && (item.numValue === null || item.numValue === undefined));
    st[`step${step}`][index] = !emptyItem && onboardingItems.length > 0;
    this.checkContinueValid();
  }

  setContinueColor(color) {
    this.setState({ continueColor: color });
  }

  handleStateChange(items, criteria, step, index) {
    this.setOnboardingItems(criteria, items)
      .then(() => this.verifyCriteriaDataFilled(criteria, step, index));
  }

  handleDefaultSavedSearches(items, step, index) {
    const st = { defaultSavedSearches: items, useDefaultSavedSearches: true };
    st[`step-${step}`] = [true];
    this.setState(st, () => this.checkContinueValid());
  }

  closeModal = () => {
    const { history, talent } = this.props;
    this.setState({
      modalOpen: false,
    }, () => {
      talent.isStudent ? history.push('/externhome') : history.push('/matches');
    });
  };

  disableContinueButton() {
    this.setState({
      continueDisabled: true,
    });
  }

  enableContinueButton() {
    this.setState({
      continueDisabled: false,
    });
  }

  renderStep0() {
    return 'STEP0';
  }

  renderStep1() {
    return 'STEP1';
  }

  renderStep2() {
    return 'STEP2';
  }

  renderStep3() {
    return 'STEP3';
  }

  renderStep4() {
    return 'STEP4';
  }

  renderStep5() {
    return 'STEP5';
  }

  renderStep6() {
    return 'STEP6';
  }

  renderStep7() {
    return 'STEP7';
  }


  /**
   * Checks if all inputs have been filled before enabling continue button
   * If OptionalSelectSteps is specified -> check using OR
   * ELSE check using AND
   */
  checkContinueValid() {
    const {
      step,
      optionalSelectSteps,
      externDiscipline,
    } = this.state;
    const stepData = this.state[`step${step}`];
    if (optionalSelectSteps.indexOf(step) !== -1) {
      if (externDiscipline && externDiscipline.length === 1) { // Extern Discipline must be chosen 2 options
        this.disableContinueButton();
      } else if (stepData.indexOf(true) !== -1) {
        this.enableContinueButton();
      } else {
        this.disableContinueButton();
      }
    } else if (stepData.indexOf(false) !== -1) {
      this.disableContinueButton();
    } else {
      this.enableContinueButton();
    }
  }

  async autoSaveTelemedPrefAsInPerson() {
    const { actions, user } = this.props;
    let onboardingItems = new Map();
    onboardingItems.set(JOB_TYPE_SUB_CATEGORY, [{ criteria: JOB_TYPE_SUB_CATEGORY, strValue: 'in-person' }]);
    const readyToSendItems = [];
    onboardingItems.forEach((items, key) => {
      if (items) {
        items.forEach((value) => {
          value.talent_id = user.talent_id;
          readyToSendItems.push(value);
        });
      }
    });
    await actions.addResource('talentprofileitems', user.token, readyToSendItems, 'talentprofileitems')
      .then(() => actions.getAllResources(user.token, 'talentprofileitems'));
  }

  async continueClicked(e) {
    const {
      step,
      maxSteps,
      skippedSteps,
      studentDefaults,
    } = this.state;
    const {
      actions,
      user,
      history,
      resources,
      talent,
    } = this.props;
    const { savedsearchs } = resources;
    const onboardingItems = this.state[`step${step}-onboardingItems`];
    if (studentDefaults
      && onboardingItems
      && onboardingItems.get('school-id')
      && !onboardingItems.get('student-defaults')
    ) {
      onboardingItems.set('student-defaults', studentDefaults.get('student-defaults'));
    }
    const temporaryUser = isTemporaryUser(user.email);
    if (onboardingItems) {
      const readyToSendItems = [];
      onboardingItems.forEach((items, key) => {
        if (items) {
          items.forEach((value) => {
            value.talent_id = user.talent_id;
            readyToSendItems.push(value);
          });
        }
      });
      await actions.addResource('talentprofileitems', user.token, readyToSendItems, 'talentprofileitems')
        .then(() => actions.getAllResources(user.token, 'talentprofileitems'));
    }
    if (step + 1 === maxSteps) {
      if (savedsearchs && savedsearchs.length) {
        // An existing employee can edit their profile to be a student as well
        // (keeps existing saved-searches, but adds the new externship job searches)
        const externshipPositionType = savedsearchs.find(s => s.positionType === 'Externship');
        if (talent && talent.isStudent && !externshipPositionType) {
          const {
            address,
            city,
            lat,
            lng,
            postalCode,
            state,
          } = savedsearchs[0];
          const externshipWBSearch = {
            address,
            city,
            lat,
            lng,
            postalCode,
            state,
            ...FIRST_EXTERN_WB_SEARCH_CONFIG,
            color: '567feb',
            talent_id: user.talent_id,
          };
          actions.addResource('savedsearchs', user.token, externshipWBSearch, 'savedsearchs');
        }
        history.push('/profile');
      } else {
        const location = JSON.parse(sessionStorage.getItem('firstWBSearchLocation'));
        sessionStorage.removeItem('firstWBSearchLocation');
        if (location) { // this means we are in the onboarding not edit profile,
          if (!temporaryUser && this.state.useDefaultSavedSearches) {
            this.state.defaultSavedSearches.forEach((jobType, indx) => {
              const payload = {
                ...location,
                color: COLORS[indx],
                title: `Initial - ${jobType}`,
                hasEmail: true,
                range: 100,
                positionType: jobType,
                notificationFrequency: 'DAILY',
                talent_id: user.talent_id,
              };

              actions.addResource('savedsearchs', user.token, payload, 'savedsearchs');
            });
          } else {
            const firstWBSearch = {
              ...location,
              ...((talent && talent.isStudent) ? FIRST_EXTERN_WB_SEARCH_CONFIG : FIRST_WB_SEARCH_CONFIG),
              talent_id: user.talent_id,
            };

            if (talent.isStudent) {
              actions.calcAssignBadges(user.token, { talentId: talent.id }, 'userbadges', 'calc-assign-badge');
            }

            if (this.props.location?.state?.from !== "isExternUser") {
              actions.addResource('savedsearchs', user.token, firstWBSearch, 'savedsearchs');
            }

          }
          if (temporaryUser && !talent.isStudent) {
            history.push('/thankyou');
          } else {
            this.setState({ modalOpen: true });
          }
          await actions.updateUserRegistration(user.token, talent);
          localStorage.setItem(`student_user_${user.id}`, `${talent.isStudent}`);
        } else {
          history.push('/profile');
        }
      }
    } else if (step + 1 < maxSteps) {
      let nextStep = step + 1;
      if (skippedSteps.indexOf(step + 1) !== -1) {
        while (skippedSteps.indexOf(nextStep) !== -1
          && nextStep + 1 < maxSteps) {
          nextStep++;
        }
      }
      this.setState({
        step: nextStep,
        continueDisabled: true,
      });
    }
    try {
      window.scrollTo(0, 0);
      document.getElementById('main')
        .scrollTo(0, 0);
    } catch (e) {
      console.log(e);
    }
  }

  backClicked(e) {
    const { history } = this.props;
    const {
      step,
      skippedSteps,
    } = this.state;
    if (step > 0) {
      let nextStep = step - 1;
      if (skippedSteps.indexOf(step - 1) !== -1) {
        while (skippedSteps.indexOf(nextStep) !== -1
          && nextStep > 0) {
          nextStep--;
        }
      }
      this.setState({ step: nextStep });
    } else {
      history.goBack();
    }
    try {
      window.scrollTo(0, 0);
      document.getElementById('main')
        .scrollTo(0, 0);
    } catch (e) {
      console.log(e);
    }
  }

  findTalentProfileItemValue = (name, talentProfileItems, criterias) => {
    if (criterias) {
      const criteria = criterias.find(c => c.name === name);
      if (criteria) {
        const talentProfileItem = talentProfileItems.find(item => item.criteria_id === criteria.id);
        return !talentProfileItem ? null : talentProfileItem.strValue;
      }
    }
    return null;
  };

  render() {
    const styles = OnboardingFlowStyle(theme);
    const { modalOpen } = this.state;
    let stepContent = '';
    switch (this.state.step) {
      case 0:
        stepContent = this.renderStep0();
        break;
      case 1:
        stepContent = this.renderStep1();
        break;
      case 2:
        stepContent = this.renderStep2();
        break;
      case 3:
        stepContent = this.renderStep3();
        break;
      case 4:
        stepContent = this.renderStep4();
        break;
      case 5:
        stepContent = this.renderStep5();
        break;
      case 6:
        stepContent = this.renderStep6();
        break;
      case 7:
        stepContent = this.renderStep7();
      default:
        break;
    }
    return (
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid
            item
            container
            xs={12}
            justifyContent="space-evenly"
          >
            {stepContent}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              alignSelf: 'flex-end',
            }}
          >
            <Grid container direction="row-reverse">
              <Grid item xs={12} lg={4}>
                <ButtonGroup fullWidth>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ backgroundColor: !this.state.continueDisabled && orange }}
                    size="large"
                    disabled={this.state.continueDisabled}
                    onClick={e => this.continueClicked(e)}
                  >Continue</Button>
                </ButtonGroup>
              </Grid>
              <Grid container item xs={12} lg={8} alignItems="center">
                <BackButton onClick={e => this.backClicked(e)} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Modal
          aria-labelledby="Profile Finish Message Modal"
          open={modalOpen}
          onClose={() => this.closeModal()}
          style={styles.modal}
        >
          <Fade in={modalOpen}>
            <div style={styles.message}>
              <img src={`${IMG_STORAGE_LINK}ic-close.svg`} style={styles.closeIcon} onClick={() => this.closeModal()} />
              <img src={`${IMG_STORAGE_LINK}icons/circle_check.svg`} />
              <Typography variant="h6" color="primary" gutterBottom style={{ fontWeight: 700 }}>
                THAT'S IT FOR THE FIRST PART OF YOUR PROFILE!
              </Typography>
              {
                this.props.location?.state?.from === 'isExternUser' ? (
                  <Typography style={styles.messageBody}>
                    We hope you found it quick and easy. On the next page you will be able to search for 
                    externships across the country by state and also by specialty rotation!
                  </Typography>
                ) : (
                  <>
                    <Typography style={styles.messageBody}>
                      We hope you found it quick and easy. In the next section, we'll get your first
                      WhiskerBot saved search, which will tell us where you might like to work and what
                      sort of job you are interested in doing.
                    </Typography>
                    <Typography style={styles.messageBody}>
                      You can create multiple Whiskerbot saved searches, so can choose to follow both
                      relief vet work in Chicago, permanent positions in Texas, and available locums in
                      California if you so choose.
                    </Typography>
                  </>
                )
              }
              <Button style={styles.modalButton} onClick={() => this.closeModal()}>
                OK
              </Button>
            </div>
          </Fade>
        </Modal>
      </Container>
    );
  }
}

export default OnboardingFlowComponent;
