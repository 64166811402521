import { navy, tiffany } from './style';

export const ThankYouStyle = theme => ({
  container: {
    padding: '30px 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& img': {
      backgroundColor: `${tiffany}`,
      width: '50px',
      height: '50px',
      padding: '10px',
      borderRadius: '50%',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
      minHeight: 'unset',
      height: '70%',
      padding: '0 30px',
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: 'unset',
      height: '70%',
      padding: 'unset',
      paddingTop: '50px',
      '& img': {
        width: '75px',
        height: '75px',
      },
    },
  },
  itemContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    padding: '10px 0px',
    display: 'flex',
    fontFamily: 'Asap',
    fontSize: '1.8rem',
    fontWeight: '700',
    color: `${navy}`,
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      fontSize: '3rem',
    },
  },
  divider: {
    width: '20%',
    height: '5px',
    backgroundColor: `${tiffany}`,
    margin: '20px 0',
  },
  paragraph: {
    padding: '10px 0 30px',
    textAlign: 'justify',
    '& a': {
      fontWeight: 'bold',
    },
  },
  paragraphLeft: {
    padding: '10px 0 30px',
    textAlign: 'left',
  },
  signature: {
    width: '100%',
    textAlign: 'right',
    marginBottom: '30px',
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'none',
    backgroundColor: `${tiffany}`,
    color: '#FFFFFF',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '20px',
    padding: '10px 20px',
    transition: 'all 0.5s ease',
    '&:hover': {
      boxShadow: '0 5px 10px rgba(0,0,0,0.25)',
    },
  },
});
