import React, { Component } from 'react';
import { DesciplineComponentStyle } from '../../../css/desciplineComponentStyle';
import { EXTERN_CAREER } from '../../../config/enums';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router';

class ExternCareerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(key) {
    this.setState({ selected: key });
    this.props.onClick(key);
  }

  componentDidMount() {
    const { initState } = this.props;
    let selected;

    if (!isEmpty(initState)) {
      const value = initState.find(x => x.criteria_id);
      selected = value.strValue;
    }

    this.setState({ selected });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected !== this.state.selected) {
      this.handleChange(this.state.selected);
    }
  }

  render() {
    const { classes } = this.props;
    const { selected } = this.state;
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography className={classes.title}>What do you currently see yourself leaning towards after graduation?</Typography>
        </Grid>
        <Grid
          container
          spacing={1}
          className={classes.gridContainer}
        >
          {
            EXTERN_CAREER.map((item) => {
              const isSelected = selected === item;
              return (
                <Grid item key={item}>
                  <Paper onClick={e => this.handleChange(item)} className={isSelected ? classes.selectedSquare : classes.square}>
                    <Typography gutterBottom align="center" color="inherit" className={classes.squareText}>
                      {item}
                    </Typography>
                  </Paper>
                </Grid>
              );
            })
          }
        </Grid>
      </Grid>
    );
  }
}

ExternCareerComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default withStyles(DesciplineComponentStyle)(withRouter(ExternCareerComponent));
