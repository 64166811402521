import React from 'react';
import { withRouter } from 'react-router';
import {
  Button, DialogActions,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { orangeLight, orange } from '../../css/style';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 20px 20px 10px',
  },
  button: {
    width: '100%',
    height: '40px',
    fontFamily: 'Asap',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff',
    borderRadius: '5px',
    backgroundColor: '#243060',
    marginRight: '10px',
  },
  tag: {
    margin: '0 5px 0 10px',
    width: '15px',
    height: '15px',
    borderRadius: '50%',
  },
  blockedTag: {
    margin: '0 5px 0 10px',
    width: '15px',
    height: '15px',
    borderRadius: '2px',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
  },
  tagContainer: {
    fontFamily: 'Asap',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 3,
  },
  submitButtonContainer: {
    width: '30%',
    maxWidth: '100px',
  },
};

const CalendarActionButton = ({
  submitButtonText,
  onOk,
  onChat,
  readOnly,
  pendingDates,
  disabledConnect = false,
  isFastpass = false,
  requestedDates,
  chatButtonText,
  confirmedDates,
}) => (
    <DialogActions style={styles.root}>
        <div style={styles.tagContainer}>
          <div style={styles.item}>
            <div style={{ ...styles.tag, backgroundColor: '#5aac44' }} />
            <span>AVAILABLE</span>
          </div>
          <div style={styles.item}>
            <div style={{ ...styles.tag, backgroundColor: orange }} />
            <span>TAKEN</span>
          </div>
          <div style={styles.item}>
            <div style={{ ...styles.tag, backgroundColor: isFastpass ? orangeLight : '#243060' }} />
            <span>{isFastpass ? 'TAKE SHIFT' : 'REQUESTED'}</span>
          </div>
          {!isEmpty(pendingDates) && (
            <div style={styles.item}>
              <div style={{ ...styles.tag, backgroundColor: '#bdbdbd' }} />
              <span>PENDING</span>
            </div>
          )}
          {!isEmpty(confirmedDates) && (
            <div style={styles.item}>
              <div style={{ ...styles.blockedTag, backgroundColor: '#243060' }} />
              <span>CONFIRMED</span>
            </div>
          )}
        </div>
        <div style={styles.submitButtonContainer}>
          {
            !readOnly
            && (
              <Button
                onClick={onOk}
                style={disabledConnect || (isFastpass && isEmpty(requestedDates))
                  ? { ...styles.button, backgroundColor: '#dcdcdc', color: '#b2b2b0' }
                  : isFastpass ? { ...styles.button, backgroundColor: orange } : styles.button}
                disabled={disabledConnect || (isFastpass && isEmpty(requestedDates))}
              >
                {submitButtonText}
              </Button>
            )}
        </div>
        {
          !readOnly && Boolean(chatButtonText)
          && (
          <div style={styles.submitButtonContainer}>
            <Button
              onClick={onChat}
              style={disabledConnect
                ? { ...styles.button, backgroundColor: '#dcdcdc', color: '#b2b2b0' }
                : styles.button}
              disabled={disabledConnect}
            >
              {chatButtonText}
            </Button>
          </div>
        )}
    </DialogActions>
);

export default withRouter(CalendarActionButton);
