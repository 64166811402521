import Immutable from 'seamless-immutable';
import types from '../actions/ActionTypes';

const initialState = Immutable({});

const toCamelCase = (string) => {
  const regex = /(_)\w/g;
  return string.replace(regex, char => char[1].toUpperCase());
};

const update = (state, action, resource, resourceId) => {
  switch (action.type) {
    case types.DELETE_RESOURCE_SUCCESS:
      return state.update(
        resource,
        resources => resources.filter(resourceItem => resourceItem.id !== resourceId),
      );
    case types.ADD_RESOURCE_SUCCESS:
      return state.update(resource, resources => (resources ? resources.concat(action.response) : [action.response]));
    case types.UPDATE_RESOURCE_SUCCESS:
    case types.UPDATE_RESOURCE_NO_LOADING_SUCCESS:
    case types.GET_ONE_RESOURCE_SUCCESS:
      return state.update(resource, (resources) => {
        if (resources !== undefined) {
          return resources.map((resourceItem) => {
            if (resourceItem.id && resourceItem.id.toString() === resourceId?.toString()) {
              let isStatusUpdated = undefined;
              if (action.type === types.UPDATE_RESOURCE_NO_LOADING_SUCCESS) {
                isStatusUpdated = true;
              }
              return Object.assign({}, resourceItem, action.response, { isStatusUpdated });
            }
            return resourceItem;
          });
        }
        return [action.response];
      });
    case types.GET_ALL_RESOURCES_SUCCESS:
      return Immutable.merge(state, { [resource]: action.response });
    default:
      return state;
  }
};

const createNewState = (state, path, action) => {
  const resource = toCamelCase(path[0]);
  const resourceId = path[1];
  const isValidSubState = path[2] && path[2] !== 'rescan' && path[2] !== 'disconnect';

  if (path.length > 2 && isValidSubState) {
    let subState;
    if (Array.isArray(state[resource])) {
      subState = state[resource].filter(resource => resource.id == resourceId)[0];
    } else {
      subState = state[resource];
    }

    const subPath = path.slice(2);
    const updatedSubState = createNewState(Immutable(subState), subPath, action);

    return Immutable(state).update(resource, (resourceList) => {
      if (Array.isArray(resourceList)) {
        return resourceList.map((resourceItem) => {
          if (resourceItem.id === resourceId) {
            return Immutable(resourceItem).merge(updatedSubState);
          }
          return resourceItem;
        });
      }
      return Immutable(resourceList).merge(updatedSubState);
    });
  }

  return update(Immutable(state), action, resource, resourceId);
};

// action.resourcePath --> home, resourceId, subResource, subResourceId,...
export default function ResourcesReducer(state = initialState, action) {
  if (action.type === types.LOGOUT_USER) {
    return Immutable.merge({}, { ...initialState });
  } else if (action.type === types.CLEAR_401_MESSAGE) {
    return Immutable.merge(state, { ['401']: undefined });
  } else if (!action.resourcePath) {
    return state;
  }
  return createNewState(state, action.resourcePath, action);
}
