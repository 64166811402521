import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Icon,
  IconButton,
  Tooltip,
  Typography,
  ButtonBase,
} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DomainIcon from '@mui/icons-material/Domain';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PlaceIcon from '@mui/icons-material/Place';
import EventIcon from '@mui/icons-material/Event';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import { PropTypes } from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { orange, green, lightBlue, aquaGreen, lightGray } from '../../css/style';
import { JobMatchCardStyle } from '../../css/jobMatchCardStyle';
import { badgeColor, IMG_STORAGE_LINK } from '../constants/Constants';
import { isPrivateStaff, checkIsBoostedJob, formatPostTitle, getJobRoleText, isReliefOrExternshipType } from '../../utils/Functions';

const fastpassIcon = `${IMG_STORAGE_LINK}green-ticket.png`;

class OpenExternJobCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  viewMatchDetails = (item) => {
    const { search } = this.props;
    if(item.jobPosting) {  // a match
      localStorage.setItem('viewDetailsMatchIdStr', item.id);
      this.props.history.push(`/${search ? 'jobpostings' : 'matches'}/${item.id}`);
    } else {
      localStorage.setItem('viewDetailsPostIdStr', item.id);
      this.props.history.push(`/jobpostings/${item.id}`);
    }
  };

  getBadge(title, id) {
    const { classes } = this.props;
    return (
      <Button
        className={classes.badgeFilter}
        key={title}
        style={{
          backgroundColor: badgeColor(id || title),
        }}
        ref={title}
      >
        {title}
      </Button>
    );
  }

  getBadges() {
    const { item } = this.props;
    return item && item.badges && item.badges.length && (
      <div
        style={{
          height: 30,
          marginTop: 8,
          overflow: 'hidden',
          paddingRight: '50px',
          position: 'relative',
        }}
      >
        {item.badges.map(badge => this.getBadge(badge.title, badge.id))}
        {this.moreBadges()}
      </div>
    ) || '';
  }

  moreBadges() {
    const { item } = this.props;
    let lineTops = [];
    const publicRef = this.refs.public;
    const privateRef = this.refs['private-staff'];
    const customRef = this.refs.superhero;
    let firstLineTop = customRef ? customRef.offsetTop : null;
    if (!firstLineTop) {
      firstLineTop = publicRef ? publicRef.offsetTop : null;
    }
    if (!firstLineTop) {
      firstLineTop = privateRef ? privateRef.offsetTop : null;
    }
    if (firstLineTop && publicRef && firstLineTop !== publicRef.offsetTop) {
      lineTops.push(privateRef.offsetTop);
    }
    if (firstLineTop && privateRef && firstLineTop !== privateRef.offsetTop) {
      lineTops.push(privateRef.offsetTop);
    }
    item.badges.map((badge) => {
      let ref = this.refs[badge.title];
      if (ref) {
        if (!firstLineTop) {
          firstLineTop = ref.offsetTop;
        }
        if (ref.offsetTop != firstLineTop) {
          lineTops.push(ref.offsetTop);
        }
      }
    });
    if (lineTops && lineTops.length > 0) {
      return (
        <span
          style={{
            position: 'absolute',
            top: 10,
            right: 0,
            fontSize: '14px',
            fontFamily: 'Asap',
          }}
        >
          +
          {lineTops.length}
          {' '}
          more
        </span>
      );
    } else {
      return null;
    }
  }

  render() {
    const {
      classes, item, confirmConnectToMatch, user, history, search,
      deleteMatch = () => {}, open, fastpassData,
      isMobile,
    } = this.props;

    if (isEmpty(item)) return null;

    const { practice } = item;
    const showConnectButton = !!user;
    const showSignInButton = !user;

    const enableConnectButton = user && user.isVerified;
    const isBoostedJob = item?.isBoostedByDates;
    const rotationTypes = item && item.rotationTypes && JSON.parse(item.rotationTypes);
    const isFastPass = item?.typeOfEmployment === 'Relief'
      && fastpassData?.find(fp => fp.practice_id === item.practice_id);
    
    return isMobile ? (
      <Card
        key={`${item.id}_m_extern_post`}
        id={`${item.id}_m_extern_post`}
        className={classes.card}
        style={{
          border: isBoostedJob ? `1px solid ${orange}` : isFastPass ? `1px solid ${green}` : '1px solid transparent',
        }}
      >
        <CardHeader
          title={(
            <Grid container style={{ display: 'flex', alignItems: 'center', width: '100%'}} justifyContent='space-between'>
              {isBoostedJob &&
                <Grid item xs={5} className={classes.cardHeaderContainer}>
                  <img
                    src={`${IMG_STORAGE_LINK}dollar.svg`}
                    style={{ width: 20, height: 20, marginRight: '5px' }}
                    alt='boosted' title='boosted'
                  />
                  <Typography key={`${item.id}_m_extern_boosted`} className={classes.fastpassText}>BOOSTED</Typography>
                </Grid>
              }
              {isFastPass &&
              <Grid item xs={5} className={classes.cardHeaderContainer}>
                <img style={{ width: 20, height: 20, marginRight: '5px' }} src={fastpassIcon} alt='fastpass' title='fastpass'/>
                <Typography key={`${item.id}_m_extern_fastpass`} className={classes.fastpassText}>FAST PASS</Typography>
              </Grid>
              }
            </Grid>
          )}
          style={{ padding: '16px 16px 8px 16px' }}
        />
        <CardContent  style={{ padding: '0px 16px' }}>
          <Grid
            className={classes.infoGrid}
            direction="row"
            container
            key={`${item.id}_m_extern_container`}
          >
            <Grid item xs={12} key={`${item.id}_m_extern_title_container`} className={classes.matchIteminfoItem}>
              <NewReleasesIcon style={{ fontSize: '20px', marginRight: '4px', color: orange }} />
              <Typography key={`${item.id}_m_extern_title`} color="secondary" className={classes.infoTypography}>
                {formatPostTitle(item?.title)}
              </Typography>
            </Grid>
            {item.available && item.available.length > 0 && (
              <Grid item xs={12} key={`${item.id}_m_extern_dates_container`} className={classes.matchIteminfoItem}>
                <Typography
                  key={`${item.id}_m_extern_date_list`}
                  className={classes.matchItemInfoText}
                  style={{ fontWeight: 600 }}
                >
                  {item.available.map(a => moment(a).format('MMM.DD')).join(', ')}
                </Typography>
              </Grid>
            )}
            {item.typeOfEmployment === 'Externship' && rotationTypes && rotationTypes.length > 0 && (
              <Grid item xs={12} className={classes.matchIteminfoItem}>
                <Tooltip title={`Externship Rotation: ${rotationTypes?.join(', ')}`}>
                  <Typography component='span'>
                    <span>{rotationTypes?.join(', ')}</span>
                  </Typography>
                </Tooltip>
              </Grid>
            )}
            <Grid item xs={12} key={`${item.id}_m_extern_practice_container`} className={classes.matchIteminfoItem}>
              <Typography
                key={`${item.id}_m_extern_practice_name`}
                className={classes.matchItemInfoText}
              >
                {item?.practice?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} key={`${item.id}_m_extern_location_container`}
              className={classes.matchIteminfoItem}
            >
              <Typography
                key={`${item.id}_m_extern_location`}
                className={classes.matchItemInfoText}
              >
                {item?.practice?.location}
              </Typography>
            </Grid>
            {this.getBadges()}
          </Grid>
          
        </CardContent>
        
        <CardActions style={{ padding: '16px' }}>
        <Grid container>
          <Grid item xs={6} className={classes.cardHeaderContainer}>
          </Grid>
          <Grid item xs={6} className={classes.cardActions}>
            {showSignInButton
                && (
                  <ButtonBase
                    key={`${item.id}_extern_login_btn`}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={e => history.push('/login')}
                  >
                    <Typography className={classes.link}>Sign in to connect!</Typography>
                  </ButtonBase>
                )}
                {showConnectButton
                  && (
                  <ButtonBase
                    key={`${item.id}_extern_connect_btn`}
                    size="small"
                    disabled={!enableConnectButton}
                    variant="contained"
                    onClick={(e) => {
                      localStorage.setItem('viewDetailsMatchIdStr', item.id);
                      return confirmConnectToMatch(item);
                    }}
                  >
                    <Typography className={classes.link} style={{ color: orange }}>Connect</Typography>
                  </ButtonBase>
                )}
                {showConnectButton
                  && (<>
                    <RemoveIcon style={{ rotate: 'z 90deg', color: lightGray, fontSize: '18px' }} />
                  <ButtonBase
                    key={`${item.id}_extern_details_btn`}
                    size="small"
                    variant="contained"
                    onClick={e => this.viewMatchDetails(item)}
                  >
                    <Typography className={classes.link}>Details</Typography>
                  </ButtonBase>
                  </>
                )}
            </Grid>
          </Grid>
          
        </CardActions>
      </Card>
    )
    : ( // desktop
      <div className={classes.cardContainer}>
        <Card
          key={`${item.id}_extern_card`}
          id={`${item.id}_extern_card`}
          className={classes.card}
          style={{
            border: isBoostedJob ? `1px solid ${orange}` : isFastPass ? `1px solid ${green}` : '1px solid transparent',
          }}
        >
          <CardContent style={{ padding: '8px' }}>
            <Grid container>
              {isBoostedJob &&
                <Grid item xs={5} className={classes.cardHeaderContainer}
                >
                  <img
                    src={`${IMG_STORAGE_LINK}dollar.svg`}
                    style={{ marginRight: '5px' }}
                  />
                  <Typography key={`${item.id}_extern_boosted`} className={classes.headerText}>BOOSTED</Typography>
                </Grid>
              }
              <Grid item
                xs={isBoostedJob ? 5 : 10} className={classes.cardHeaderContainer}
                style={{ visibility: isFastPass ? 'visible' : 'hidden' }}
              >
                <img style={{ width: 20, height: 20, marginRight: '5px' }}
                  src={ `${IMG_STORAGE_LINK}green-ticket.png`}
                  alt='fastpass' title='fastpass'                  
                />
                <Typography
                  key={`${item.id}_extern_fastpass`}
                  className={classes.headerText}
                >FAST PASS</Typography>
              </Grid>

              <Grid item xs={10}  className={classes.cardHeaderContainer} style={{ height: '58px' }}>
                <Typography className={classes.jobTitle}>
                  {formatPostTitle(item.title)}
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.iconGridItem}>
                <div className={classes.newIconContainer}>
                  <NewReleasesIcon className={classes.newIcon} />
                  <Typography variant="caption" component="p" className={classes.newIconText}>NEW</Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container className={classes.matchItemInfo}>
              {item.available && item.available.length > 0 && (
                <Grid item xs={12} className={classes.matchIteminfoItem}>
                  <EventIcon className={classes.matchIteminfoIcon} />
                  <Typography component='span' className={classes.matchItemText} style={{ fontWeight: 600 }}>{item.available.map(a => moment(a).format('MMM.DD')).join(', ')}</Typography>
                </Grid>
              )}
              {item.typeOfEmployment === 'Externship' && rotationTypes && rotationTypes.length > 0 && (
                <Grid item xs={12} className={classes.matchIteminfoItem}>
                  <AddBoxIcon className={classes.matchIteminfoIcon} />
                  <Tooltip title={`Externship Rotation: ${rotationTypes?.join(', ')}`}>
                    <Typography component='span'>
                      <span>{rotationTypes?.join(', ')}</span>
                    </Typography>
                  </Tooltip>
                </Grid>
              )}
              <Grid item xs={12} className={classes.matchIteminfoItem}>
                <DomainIcon className={classes.matchIteminfoIcon} />
                <Typography className={classes.matchItemText}>
                  {practice?.name || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.matchIteminfoItem}>
                <PlaceIcon className={classes.matchIteminfoIcon} />
                <Typography className={classes.matchItemText}>{practice ? practice.location : '-'}</Typography>
              </Grid>
            </Grid>
            {this.getBadges()}
          </CardContent>
          <CardActions style={{ padding: '8px' }}>
            <Grid container>
              <Grid item xs={6} className={classes.cardHeaderContainer}>
              </Grid>
              <Grid item xs={6} className={classes.cardActions}>
                {showSignInButton
                && (
                  <Button
                    key={`${item.id}_extern_login_btn`}
                    size="small"
                    className={classes.lightNavyButton}
                    variant="contained"
                    color="primary"
                    onClick={e => history.push('/login')}
                  >
                    Sign in to connect!
                  </Button>
                )}
                {showConnectButton
                  && (
                  <Button
                    key={`${item.id}_extern_connect_btn`}
                    size="small"
                    disabled={!enableConnectButton}
                    className={classes.redButton}
                    style={{ marginRight: '8px' }}
                    variant="contained"
                    onClick={(e) => {
                      localStorage.setItem('viewDetailsMatchIdStr', item.id);
                      return confirmConnectToMatch(item);
                    }}
                  >
                    Connect
                  </Button>
                )}
                {showConnectButton
                  && (
                  <Button
                    key={`${item.id}_extern_details_btn`}
                    size="small"
                    className={classes.lightNavyButton}
                    variant="contained"
                    onClick={e => this.viewMatchDetails(item)}
                  >
                    Details
                  </Button>
                )}
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </div>
    );
  }
}

OpenExternJobCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.any),
  search: PropTypes.bool,
  confirmConnectToMatch: PropTypes.func,
  refreshStats: PropTypes.func,
  enableDelete: PropTypes.bool,
};

OpenExternJobCard.defaultProps = {
  user: null,
  actions: null,
  enableDelete: false,
  confirmConnectToMatch: () => {
  },
};

export default withStyles(JobMatchCardStyle)(withRouter(OpenExternJobCard));
