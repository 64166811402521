import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { VETENARIAN_COLLEGES } from '../../../config/enums';
import {
  SimpleSelectableRectangleStyle,
  SpecialityCollegeComponentStyle,
} from '../../../css/style';
import OnboardingItemComponent from './OnboardingItemComponent';
import SimpleSelectableIconComponent from './SimpleSelectableIconComponent';


class SpecialityCollegeComponent extends OnboardingItemComponent {
  constructor(props) {
    super(props);
    const { initState } = props;
    const st = {};
    initState.forEach(college => st[college.strValue] = true);
    this.state = st;
    this.handleState();
  }

  handleState() {
    const { handleStateChange } = this.props;
    const items = VETENARIAN_COLLEGES.filter(college => this.state[college])
      .map(college => ({
        criteria: 'speciality',
        strValue: college,
      }));
    handleStateChange && handleStateChange(items);
  }

  typeClicked(type) {
    const st = {};
    st[type] = !this.state[type];
    this.setState(st, () => this.handleState());
  }

  render() {
    return (
      <Grid container spacing={1} justifyContent="center">
        {
          VETENARIAN_COLLEGES.map(college => (
            <Grid item key={college}>
              <SimpleSelectableIconComponent
                icon={college}
                title={college.replace('-', ' ')
                  .toUpperCase()}
                selected={this.state[college]}
                style={SimpleSelectableRectangleStyle}
                onClick={e => this.typeClicked(college)}
              />
            </Grid>))
        }
      </Grid>
    );
  }
}

SpecialityCollegeComponent.propTypes = {
  initState: PropTypes.array.isRequired,
  handleStateChange: PropTypes.func.isRequired,
};

SpecialityCollegeComponent.defaultProps = {
  initState: [],
};

export default withStyles(SpecialityCollegeComponentStyle)(SpecialityCollegeComponent);
