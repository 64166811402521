import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography, Slide, Button, Dialog, DialogTitle, DialogActions, } from '@mui/material';
import { withStyles } from '@mui/styles';
import ReplayIcon from '@mui/icons-material/Replay';
import { tiffany, orange } from '../css/style';
import { IMG_STORAGE_LINK } from './constants/Constants';

const styles = theme => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: '1px',
    top: '1px',
    color: 'grey',
  },
  icon: {
    height: '50px',
    width: '50px',
    textAlign: 'center',
    margin: '20px 0',
    border: `solid 3px ${tiffany}`,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '10px 80px',
    margin: 0,
  },
  button: {
    backgroundColor: `${orange}`,
    color: 'white',
    textTransform: 'capitalize',
    padding: '4px 14px',
    margin: 0,

    '&:hover': {
      backgroundColor: `${tiffany}`,
      color: 'white',
    },
  },

  resourceLogo: {
    verticalAlign: 'middle',
    marginRight: '10px',
    color: tiffany,
    width: '24px',
    height: '24px',
  },
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: !!error };
  }

  componentDidCatch(error, errorInfo) {
    const { user } = this.props;
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
    if (window?.location?.pathname !== '/externhome' && window?.location?.pathname !== '/matches') {
      setTimeout(() => {
        this.refresh();
      }, 15000);
    }
  }

  refresh() {
    const { history, user } = this.props;

    if (window?.location?.pathname !== '/externhome' && window?.location?.pathname !== '/matches') {
      history.push('/');
    }
    this.setState({ hasError: false });
  }

  renderError() {
    const { theme } = this.props;
    const { hasError } = this.state;
    const stylesWithTheme = styles(theme);

    return (
      <React.Fragment>
        <Dialog
          open={hasError}
          TransitionComponent={Transition}
          keepMounted
          onClose={e => this.refresh()}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            style: {
              minWidth: '310px',
            },
          }}
        >
          <DialogTitle style={stylesWithTheme.dialogTitle}>
            <div style={stylesWithTheme.titleContainer}>
              <img
                style={stylesWithTheme.resourceLogo}
                src='https://storage.googleapis.com/jobs-unleashed-common/icon.ico'
              />
              <Typography color="primary" style={stylesWithTheme.title}>
                Something went wrong
              </Typography>
            </div>
          </DialogTitle>
          <DialogActions style={{ padding: '20px', justifyContent: 'center' }}>
            <Button style={stylesWithTheme.button} onClick={e => this.refresh()}>
              <ReplayIcon />
              {'  '}
              Restart
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.renderError();
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default withStyles(styles)(withRouter(ErrorBoundary));
