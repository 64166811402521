/* eslint-disable import/prefer-default-export */
/* eslint-disable object-curly-newline */

import {
  CERTIFICATIONS,
  DVM_CATEGORIES,
  JOB_CATEGORIES,
  JOB_TYPE_SUBCATEGORIES,
  VET_CATEGORIES,
} from '../../config/enums';

export const pageInfo = [
  {
    key: 'home',
    title: 'Home',
    value: '/',
    hideWhenLogin: true,
    hideInDrawer: true,
  },
  {
    key: 'about',
    title: 'About',
    redirect: 'https://www.jobsunleashed.com/about/',
  },
  {
    key: 'blog',
    title: 'Resources',
    redirect: 'https://www.jobsunleashed.com/resources',
  },
  {
    key: 'contact',
    title: 'Contact',
    redirect: 'https://www.jobsunleashed.com/contact-us',
  },
  {
    key: 'profile',
    title: 'My Profile',
    redirect: '/profile',
    hideWhenLogin: true,
    hideInDrawer: true,
  },
];
export const userMenu = [
  {
    key: 'matches',
    title: 'My Matches',
    value: '/matches',
  },
  {
    key: 'profile',
    title: 'My Profile',
    value: '/profile',
  },
  {
    key: 'settings',
    title: 'Settings',
    value: '/settings',
  },
];

const colors = ['#4CAF50', '#FF8A65', '#42A5F5', '#8BC34A', '#006064', '#311B92', '#006064', '#3949AB', '#FFA726', '#F44336', '#880E4F', '#29B6F6', '#81C784', '#1E88E5', '#0D47A1', '#FF6F00', '#00796B', '#F9A825', '#0097A7', '#26A69A', '#FFC107', '#FB8C00', '#AED581', '#B71C1C', '#388E3C', '#00BCD4', '#0277BD', '#E91E63', '#AB47BC', '#D4E157', '#7B1FA2', '#F57F17', '#558B2F', '#7E57C2', '#1A237E', '#FF8F00', '#FBC02D'];
export const badgeColor = (str) => {
  if (!str) {
    str = '';
  }
  if (str.toString()
    .toLocaleLowerCase() === 'public') {
    return '#4bbdad';
  }
  if (str.toString()
    .toLocaleLowerCase() === 'private-staff' || str.toString()
      .toLocaleLowerCase() === 'private') {
    return '#E0592A';
  }
  if (str.toString()
    .toLocaleLowerCase() === 'superhero') {
    return '#243060';
  }
  const st = str || '0';
  var i = parseInt(st);
  return colors[i % colors.length];
};

// attributes that differentiate the certified-vet-tech and vet-assistant
export const EXPERIENCE_LEVEL_VET_TECH_SPECIAL = {
  'vet-certification': true,
  'cert-specialty': true,
};

export const EXPERIENCE_LEVELS_CONFIG = {
  'vet-tech-or-assistant': {
    'vet-sub-category': true,
    'job-type-sub-category': true,
    ...EXPERIENCE_LEVEL_VET_TECH_SPECIAL,
    'patient-type': true,
    'years-in-position': true,
    'practice-size': true,
    'patients-per-day': true,
    'administering-anesthesia': true,
    'monitoring-anesthesia': true,
    'central-line': true,
    catheterization: true,
    'filling-presciptions': true,
    Junior: {
      'years-in-position': 0,
      'administering-anesthesia': 2,
      'monitoring-anesthesia': 2,
      'central-line': 2,
      catheterization: 2,
      'filling-presciptions': 2,
    },
    Intermediate: {
      'years-in-position': 3,
      'administering-anesthesia': 3,
      'monitoring-anesthesia': 3,
      'central-line': 3,
      catheterization: 3,
      'filling-presciptions': 3,
    },
    Senior: {
      'years-in-position': 7,
      'administering-anesthesia': 4,
      'monitoring-anesthesia': 4,
      'central-line': 4,
      catheterization: 4,
      'filling-presciptions': 4,
    },
  },
  'specialist-veterinarian': {
    'patient-type': true,
    speciality: true,
    'job-type-sub-category': true,
    'years-in-position': true,
    'practice-size': true,
    'patients-per-day': true,
    'certification-status': true,
  },
  'dvm-doctor-of-veterinary-medicine': {
    'sub-category': true,
    'job-type-sub-category': true,
    'patient-type': true,
    'years-in-position': true,
    'practice-size': true,
    'patients-per-day': true,
    Junior: {
      'years-in-position': 0,
    },
    Intermediate: {
      'years-in-position': 3,
    },
    Senior: {
      'years-in-position': 7,
    },
  },
  'non-clinical': {
    'job-category': true,
    'years-in-animal-care': true,
    'practice-size': true,
    Junior: {
      'years-in-animal-care': 0,
    },
    Intermediate: {
      'years-in-animal-care': 3,
    },
    Senior: {
      'years-in-animal-care': 7,
    },
  },
};
export const SPECIALITIES = [
  'shelter medicine',
  'reptile medicine',
  'exotic companion animal',
  'canine and feline',
  'equine',
  'food animal',
  'dairy',
  'swine health',
  'avian',
  'beef cattle',
  'feline',
  'toxicology',
  'animal welfare',
  'laboratory animal medicine',
  'poultry',
  'theriogeniology',
  'anesthesia',
  'behavior',
  'pharmacology',
  'dermatology',
  'emergency and critical care',
  'cardiology',
  'small animal internal medicine',
  'large animal internal medicine',
  'neurology',
  'oncology',
  'virology',
  'immunology',
  'bacteriology/mycology',
  'parisitology',
  'nutrition',
  'ophthalmology',
  'anatomic pathology',
  'clinical pathology',
  'preventative medicine',
  'radiology',
  'radiation oncology',
  'equine diagnostic imaging',
  'small animal surgery',
  'large animal surgery',
  'canine',
  'zoological medicine',
  'dentistry',
  'equine dental',
];
export const SPECIALTIES_BY_COLLEGE = {
  ABVP: [
    'shelter medicine',
    'reptile medicine',
    'exotic companion animal',
    'canine and feline',
    'equine',
    'food animal',
    'dairy',
    'swine health',
    'avian',
    'beef cattle',
    'feline',
  ],
  ABVT: [
    'toxicology',
  ],
  ACAW: [
    'animal welfare',
  ],
  ACLAM: [
    'laboratory animal medicine',
  ],
  ACPV: [
    'poultry',
  ],
  ACT: [
    'theriogeniology',
  ],
  ACVAA: [
    'anesthesia',
  ],
  ACVB: [
    'behavior',
  ],
  ACVCP: [
    'pharmacology',
  ],
  ACVD: [
    'dermatology',
  ],
  ACVECC: [
    'emergency and critical care',
  ],
  ACVIM: [
    'cardiology',
    'small animal internal medicine',
    'large animal internal medicine',
    'neurology',
    'oncology',
  ],
  ACVM: [
    'virology',
    'immunology',
    'bacteriology/mycology',
    'parisitology',
  ],
  ACVN: [
    'nutrition',
  ],
  ACVO: [
    'ophthalmology',
  ],
  ACVP: [
    'anatomic pathology',
    'clinical pathology',
  ],
  ACVPM: [
    'preventative medicine',
  ],
  ACVR: [
    'radiology',
    'radiation oncology',
    'equine diagnostic imaging',
  ],
  ACVS: [
    'small animal surgery',
    'large animal surgery',
  ],
  ACVSMR: [
    'canine',
    'equine',
  ],
  ACZM: [
    'zoological medicine',
  ],
  AVDC: [
    'dentistry',
    'equine dental',
  ],
};
export const SPECIALITIES_MAP = SPECIALITIES.map(item => ({
  key: item,
  title: `${item.charAt(0)
    .toUpperCase()}${item.slice(1)}`,
  label: Object.keys(SPECIALTIES_BY_COLLEGE)
    .filter(key => SPECIALTIES_BY_COLLEGE[key].includes(item))[0],
}));
export const VET_CERT_SPECIALTIES = [
  {
    key: 'avdt',
    title: 'Dentistry',
  }, {
    key: 'avtaa',
    title: 'Anesthesia',
  }, {
    key: 'aimvt',
    title: 'Internal Medicine',
  }, {
    key: 'avecct',
    title: 'Emergency / Critical Care',
  }, {
    key: 'abvt',
    title: 'Behaviour',
  }, {
    key: 'avzmt',
    title: 'Zoological Medicine',
  }, {
    key: 'aevnt',
    title: 'Equine',
  }, {
    key: 'avtcp',
    title: 'Clinical Practice',
  }, {
    key: 'avst',
    title: 'Surgery',
  }, {
    key: 'avnt',
    title: 'Nutrition',
  }, {
    key: 'avot',
    title: 'Opthalmic',
  }, {
    key: 'avcpt',
    title: 'Clinical Pathology',
  }, {
    key: 'aala',
    title: 'Lab Animal',
  }, {
    key: 'aav',
    title: 'Avian',
  }, {
    key: 'acvr',
    title: 'Radiology',
  }, {
    key: 'aarv',
    title: 'Marine Rehabilitation',
  }];
export const ANIMAL_PATIENT_TYPES = [{
  key: 'companion',
  title: 'Companion',
}, {
  key: 'exotic-companion',
  title: 'Exotic Companion',
}, {
  key: 'equine',
  title: 'Equine',
}, {
  key: 'large-food',
  title: 'Large Food',
}, {
  key: 'small-food',
  title: 'Small Food',
}, {
  key: 'lab',
  title: 'Lab',
}, {
  key: 'wildlife',
  title: 'Wildlife',
}];
export const CERTIFICATION_STATUS_TYPES = [
  {
    key: 'board-certified',
    title: 'Board Certified',
  },
  {
    key: 'practice-limited',
    title: 'Practice Limited',
  },
];
export const EXPERIENCE_TYPE_CONFIG = {
  speciality: {
    title: 'Specialty',
    unselected: 'Any Specialty',
    items: SPECIALITIES_MAP,
  },
  'sub-category': {
    title: 'Sub-category',
    unselected: 'Please Select',
    items: DVM_CATEGORIES,
  },
  'vet-sub-category': {
    title: 'Sub-category',
    unselected: 'Please Select',
    items: VET_CATEGORIES,
  },
  'job-category': {
    title: 'Job Category',
    unselected: 'All Job Categories',
    items: JOB_CATEGORIES,
  },
  'vet-certification': {
    title: 'Certification',
    unselected: 'Any Certification',
    items: CERTIFICATIONS,
  },
  'cert-specialty': {
    title: 'Specialty',
    unselected: 'Any Specialty',
    items: VET_CERT_SPECIALTIES,
  },
  'patient-type': {
    title: 'Animal Types',
    inputTitle: 'Candidate require familiarity with these animal types',
    unselected: 'All Animal Types',
    items: ANIMAL_PATIENT_TYPES,
  },
  'school-name': {
    title: 'School Name',
    isStudent: true,
  },
  'school-id': {
    title: 'School ID',
    isStudent: true,
  },
  'graduation-date': {
    title: 'Graduation Date',
    isStudent: true,
  },
  'extern-discipline-1': {
    title: 'Extern Discipline 1',
    isStudent: true,
  },
  'extern-discipline-2': {
    title: 'Extern Discipline 2',
    isStudent: true,
  },
  'extern-career': {
    title: 'Extern Career',
    isStudent: true,
  },
  'years-in-animal-care': {
    title: 'Years in Animal Care',
    default: 0,
    max: 20,
  },
  'years-in-position': {
    title: 'Years in current position',
    default: 0,
    max: 20,
  },
  'practice-size': {
    title: 'Practice Size',
    inputTitle: 'Candidates minimum practice size',
    min: 0,
    max: 20,
    tier3: true,
  },
  'certification-status': {
    title: 'Certification Status',
    unselected: 'Any Certification',
    items: CERTIFICATION_STATUS_TYPES,
  },
  'patients-per-day': {
    title: 'Patients Per Day',
    inputTitle: 'Candidates minimum # of patients/day',
    min: 0,
    max: 20,
    tier3: true,
  },
  'administering-anesthesia': {
    title: 'Administering Anesthesia',
    min: 1,
    max: 5,
    tier3: true,
  },
  'monitoring-anesthesia': {
    title: 'Monitoring Anesthesia',
    min: 1,
    max: 5,
    tier3: true,
  },
  'central-line': {
    title: 'Running a central line',
    min: 1,
    max: 5,
    tier3: true,
  },
  catheterization: {
    title: 'Catheterization',
    min: 1,
    max: 5,
    tier3: true,
  },
  'filling-presciptions': {
    title: 'Filling Prescriptions',
    min: 1,
    max: 5,
    tier3: true,
  },
};

export const JOB_TYPE_SUB_CATEGORY = 'job-type-sub-category';
export const SUB_CATEGORY = 'sub-category';
export const VET_SUB_CATEGORY = 'vet-sub-category';

export const LOGIN_ERROR_MESSAGES = {
  INVALID_EMAIL: 'Invalid Email',
  INVALID_PASSWORD: 'Invalid Password',
  EMAIL_REQUIRED: 'Please enter an email to login',
  ACCESS_DENIED: 'Access Denied',
  PASSWORD_REQUIRED: 'Please enter a password to login',
  TALENTS_ACCESS_DENIED: 'Wrong user',
};

export const DESKTOP_BREAKPOINT = 'md';
export const IMG_STORAGE_LINK = 'https://storage.googleapis.com/jobs-unleashed-common/talents/images/';
export const GOOGLE_MAPS_API_KEY= 'AIzaSyCc225S_S6fJqPklEYQdQesSYjQUzHV3T4';

export const TALENT_ROLES_CONFIG = {
  'vet-tech-or-assistant': {
    subKey: 'vetSubCategory',
    dataSubKey: 'vet-sub-category',
    title: 'Vet Tech / Vet Assistant',
  },
  'specialist-veterinarian': {
    subKey: 'specialty',
    dataSubKey: 'speciality',
    title: 'Specialist Veterinarian',
  },
  'dvm-doctor-of-veterinary-medicine': {
    subKey: 'subCategory',
    dataSubKey: 'sub-category',
    title: 'DVM - Doctor of Veterinary Medicine',
  },
  'non-clinical': {
    subKey: 'jobCategory',
    dataSubKey: 'job-category',
    title: 'Non-Clinical',
  },
};
export const TALENT_PROFILE_ROLE_MAPPING = {
  speciality: {
    items: SPECIALITIES_MAP,
  },
  specialty: {
    items: SPECIALITIES_MAP,
  },
  'sub-category': {
    items: DVM_CATEGORIES,
  },
  'vet-sub-category': {
    items: VET_CATEGORIES,
  },
  'job-category': {
    items: JOB_CATEGORIES,
  },
};
export const SEARCH_SHORT_TEXT = {
  'new matches': 'new',
  'Default Home Relief': 'Home Relief',
  'Network Relief Pool': 'Network Pool',
  'Externship Work Near Me': 'Externships Near Me',
};

export const CORONADO_BADGE_TITLE = 'Coronado ER';
