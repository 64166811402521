import React, { Component, Fragment } from 'react';
import { TextField } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { IMG_STORAGE_LINK } from '../constants/Constants';
import PlaceIcon from '@mui/icons-material/Place';
export class LocationSearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.address || '',
    };
    this.descriptionAddress = '';
  }

  handleChange = (address) => {
    const { onChange } = this.props;

    this.setState({ address });
    if (!address || address !== this.descriptionAddress) {
      const addressObj = {
        state: '',
        city: '',
        postalCode: '',
        address: '',
        lat: '',
        lng: '',
      };
      onChange(addressObj);
    }
  };

  setLocationValues = (result, latlon) => {
    const { onChange } = this.props;
    const state = result.address_components.filter(x => x.types.indexOf('administrative_area_level_1') >= 0)
      .map(x => x.short_name);
    const city = result.address_components.filter(x => x.types.indexOf('locality') >= 0 || x.types.indexOf('sublocality') >= 0 || x.types.indexOf('administrative_area3') >= 0)
      .map(x => x.short_name);
    const postalCode = result.address_components.filter(x => x.types.indexOf('postal_code') >= 0)
      .map(x => x.short_name);
    let address = state.length ? state[0] : address;
    if (city.length) {
      address = city[0];
    }
    if (postalCode.length) {
      address = `${address}, ${postalCode[0]}`;
    }
    let country = [];
    if (!address) {
      country = result.address_components.filter(x => x.types.indexOf('country') >= 0)
        .map(x => x.short_name);
      address = country.length ? country[0] : address;
    }

    const addressObj = {
      country: country.length ? country[0] : '',
      state: state.length ? state[0] : '',
      city: city.length ? city[0] : '',
      postalCode: postalCode.length ? postalCode[0] : '',
      address,
      lat: latlon.lat,
      lng: latlon.lng,
    };
    onChange(addressObj);
  };

  handleSelect = (address, placeId, suggestion) => {
    this.setState({
      address,
    });
    this.descriptionAddress = address;

    // When the suggestion is to indicate the entire state (i.e. 'AK, USA'),
    // it has type ADMINISTRATIVE_AREA_LEVEL_1/COUNTRY
    // and causes ZERO_RESULTS error calling geocodeByAddress
    if (suggestion
        && (suggestion.types.indexOf('administrative_area_level_1') >= 0
            || suggestion.types.indexOf('country') >= 0)) {
      geocodeByPlaceId(placeId)
        .then(results => Promise.all([results[0], getLatLng(results[0])]))
        .then(([result, latlon]) => this.setLocationValues(result, latlon))
        .catch(error => console.error('Error', error));
    } else {
      geocodeByAddress(address)
        .then(results => Promise.all([results[0], getLatLng(results[0])]))
        .then(([result, latlon]) => this.setLocationValues(result, latlon))
        .catch(error => console.error('Error', error));
    }
  }

  render() {
    const {
      placeholder, variant, onChange, fullWidth, errorBorder, onKeyUp, stateSearch, isSearchBar,
    } = this.props;
    const searchOptions = {
      componentRestrictions: { country: ['us'] },
      types: stateSearch ? ['administrative_area_level_1'] : ['(regions)'],
    };
    const { anchorRef, address } = this.state;
    return (
      <PlacesAutocomplete
        value={address}
        searchOptions={searchOptions}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({
          getInputProps, suggestions, getSuggestionItemProps, loading,
        }) => (
          <Fragment>
              <TextField
                key='location_input'
                onClick={e => this.setState({ anchorRef: e.currentTarget })}
                onFocus={e => this.setState({ anchorRef: e.currentTarget })}
                variant={variant}
                placeholder={placeholder}
                value={address}
                fullWidth={fullWidth}
                {...getInputProps({
                  className: 'location-search-input',
                })}
                error={
                  this.props.address
                    ? this.state.address.split(',')[0] !== this.props.address.split(',')[0]
                      && this.state.address !== this.descriptionAddress
                    : errorBorder && Boolean(address)
                }
                inputProps={{ autoComplete: 'new-password', style: { paddingLeft: 0, paddingRight: 0 }} }
                InputProps={{ style: { height: '35px', fontSize: '16px', fontWeight: 400, }, 
                startAdornment: (
                  <img
                    src='https://storage.googleapis.com/jobs-unleashed-common/icon.ico'
                    style={{ width: isSearchBar ? 24 : 0, height: isSearchBar? 24 : 0, transform: 'scaleX(-1) translateX(8px)', visibility: isSearchBar ? 'visible' : 'hidden' }}
                  />
                ),
                endAdornment: (
                  <PlaceIcon style={{ visibility: isSearchBar ? 'visible' : 'hidden', color: '#9e9e9e' }}/>
                ),
              }}
                onKeyUp={event => onKeyUp && onKeyUp(event)}
              />
            <Popper
              open={(loading || suggestions.length) ? true : false}
              anchorEl={anchorRef}
              placement="bottom-start"
              key='location_popper'
              style={{ zIndex: 5000 }}
            >
              <Paper key='location_list_container'>
                <List className="autocomplete-dropdown-container" key='location_list'>
                  {loading && <Typography variant="h5" key='loading'>Loading...</Typography>}
                  {suggestions.slice(0, 4).map((suggestion) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    return (
                      <ListItem
                        key={suggestion.description}
                        button
                        {...getSuggestionItemProps(suggestion, {
                          className,
                        })}
                      >
                        {suggestion.description}
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
              </Popper>
            {
              <Typography
                variant='caption'
                color='error'
                align='center'
                style={{ display: 'flex', height: '10px' }}
                key='location_error'
              >
                {
                  errorBorder && Boolean(address)
                  && (
                    <Fragment>
                      <img src={`${IMG_STORAGE_LINK}icons/warning.svg`} alt='warning icon' height='14' key='location_error_img' />
                      <span key='location_error_msg'>Please select a location from the dropdown</span>
                    </Fragment>
                  )
                }
              </Typography>
            }
            </Fragment>
        )}
      </PlacesAutocomplete>
    );
  }
}
