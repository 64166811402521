import { minHeight } from '@mui/system';
import { aquaGreen, navy, orange, lightBlue, tiffany, textColor, bgGray } from '../../../css/style';
const SEARCH_ITEM_HEIGHT = 42;

export const MatchFilterStyle  = (theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#ffffff',

  },
  boxContainer: {
    height: '100%',
  },
  matchTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '36px',
  },
  title: {
    fontSize: 25,
    fontWeight: 700,

    // mobile styles
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
  whiskerIcon: {
    marginRight: '4px',
    width: '37px',
    height: '8px',
  },
  searchContainer: {
    marginTop: 10,
  },

  icon: {
    color: orange,
    paddingTop: 2,
    fontSize: '1.7rem',
  },
  countText: {
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
    },
  },
  ellipsisText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    // mobile styles
    [theme.breakpoints.down('md')]: {
      textOverflow: 'visible',
      fontSize: '13px',
    },

    // laptop styles
    [theme.breakpoints.up('lg')]: {
      maxWidth: '120px',
    },

    // desktop styles
    [theme.breakpoints.up('xl')]: {
      maxWidth: '160px',
    },
  },
  filterButton: {
    height: '27px',
    padding: '1px 12px',
    fontSize: '16px',
    fontWeight: 500,
    textTransform: 'capitalize',
    backgroundColor: 'transparent',
    color: lightBlue,
    border: 'none',

    [theme.breakpoints.down('md')]: {
      margin: '8px 4px',
      height: '24px',
    },

    '&:hover': {
      borderColor: aquaGreen,
      color: aquaGreen,
      fontWeight: 700,
    },
  },
  filterButtonSelected: {
    height: '27px',
    padding: '1px 12px',
    fontSize: '16px',
    textTransform: 'capitalize',
    borderColor: aquaGreen,
    color: aquaGreen,
    fontWeight: 700,

    [theme.breakpoints.down('md')]: {
      margin: '8px 4px',
      height: '24px',
    },
  },
  filterContainer: {
    marginTop: 20,
    marginBottom: 30,
    paddingRight: 40,
    position: 'relative',
  },
  locationFilterText: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: navy,
  },
  matchIteminfoIcon: {
    color: navy,
    marginRight: 5,
  },
  newSearchButton: {
    minWidth: '108px',
    height: '31px',
    fontFamily: 'Asap',
    fontSize: '16px',
    fontWeight: 600,
    textAlign: 'center',
    textTransform: 'none',
    borderRadius: '5px',
    backgroundColor: 'white',
    color: '#243060',
    border: '1px solid #243060',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'white',
      opacity: 0.8,
    },
  },
  itemStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '36px',
  },
  itemCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '36px',
  },
  itemEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '36px',

  },
  itemWBSearch: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 10px 0px 10px',
    flexWrap: 'wrap',
    justifyContent: 'space-between',

    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },

    [theme.breakpoints.down('md')]: {
      flexWrap: 'nowrap',
      overflow: 'auto',
      margin: 0,
    },
  },
  searchPaper: {
    width: '100%',
    position: 'fixed',
    backgroundColor: '#fafafa',
    padding: '20px 20px 14px 20px',
    boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
    maxHeight: '117px',
    overflowY: 'hidden',
    //marginTop: '60px',

    [theme.breakpoints.down('md')]: {
      padding: '16px',
    },
  },
  searchExtraMenu: {
    minWidth: 'unset',
    color: textColor,
    fontSize: '16px',
    fontWeight: '600',
  },
  searchFilterBtn: {
    width: '56px',
    height: '32px',
    fontSize: '13px',
    fontWeight: '600',
  },
  menuItem: {
    justifyContent: 'flex-start',
    padding: '4px 8px',

    '& .MuiTypography-body1': {
      fontSize: '16px',
      fontWeight: 500,
      textTransform: 'capitalize',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '175px',
    }
  },
  mMenuItem: {
    justifyContent: 'flex-start',
    padding: '4px 8px',

    '& .MuiTypography-body1': {
      fontSize: '16px',
      fontWeight: 500,
      textTransform: 'capitalize',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 'calc(100vw - 70px)',
    }
  },

  menuIcon: {
    display: 'flex',
    color: textColor,
    padding: '4px 8px',
    height: '32px',
    backgroundColor: 'transparent',

    // remove hover effect
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  menuBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: bgGray,
    margin: '16px',
    minWidth: 'unset',
    color: textColor,
    fontSize: '16px',
    fontWeight: '600',
  },

  calendarIconBox: {
    border: `1px solid ${tiffany}`,
    borderRadius: '50%',
    boxShadow: '0px 4px 4px 0px #00000040',
    cursor: 'pointer!important',
    backgroundColor: 'white',
  },
  matchSeachBar: {
    width: '372px',
    height: '36px',
  },
  calendarBtn: {
    color: '#364D96',
    border: '1px solid #4DBEAD',
    background: '#ffffff',
    boxShadow: '0 4px 4px 0 rgba(0,0,0,0.25)',
    cursor: 'pointer',
    padding: '3px',

    '&:disabled': {
      boxShadow: 'inset 0 4px 4px 0 rgba(0,0,0,.25)',
      border: '1px solid #C4CBD4',
    }
  },
  rotationSelectContainer: {
    width: '150px',
    position: 'relative',
  },
  rotationIcon: {
    position: 'absolute',
    top: 6,
    left: 7,
  },
  
  titleContainer: {
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: '#E1E5EA',
    borderBottom: '1px solid #D9D9D9',
  },
  badgeSelectContainer: {
    minWidth: '130px',
    position: 'relative',
  },

  chipOverflow: {
    maxWidth: '130px',
  },
  chipBadge: {
    marginLeft: '18px',
    marginTop: '2px',
  },
  inputEndAdornment: {
    marginLeft: '-4px',
  },
  closeButton: {
    color: 'grey',
    padding: '8px',
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    right: 5,

    '& svg': {
      width: '20px',  
      height: '20px',
    },
  },
  filterCloseButton: {
    color: 'grey',
    padding: '8px',
    cursor: 'pointer',
    position: 'absolute',
    top: 2,
    right: 2,

    '& svg': {
      width: '16px',  
      height: '16px',
    },
  },
  searchListDiv: {
    maxHeight: SEARCH_ITEM_HEIGHT * 5,
    overflowY: 'auto',
  },
  searchMenu: {
    '& .MuiList-padding': {
      padding: 0,
    },
  },
}));

export const sxStyles = {

  searchSelect: {
    width: '100%',

    '& .MuiSelect-select': {
      padding: ' 6px 9px',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    '& .MuiSelect-icon': {
      right: 7,
    },
  },
  badgeSelect: {
    width: '100%',
    '& .MuiSelect-select': {
      padding: '6px 24px 6px 9px !important',
      textTransform: 'capitalize',
    },
  },
  rotationSelect: {
    width: '100%',
    '& .MuiSelect-select': {
      padding: '6px 32px 6px 32px !important',
      textAlign: 'center',
    },
  },
};