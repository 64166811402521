import { Button, Divider, Grid, Hidden, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import types from '../../../actions/ActionTypes';
import { SignUpPageStyle } from '../../../css/style';
import EmailBar from '../../subcomponents/EmailBar';
import WhiskerBot from '../../subcomponents/WhiskerBot.jsx';
import { handleTrailingSpaces } from '../../../utils/Functions';
import { isEmpty } from 'lodash';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExtern: false,
    };
  }

  componentDidMount() {
    if (this.props.location?.pathname?.includes('extern-signup')) {
      this.setState({ isExtern: true });
      if (!isEmpty(this.props.user)) {
        this.props.history.push({
          pathname: "/externinfo",
          state: { from: "isExternUser" }
        })
      }
    }
  }

  handleEmailSignUpClick = (email, password) => {
    const { actions, history } = this.props;
    if (email.length === 0 && password.length === 0) {
      actions.setError('Please enter your email and password');
    } else if (email.length === 0) {
      actions.setError('Please enter your email');
    } else if (password.length === 0) {
      actions.setError('Please enter your password');
    } else {
      actions.registerUser({
        email: handleTrailingSpaces(email),
        password,
        isTalent: true,
      })
        .then((e) => {
          if (e.type === types.REGISTER_USER_SUCCESS) {
            if (this.state.isExtern) {
              history.push({
                pathname: "/externinfo",
                state: { from: "isExternUser" }
              })
            } else {
              history.push('/userinfo');
            }
          }
        });
    }
  };

  renderEmailSignUp = () => {
    const { classes } = this.props;
    return (
      <Grid
        container
        item
        xs={10}
        lg={6}
        className={classes.emailInputContainer}
      >
        <EmailBar
          emailPlaceHolder="Create account with Email"
          passwordPlaceHolder="Password"
          buttonText="Continue with Email"
          onClick={this.handleEmailSignUpClick}
          buttonColor="secondary"
        />
        <div className={classes.privacyPolicyContainer}>
          <Typography className={classes.privacyPolicyText}>
            By continuing, you agree to our&nbsp;
            <Link
              href="/"
              className={classes.link}
              to="/"
              // TODO: implement link to terms of use
            >
              Terms of Use
            </Link>
            &nbsp;and&nbsp; 
            <Link
                href="/"
                className={classes.link}
                to="/"
                // TODO: implement link to privacy policy
              >
                Privacy Policy
              </Link>
          </Typography>
        </div>
      </Grid>
    );
  };

  render() {
    const { classes, history } = this.props;
    return (
      <Grid
        container
        align="center"
        justifyContent="center"
        className={classes.signUpGridContainer}
      >
        {!this.state.isExtern && <Hidden mdDown>
          <Grid item xs={8} className={classes.whiskerBotBanner}>
            <WhiskerBot long />
          </Grid>
        </Hidden>}
        <Typography className={classes.signUpTitle}>
          {this.state.isExtern ? 'EXTERNSHIP SIGN UP' : 'Create a Job-Seeker account for free access to our WhiskerBot Matching Engine'}
        </Typography>
        {this.renderEmailSignUp()}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <div className={classes.memberSignIn}>
          {this.state.isExtern ? 'Already an account?' : 'Already a member?'}
          <Button className={classes.signInButton} onClick={() => history.push(this.state.isExtern ? '/externlogin' : '/login')}>
            Sign In
          </Button>
        </div>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {!this.state.isExtern && <div className={classes.hiringContainer}>
          <div className={classes.signupButtonContainer}>
            <Typography className={classes.hiringText}>
              Hiring?
            </Typography>
            <Button
              className={classes.buttonText}
              color='primary'
              variant='contained'
              onClick={() => window.location = 'https://employers.jobsunleashed.com/signup'}
            >
              Sign Up Here
            </Button>
          </div>
          <Typography className={classes.hiringText}>
            as an Employer!
          </Typography>
        </div>}
      </Grid>
    );
  }
}

SignUp.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  history: PropTypes.shape({
    action: PropTypes.string,
    location: PropTypes.object,
    length: PropTypes.number,
    push: PropTypes.func,
  }).isRequired,
};

export default withStyles(SignUpPageStyle)(withRouter(SignUp));
