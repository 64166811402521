import { Link, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { ConfirmEmailStyles } from '../../css/style';

class ConfirmEmail extends Component {
  handleClick = () => {
    const { actions, user } = this.props;
    actions.sendVerifyAccountEmail(user, 'users', 'verify');
  };

  render() {
    const { classes, user } = this.props;
    const isSignUpStudentUser = localStorage.getItem(`student_user_${user?.id}`) === 'true';
    return (
      <div className={classes.container}>
        <Typography className={classes.title}>
          CONFIRM YOUR EMAIL TO ENABLE CONNECTIONS!
        </Typography>
        <Typography className={classes.body}>
          {`Please check your inbox for a confirmation email.`}
          {(!user?.isStudent && !isSignUpStudentUser) && ` Click the link and start using Whisker Search.`}
        </Typography>
        <div className={classes.linkContainer}>
          <Typography className={classes.body}>
            {'Didn\'t receive the email?'}
            &nbsp;
          </Typography>
          <Link href="#" className={classes.link} underline="always" onClick={this.handleClick}>
            Send again.
          </Link>
        </div>
      </div>
    );
  }
}

export default withStyles(ConfirmEmailStyles)(ConfirmEmail);
