// /* eslint-disable import/no-named-as-default */
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Hidden,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { PropTypes } from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { IMG_STORAGE_LINK } from '../../constants/Constants';
import { ThankYouStyle } from '../../../css/thankyouStyle';
import { isTemporaryUser } from '../../../utils/Functions';
import types from '../../../actions/ActionTypes';

class ThankYou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTemporaryUser: false,
    };
  }

  componentDidMount() {
    const { user, history, actions } = this.props;
    if (isTemporaryUser(user.email)) {
      this.setState({ isTemporaryUser: true });
    } else {
      history.push('/');
    }
  }

  componentWillUnmount() {
    this.props.actions.logoutUser();
  }

  render() {
    const { classes } = this.props;
    return (
      <Container maxWidth='lg' className={classes.container}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="row-reverse"
          className={classes.gridContainer}
        >
          <Grid
            item
            xs={12}
            lg={6}
            container
            justifyContent='center'
            className={classes.itemContainer}
          >
            <img src={`${IMG_STORAGE_LINK}ic-check.svg`} alt="Check Icon" />
            <Typography className={classes.title}>Thank You For Signing Up!</Typography>
            <div className={classes.divider} />
            <Typography className={classes.paragraph}>
              Congratulations on completing your registration!
              <br />
              <br />
              {`We are delighted to have you as a member of our community. 
                Our team will check your information and get back to you as soon as possible. 
                In the meanwhile, please do not hesitate to `}
              <a href="https://www.jobsunleashed.com/contact-us">contact us</a>
              {' if you have any questions or issues.'}
              <br />
              <br />
              Thank you for considering us, and we hope to chat with you soon!
            </Typography>
            <Typography className={classes.signature}>
              Jobs Unleashed Team
            </Typography>
            <a href="https://jobsunleashed.com/" className={classes.link}>
              Back To Home
            </a>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

ThankYou.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
};

export default withStyles(ThankYouStyle)(withRouter(ThankYou));
