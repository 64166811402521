import { Container, Grid, Hidden, Typography, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { IMG_STORAGE_LINK } from '../constants/Constants';
import { UserProfilePageStyle } from '../../css/style';
import AboutYouPage from './AboutYouPage';
import ContactInfoComponent from './ContactInfoComponent';
import NotificationPanel from './NotificationPanel';
import ExperienceDetailsPage from './ExperienceDetailsPage';

const fastpassIcon = `${IMG_STORAGE_LINK}green-ticket.png`;

class UserProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = { fastpassData: [] };
  }

  componentDidMount() {
    const { user, actions } = this.props;
    if (user) {
      actions.getAllResources(user.token, 'schools');
      actions.getAllResources(user.token, 'criterias');
      actions.getAllResources(user.token, 'talents');
      actions.getAllResources(user.token, 'talentprofileitems');
      actions.getAllResources(user.token, `fastpass/user/${user.id}`).then(fastpassRes => {
        const activeFastpass = (fastpassRes?.response || []).filter(r => !r.deletedAt) || [];
        this.setState({ fastpassData: activeFastpass });
      });
    }
  }

  getFilteredResource = (resourceToMatch) => {
    const { user, resources } = this.props;
    const { talent_id } = user;
    let resource = [];

    if (resources.talentprofileitems) {
      resource = resourceToMatch.reduce((result, resource) => {
        const talentProfileItem = resources.talentprofileitems.find((item) => {
          if (item.talent_id === talent_id) {
            const { criterias } = resources;
            if (criterias) {
              const criteria = resources.criterias.find(resourceCriteria => resourceCriteria.id === item.criteria_id);
              return !!(criteria && resource.criteria === criteria.name);
            }
          }
          return false;
        });

        if (talentProfileItem) {
          result.push(talentProfileItem);
        }

        return result;
      }, []);
    }

    return resource;
  };

  renderExperienceDetails() {
    const { actions, user } = this.props;
    const { criterias, talents, talentprofileitems, schools } = this.props.resources;

    if (!criterias || !talents || !talentprofileitems) {
      return null;
    }

    return (
      <ExperienceDetailsPage
        actions={actions}
        user={user}
        criterias={criterias}
        talents={talents}
        talentprofileitems={talentprofileitems}
        vetSchools={schools}
      />
    );
  }

  renderFastpassLocations() {
    const { classes } = this.props;
    const { fastpassData } = this.state;

    if (isEmpty(fastpassData)) return null;

    return (
      <Container className={classes.container} style={{ marginTop: '28px' }}>
        <Typography className={classes.title}>
          FASTPASS LOCATIONS
        </Typography>
        <Grid direction="column" container justifyContent="center" alignItems="center">
          <Grid
            className={classes.innerGrid}
            direction="row"
            alignContent="space-between"
            container
            spacing={2}
          >
            <List>
              {fastpassData.map(item => (
                <ListItem key={item.id} >
                  <ListItemAvatar key={`${item.id}_icon`} className={classes.listItemAvatar} >
                    <img style={{ width: 20, height: 20 }} src={fastpassIcon} />
                  </ListItemAvatar>
                  <ListItemText
                    key={`${item.id}_text`}
                    primary={item.practiceName || '-'}
                    className={classes.listItemText}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Container>
    );
  }

  refreshState = () => {
    const { actions, user } = this.props;
    actions.getAllResources(user.token, 'savedsearchs');
  };

  render() {
    const {
      classes, user, resources, actions,
    } = this.props;

    return (
      <div style={{ marginTop: '24px', overflow: 'auto' }}>
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={2}
            className={classes.grid}
          >
            <Grid item xs={12} sm={6} md={6}>
              {this.renderExperienceDetails()}
              <AboutYouPage
                user={user}
                resources={resources}
                criterias={resources.criterias}
                actions={actions}
              />
              <ContactInfoComponent
                childRef={ref => (this.contactInfo = ref)}
                user={user}
                actions={actions}
                resources={resources}
              />
              {this.renderFastpassLocations()}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <NotificationPanel
                childRef={ref => (this.notification = ref)}
                user={user}
                actions={actions}
                resources={resources}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default withStyles(UserProfilePageStyle)(withRouter(UserProfilePage));
