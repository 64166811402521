import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { SimpleSelectableSquareStyle } from '../../../css/style';
import { IMG_STORAGE_LINK } from '../../constants/Constants';


export default function SimpleSelectableIconComponent(props) {
  const {
    title, icon, selectedIcon, selected, onClick, style,
  } = props;
  const classes = (style ? makeStyles(style) : makeStyles(SimpleSelectableSquareStyle))();
  const iconName = icon && icon === 'dvm-doctor-of-veterinary-medicine' ? 'gp-veterinarian' : icon;
  const image = icon && `${IMG_STORAGE_LINK}icons/${selected ? (selectedIcon || iconName) : iconName}.png`;
  return (
    <Paper onClick={onClick} className={selected ? classes.selectedSquare : classes.square}>
      {image && (
        <div className={icon === 'telemedicine-in-person' ? classes.centeredImageContainer : classes.imageContainer}>
          <img src={image} className={icon === 'telemedicine-in-person' ? classes.largeWidthImage : classes.image} alt="Selectable Icon" />
        </div>
      )}
      <Typography gutterBottom align="center" color="inherit" className={classes.squareText}>
        {title}
      </Typography>
    </Paper>
  );
}
