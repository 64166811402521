import { createMemoryHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import RootReducer from '../reducers';

const middleware = routerMiddleware(createMemoryHistory);

const enhancers = [
  applyMiddleware(middleware, thunk),
];

const composeEnhancers = composeWithDevToolsDevelopmentOnly({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const persistConfig = {
  key: 'root',
  storage: storageSession,
  blacklist: ['loading'],
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

export const store = createStore(persistedReducer, composeEnhancers(compose(...enhancers)));
export const persistor = persistStore(store);
