import React, { useEffect, useState, useRef  } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Grid,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { green, navy, orangeLight, bgGray, tiffany } from '../../css/style';
import  { getTimeCategory } from '../../utils/Functions';
import { IMG_STORAGE_LINK } from '../constants/Constants';

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: 1,
    top: 1,
    color: theme.palette.grey[500],
  },
  tag: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
  },
  blockedTag: {
    width: '12px',
    height: '12px',
    borderRadius: '2px',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tagContainer: {
    fontFamily: 'Asap',
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 24,
    padding: '8px 0px',
  },
  submitButtonContainer: {
    width: '30%',
    maxWidth: '100px',
  },
  shiftCalendarTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleTextDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  shiftCalendarTitle: {
    color: navy,
    padding: '0px 8px',
    fontSize: '20px',
    fontWeight: '700',
  },
  calendarTitle: {
    color: navy,
    fontSize: '16px',
    fontWeight: '600',
  },
  shiftCalendarToolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px',

    [theme &&theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  shiftCalendarMonthBtn: {
    border: `1px solid ${tiffany}`,
    borderRadius: '50%',
    cursor: 'pointer!important',
    backgroundColor: 'white',
    boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
    justifyContent: 'center',
    alignItems: 'center',
    color: navy,
    display: 'flex',
    width: '20px',
    height: '20px',
    marginRight: '8px',
  },
  shiftInfo: {
    fontSize: 10,
    color: 'white',
  },
  fullCalendarContainer: {
    '& .fc .fc-toolbar.fc-header-toolbar': {
      color: navy,
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: '2',
    },
    '& .fc-toolbar-chunk': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .fc .fc-toolbar-title': {
      color: navy,
      fontSize: '20px',
      fontWeight: '700',
    },
    '& .fc .fc-button-primary': {
      backgroundColor: 'white',
      color: navy,
      borderColor: tiffany,
      borderRadius: '50%',
      padding: '4px',
      fontSize: '10px',
      fontWeight: '700',

      '&:hover': {
        backgroundColor: 'white',
        color: tiffany,
      },
      '&:disabled': {
        backgroundColor: 'white',
        color: tiffany,
      },
    },
    '& .fc td, .fc th': {
      minWidth: '156px',
    },
    '& .fc .fc-daygrid-day-number': {
      fontSize: '25px',
      color: navy,

      [theme &&theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
    '& .fc .fc-daygrid-day.fc-day-today': {
      backgroundColor: bgGray,
      boxShadow: '0px 0px 0px 3px #A6B0BF inset',
      border: '1px solid #DCE0E5',
      fontWeight: '700',
    },
    '& .fc-daygrid-dot-event': {
      padding: 0,
      borderRadius: '20px',
    },
    '& .fc-daygrid-day-events': {
      '&:hover': {
        backgroundColor: 'white',
      },
      '&:focus': {
        backgroundColor: 'white',
      },
    },
  },
  error: {
    color: 'red',
    paddingTop: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const useStyles = makeStyles(theme => ({...styles(theme),}));

const ShiftCalendarDialog = (props) => {
  const { 
    handleClose, 
    open, 
    shiftCalendarPractice, 
    shiftCalendarMatches = [], 
    handleEventSelect = () => {},
    isMobile,
  } = props;
  const [events, setEvents] = useState([]);
  const [monthTitle, setMonthTitle] = useState('');
  const [selected, setSelected] = useState({});
  const [initialDate, setInitialDate] = useState(null);
  const [warningMsg, setWarningMsg] = useState('');

  const classes = useStyles();
  const fullCalendarRef = useRef(null)

  useEffect(() => {
    const handleEvents = () => {
      let events = [];
      let avaiDates = [];
      shiftCalendarMatches.forEach(match => {
        if (!isEmpty(match.avaiDateKeyList)) {
          events = events.concat(match.avaiDateKeyList.map(date => {
            const st = match?.jobPosting?.startTime ? moment(`${date} ${match.jobPosting.startTime}`, 'YYYY-MM-DD h:mm(A)') : date;
            let et = match?.jobPosting?.endTime ? moment(`${date} ${match.jobPosting.endTime}`, 'YYYY-MM-DD h:mm(A)') : null;
            let endTimeStr;
            if (et && et < st) {
              et = moment(`${date} 11:59(PM)`, 'YYYY-MM-DD h:mm(A)');
              endTimeStr = moment(`${date} ${match.jobPosting.endTime}`, 'YYYY-MM-DD h:mm(A)');
            }
            avaiDates.push(new Date(date));

            return {
              start: new Date(st),
              startStr: st,
              end: et ? new Date(et) : null,
              backgroundColor: green,
              textColor: 'white',
              matchId: match.id,
              practiceId: match?.practice?.id,
              isAvailable: true,
              shiftDate: date,
              jobId: match?.jobPosting?.id,
              endTimeStr,
            }
          }))
        }
        if (!isEmpty(match.confirmedDateKeyList)) {
          events = events.concat(match.confirmedDateKeyList.map(date => {
            const st = match.jobPosting.startTime ? moment(`${date} ${match.jobPosting.startTime}`, 'YYYY-MM-DD h:mm(A)') : date;
            const et = match.jobPosting.endTime ? moment(`${date} ${match.jobPosting.endTime}`, 'YYYY-MM-DD h:mm(A)') : null;

            return {
              start: new Date(st),
              end: et ? new Date(et) : null,
              backgroundColor: navy,
              textColor: 'white',
              matchId: match.id,
              practiceId: match?.practice?.id,
              shiftDate: date,
            }
          }))
        }
      });
      setEvents(events);
      const sortedDateList = avaiDates?.length ? avaiDates.sort((date1, date2) => (date1 - date2)) : [new Date()];
      setInitialDate(sortedDateList[0]);
    }
    handleEvents();
  }, [shiftCalendarMatches]);

  const renderFullCalendar = () => {
    return (
      <div style={{ minWidth: 'unset' }} className={classes.fullCalendarContainer}>
        <FullCalendar
          ref={fullCalendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView='dayGridMonth'
          initialDate={initialDate}
          weekends={true}
          events={events}
          eventContent={(eventInfo) => renderEventContent(eventInfo)}
          eventMinHeight={30}
          contentHeight={600}
          headerToolbar = {{ left: '', center: '', right: '' }}
          viewDidMount = {(info) => {
            setMonthTitle(info?.view?.title);
          }}
          dateClick={(e) => {
            setWarningMsg('');
            setSelected({});
          }}
          eventClick={(e) => {
            const eventData = e?.event || {};
            const eventExtenedData = e?.event?._def?.extendedProps || {};
            if (!eventExtenedData.isAvailable) return;
            const startDateOnlyStr = moment(eventData.startStr).format('YYYY-MM-DD');
            if (shiftCalendarMatches.find(match => match?.confirmedDateKeyList?.includes(startDateOnlyStr))) {
              setWarningMsg(`${moment(startDateOnlyStr).format('MM/DD')} has a confirmed shift.`);
              return;
            }
            setWarningMsg('');
            const selectedEvent = {
              matchId: eventExtenedData.matchId,
              jobId: eventExtenedData.jobId,
              date: startDateOnlyStr,
              start:eventData.start,
              end: eventData.end,
            };
            setSelected(selectedEvent);
            handleEventSelect(selectedEvent);
          }}
        />
        {warningMsg && (<div className={classes.error}>
          <ErrorOutlineIcon fontSize='small' />
          <span style={{marginLeft: '5px'}}>{warningMsg}</span>
        </div>)}
      </div>
    )
  }

  const renderEventContent = (eventInfo) => {
    const eventData = eventInfo?.event || {};
    const eventDataExtended = eventData.extendedProps || {};
    const isSelected = eventDataExtended?.isAvailable
      &&  selected?.matchId === eventDataExtended?.matchId && selected?.date === eventDataExtended?.shiftDate;
    const shiftCat = eventData.start && eventData.end ? getTimeCategory(moment(eventData.start)) : null;

    return (
      <div
        style={{
          width: '100%',
          backgroundColor: isSelected ? orangeLight : eventData.backgroundColor || 'black',
          textAlign: 'center',
          border: `1px solid ${isSelected ? orangeLight : eventData.backgroundColor || 'black'}`,
          borderRadius: eventDataExtended.isAvailable ? isMobile ? '10px' : '20px' : '0px',
          padding: '2px 6px',
          display: 'flex',
          justifyContent: !eventData.end || isMobile ? 'center' : 'space-between',
          alignItems: 'center',
          cursor:  eventDataExtended.isAvailable ? 'pointer' : 'unset',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <span className={classes.shiftInfo}>
          {!eventData.end
            ? 'Shift'
              : `${moment(eventData.start).format('h:mm a')}`.replace(':00','')}
        </span>
        {eventData.end && <span className={classes.shiftInfo}> - </span>}
        {eventData.end && <span className={classes.shiftInfo}>
          {`${moment(eventDataExtended.endTimeStr || eventData.end).format('h:mm a')}`.replace(':00','')}
        </span>}
        {shiftCat && (
          <img
            src={shiftCat === 'MORNING'
              ? `${IMG_STORAGE_LINK}shift_morning.svg`
                : shiftCat === 'AFTERNOON' ? `${IMG_STORAGE_LINK}shift_afternoon.svg` : `${IMG_STORAGE_LINK}shift_evening.svg`}
            style={{ width: '16px', height: '16px' }}
          />
        )}
      </div>
    )
  }

  return (<>
    <Dialog
      maxWidth="md"
      PaperProps={{
        style: {
          minWidth: isMobile ? '100%' : '900px',
          border: '1px solid #E1E5EA',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '8px',
        },
      }}
      open={open}
      onClose={() => handleClose()}
    >
      <DialogTitle className={classes.dialogContent} style={{ maxWidth: 'unset', backgroundColor: bgGray, padding: '12px 20px' }}>
        <Grid container className={classes.shiftCalendarTitleContainer}>
          <Grid item xs={12} md={9} className={classes.shiftCalendarToolbar}>
            <div className={classes.titleTextDiv}>
              <Box className={classes.shiftCalendarMonthBtn}>
                <IconButton onClick={() => {
                  fullCalendarRef?.current?.getApi()?.prev();
                  setMonthTitle(fullCalendarRef?.current?.getApi()?.currentData?.viewTitle);
                }} size='small'>
                  <ChevronLeftIcon style={{ fontSize: '16px' }} />
                </IconButton>
              </Box>
              <Box className={classes.shiftCalendarMonthBtn}>
                <IconButton onClick={() => {
                  fullCalendarRef?.current?.getApi()?.next();
                  setMonthTitle(fullCalendarRef?.current?.getApi()?.currentData?.viewTitle);
                }} size='small'>
                  <ChevronRightIcon style={{ fontSize: '16px' }} />
                </IconButton>
              </Box>
              <Typography className={classes.shiftCalendarTitle}>{monthTitle}</Typography>
            </div>
            <div className={classes.titleTextDiv} style={{ flexWrap: 'wrap' }}>
              <Typography className={classes.calendarTitle}>Connected Shifts at&nbsp;</Typography>
              <Typography className={classes.calendarTitle} style ={{ textTransform: 'capitalize', padding: '0px', }}>
                {shiftCalendarPractice?.name}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={3} className={classes.tagContainer}>
            <div className={classes.item}>
              <div  className={classes.tag} style={{ backgroundColor: '#5aac44' }} />
              <span>Available</span>
            </div>
            <div className={classes.item}>
              <div className={classes.tag} style={{ backgroundColor: orangeLight }} />
              <span>Take Shift</span>
            </div>
            <div className={classes.item}>
              <div className={classes.blockedTag}  style={{ backgroundColor: navy }} />
              <span>Confirmed</span>
            </div>
          </Grid>
        </Grid>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => handleClose()}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} style={{ maxWidth: 'unset' }}>
        {renderFullCalendar()}
      </DialogContent>
    </Dialog>
    </>
  );
}

ShiftCalendarDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default withRouter(ShiftCalendarDialog);

