import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, FormControlLabel, RadioGroup, Radio,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { SpecialistTypeStyle } from '../../../css/style';
import OnboardingItemComponent from './OnboardingItemComponent';

class SpecialistTypeComponent extends OnboardingItemComponent {
  constructor(props) {
    super(props);
    const { initState, setOnboardingItems } = props;
    const specialist = initState.length > 0 ? initState[0].strValue : 'false';
    this.state = {
      specialist,
    };
  }

  /**
   * Ensures that default value (false) is sent to API
   */
  componentDidMount() {
    const { addSkippedStep, initState, removeSkippedStep } = this.props;
    const specialist = initState.length > 0 ? initState[0].strValue : 'false';
    if (specialist === 'false') {
      addSkippedStep();
    } else {
      removeSkippedStep();
    }
  }

  specializationClicked = (event) => {
    const { addSkippedStep, removeSkippedStep } = this.props;
    const st = {};
    st.specialist = event.target.value;
    if (event.target.value === 'false') {
      addSkippedStep();
    } else {
      removeSkippedStep();
    }
    this.setState(st, () => {
      this.handleState();
    });
  }

  handleState = () => {
    const { handleStateChange } = this.props;
    const { specialist } = this.state;
    const items = [];
    items.push({ criteria: 'specialist', strValue: specialist });
    handleStateChange(items);
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <RadioGroup
          className={classes.radioContainer}
          name="specialistOption"
          defaultValue={this.state.specialist}
          onChange={this.specializationClicked}
        >
          <FormControlLabel value="true" control={<Radio />} label="Yes" labelPlacement="bottom" />
          <FormControlLabel value="false" control={<Radio />} label="No" labelPlacement="bottom" />
        </RadioGroup>
      </Grid>
    );
  }
}

SpecialistTypeComponent.propTypes = {
  criteria: PropTypes.string.isRequired,
  initState: PropTypes.array.isRequired,
  handleStateChange: PropTypes.func.isRequired,
  addSkippedStep: PropTypes.func.isRequired,
  removeSkippedStep: PropTypes.func.isRequired,
};

SpecialistTypeComponent.defaultProps = {
  initState: [],
};

export default withStyles(SpecialistTypeStyle)(SpecialistTypeComponent);
