const types = {
  // non api call actions
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
  ERROR: 'ERROR',
  LOGOUT_USER: 'LOGOUT_USER',
  CLEAR_401_MESSAGE: 'CLEAR_401_MESSAGE',
  RENDER_MESSAGE: 'RENDER_MESSAGE',
  // api call actions - request
  LOGIN_USER_REQUEST: 'LOGIN_USER_REQUEST',
  REGISTER_USER_REQUEST:'REGISTER_USER_REQUEST',
  GET_ALL_RESOURCES_REQUEST: 'GET_ALL_RESOURCES_REQUEST',
  GET_ONE_RESOURCE_REQUEST: 'GET_ONE_RESOURCE_REQUEST',
  ADD_RESOURCE_REQUEST: 'ADD_RESOURCE_REQUEST',
  UPDATE_RESOURCE_REQUEST: 'UPDATE_RESOURCE_REQUEST',
  DELETE_RESOURCE_REQUEST: 'DELETE_RESOURCE_REQUEST',
  SEND_RESET_PASSWORD_TOKEN_REQUEST: 'SEND_RESET_PASSWORD_TOKEN_REQUEST',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  VERIFY_USER_REQUEST: 'VERIFY_USER_REQUEST',
  GET_USER_REGISTRATION: 'GET_USER_REGISTRATION',
  UPDATE_USER_REGISTRATION: 'UPDATE_USER_REGISTRATION',
  // api call actions - success
  LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
  REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
  GET_ALL_RESOURCES_SUCCESS: 'GET_ALL_RESOURCES_SUCCESS',
  GET_ONE_RESOURCE_SUCCESS: 'GET_ONE_RESOURCE_SUCCESS',
  ADD_RESOURCE_SUCCESS: 'ADD_RESOURCE_SUCCESS',
  UPDATE_RESOURCE_SUCCESS: 'UPDATE_RESOURCE_SUCCESS',
  UPDATE_RESOURCE_NO_LOADING_SUCCESS: 'UPDATE_RESOURCE_NO_LOADING_SUCCESS',
  DELETE_RESOURCE_SUCCESS: 'DELETE_RESOURCE_SUCCESS',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  SEND_RESET_PASSWORD_TOKEN_SUCCESS: 'SEND_RESET_PASSWORD_TOKEN_SUCCESS',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  VERIFY_USER_SUCCESS: 'VERIFY_USER_SUCCESS',
  VERIFY_ACCOUNT_EMAIL_SUCCESS: 'VERIFY_ACCOUNT_EMAIL_SUCCESS',
  VERIFY_ACCOUNT_SMS_SUCCESS: 'VERIFY_ACCOUNT_SMS_SUCCESS',
  UPDATE_USER_REGISTRATION_SUCCESS: 'UPDATE_USER_REGISTRATION_SUCCESS',
  // api call actions - failure
  LOGIN_USER_FAILURE: 'LOGIN_USER_FAILURE',
  REGISTER_USER_FAILURE: 'REGISTER_USER_FAILURE',
  GET_ALL_RESOURCES_FAILURE: 'GET_ALL_RESOURCES_FAILURE',
  GET_ONE_RESOURCE_FAILURE: 'GET_ONE_RESOURCE_FAILURE',
  ADD_RESOURCE_FAILURE: 'ADD_RESOURCE_FAILURE',
  UPDATE_RESOURCE_FAILURE: 'UPDATE_RESOURCE_FAILURE',
  DELETE_RESOURCE_FAILURE: 'DELETE_RESOURCE_FAILURE',
  UPDATE_RESOURCE_NO_LOADING_FAILURE: 'UPDATE_RESOURCE_NO_LOADING_FAILURE',
  SEND_RESET_PASSWORD_TOKEN_FAILURE: 'SEND_RESET_PASSWORD_TOKEN_FAILURE',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  VERIFY_USER_FAILURE: 'VERIFY_USER_FAILURE',
  VERIFY_ACCOUNT_EMAIL_FAILURE: 'VERIFY_ACCOUNT_EMAIL_FAILURE',
  VERIFY_ACCOUNT_SMS_FAILURE: 'VERIFY_ACCOUNT_SMS_FAILURE',
  UPDATE_USER_REGISTRATION_FAILURE: 'UPDATE_USER_REGISTRATION_FAILURE',
};

export default types;
