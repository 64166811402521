import React, { useState, useEffect, Fragment } from 'react';
import {
  TextField,
  Chip,
  Checkbox,
  InputAdornment,
  Badge,
  ListItem,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import { uniqBy } from 'lodash';
import { autocompleteOptionStyle } from '../../css/style';

const useStyles = makeStyles(theme => ({
  root: props => ({
    width: '100%',
    fontSize: '14px',
    backgroundColor: '#ffffff',
    margin: '4px 0 10px 0',

    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '5px 30px 5px 5px !important',

      '& .MuiAutocomplete-input': {
        padding: '4px',
      },
    },

    '& .MuiChip-deleteIcon': {
      color: 'rgba(255, 255, 255, 0.7)',
    },

    '& .MuiChip-root': {
      color: '#fff',
      backgroundColor: '#243060',
      maxWidth: props.chipMaxWidth,
      marginLeft: '5px',
      marginBottom: '1px',
    },

    '& .MuiChip-label': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },

    '& .MuiInputAdornment-positionEnd': {
      margin: '3px 16px',
    },

    '& .MuiBadge-badge': {
      height: '24px',
    },
  }),
}));

const filterOptions = createFilterOptions({
  limit: 10,
  stringify: option => option.name,
});

export default function PracticeAutocomplete(props) {
  const {
    title,
    labelCount = 2,
    disabled = false,
    items,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(() => (props.value || []));
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(items);

  const styleProps = {
    chipMaxWidth: value.length > 1 ? 90 : 200,
  };
  const classes = useStyles(styleProps);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    const { handleStateChange } = props;
    handleStateChange(value);
  }, [value]);

  useEffect(() => {
    const { items = [] } = props;
    let filteredItems = items;
    if (inputValue) {
      filteredItems = items.filter(item => item.name.toLowerCase().includes(inputValue.toLowerCase()));
    }
    filteredItems = uniqBy([...value, ...filteredItems], 'id');
    setOptions(filteredItems);
  }, [inputValue]);

  const renderChips = (value, getTagProps) => {
    let prunedValue = value;
    if (value.length > labelCount) {
      prunedValue = value.slice(0, labelCount);
    }
    return (
      <Fragment>
        {prunedValue.map((option, index) => (
          <Chip
            variant="outlined"
            label={option.name}
            size="small"
            {...getTagProps({ index })}
          />
        ))}
        {
          (value.length > labelCount)
          && (
            <InputAdornment position="end" className={classes.practiceSelectEndAdornment}>
              <Badge badgeContent={`+${value.length - labelCount}`} color="primary" overlap="rectangular" />
            </InputAdornment>
          )
        }
      </Fragment>
    );
  };

  return (
    <Autocomplete
      id="practices-autocomplete"
      classes={classes}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      openOnFocus
      disableCloseOnSelect
      disabled={disabled}
      multiple
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option => (option.name ? option.name : '')}
      filterOptions={filterOptions}
      inputValue={inputValue}
      value={value}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      noOptionsText="No Practices"
      options={options}
      popupIcon={null}
      renderInput={params => (
        <TextField
          {...params}
          id="select-multiple-checkbox"
          variant="outlined"
          placeholder={title}
          onFocus={(e) => {
            setOpen(true);
            e.target.placeholder = '';
          }}
          onBlur={(e) => {
            setOpen(false);
            e.target.placeholder = title;
          }}
        />
      )}
      // renderOption={(option, state) => {
      //   const checkedOptions = value.filter(item => item.id === option.id);

      //   return (<Fragment>
      //     <Checkbox checked={checkedOptions.length > 0} />
      //     {option.name}
      //   </Fragment>
      //   )}}
      renderOption={(props, option) => {
        const checkedOptions = value.filter(item => item.id === option.id);
        return (
          <ListItem {...props} key={option.id} sx={autocompleteOptionStyle}>
            <Checkbox checked={checkedOptions.length > 0} key={`${option.id}_checkbox`} />
              {' '}
              {option.name}
          </ListItem>
        );
      }}
      renderTags={(value, getTagProps) => renderChips(value, getTagProps)}
    />
  );
}
