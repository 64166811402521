import { ButtonBase, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Popover, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { memo, useEffect, useState } from 'react'
import { GOOGLE_MAPS_API_KEY, IMG_STORAGE_LINK } from '../constants/Constants';
import { isEmpty, isNumber, max } from 'lodash';
import { geodistance } from '../../utils/Functions';
import CloseIcon from '@mui/icons-material/Close';
import { navy } from '../../css/style';

const fastpassIcon = `${IMG_STORAGE_LINK}green-ticket.png`;

const styles = theme => ({
  fastpassText: {
    fontFamily: 'Asap',
    fontWeight: 600,
    fontSize: 12,
  },
  cardTitle: {
    fontFamily: 'Asap',
    fontWeight: 'bold',
    fontSize: 16,
    color: '#233160',
    width: '100%',
  },
  cancelBtn: {
    fontFamily: 'Asap',
    fontWeight: 600,
    fontSize: 13,
  },
  confirmBtn: {
    background: '#233160',
    fontFamily: 'Asap',
    fontWeight: 600,
    fontSize: 13,
    color: 'white',
    '&:hover': {
      backgroundColor: `${navy}`,
      color: 'white',
    },
  },
  link: {
    color: '#243060',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '15.6px',
  }
});

const defaultContainerStyle = {
  width: '100%',
  height: '602px'
};

const thriveDefaultLocation = {
  lat: 39.337263546349234,
  lng: -77.10519014257994
};

const PracticeGoogleMap = ({ markers, classes, containerStyle, practices, onConfirm, disabled, zoomIn = 1, isShowPractices, state }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  });

  const options = {
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: window.google?.maps?.MapTypeControlStyle?.HORIZONTAL_BAR,
      position: window.google?.maps?.ControlPosition?.TOP_LEFT,
    },
    zoomControl: true,
    zoomControlOptions: {
      position: window.google?.maps?.ControlPosition?.TOP_RIGHT,
    },
    scaleControl: true,
    streetViewControl: true,
    streetViewControlOptions: {
      position: window.google?.maps?.ControlPosition?.TOP_RIGHT,
    },
    fullscreenControl: false,
  }

  let { lat, lng } = !isEmpty(markers) ? markers[0]?.position : {};
  const [latitude, setLat] = useState(lat);
  const [longitude, setLng] = useState(lng);
  const [zoom, setZoom] = useState(zoomIn);
  const [map, setMap] = React.useState(null);
  const [activeMarker, setActiveMarker] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [practiceSelected, setPracticeSelected] = React.useState(null);

  useEffect(
    () => {
      if (isEmpty(markers)) return;
      setZoomFunc(markers);
      if (markers[0]?.position?.lat && markers[0]?.position?.lng) {
        setLat(markers[0]?.position?.lat);
        setLng(markers[0]?.position?.lng);
      }
    },
    [markers],
  );

  const setZoomFunc = (markers) => {
    if (!isEmpty(markers)) {
      let distanceArr = [];
      for (let i = 0; i < markers.length; i++) {
        const pos = markers[i].position;
        const otherItems = markers.slice(i + 1);
        if (!isEmpty(otherItems)) {
          for (let j = 0; j < otherItems.length; j++) {
            const otherPos = otherItems[j].position;
            distanceArr.push(geodistance(pos.lat, pos.lng, otherPos.lat, otherPos.lng));
          }
        }
      }

      if (state === 'AK') {
        setZoom(3)
      } else if (state === 'FL') {
        setZoom(6)
      } else if (state === 'NH') {
        setZoom(8)
      } else {
        setZoom(5);
      }
    }
  };

  const onLoad = React.useCallback(function callback(map) {
    if (isEmpty(markers)) return;
    const bounds = new window.google.maps.LatLngBounds();

    markers?.forEach(({ position }) => {
      if (isNumber(position?.lat) && isNumber(position?.lng)) {
        bounds.extend(position);
      }
    });
    setZoomFunc(markers);
    setMap(map);
  }, [markers])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [markers]);

  const renderCard = () => {
    let destination = practiceSelected?.location;
    destination = destination?.replace(/\//g, '%2F');
    destination = destination?.replace(/:/g, '%3A');
    return (
      <Popover
        id={`${practiceSelected?.id}_popover_PracticeGoogleMap${Math.random().toString()}`}
        key={`${practiceSelected?.id}_popover_PracticeGoogleMap${Math.random().toString()}`}
        open={!!practiceSelected}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setPracticeSelected(null);
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: { width: '357px', padding: '0px', boxShadow: '0px 4px 4px 0px #00000040' },
        }}
      >
        <Card
          style={{ boxShadow: 'none' }}
          key={`${practiceSelected?.id}_popover_card${Math.random().toString()}`}
        >
          <CardHeader
            style={{ padding: '8px' }}
            key={`${practiceSelected?.id}_popover_cardheader${Math.random().toString()}`}
            action={(
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  setAnchorEl(null);
                  setPracticeSelected(null);
                }}
                key={`${practiceSelected?.id}_close_icon_btn${Math.random().toString()}`}
              >
                <CloseIcon style={{ fontSize: '20px' }} key={`${practiceSelected?.id}_close_icon${Math.random().toString()}`} />
              </IconButton>
            )}
            title={(
              <Grid container
                style={{ display: 'flex', alignItems: 'center', width: '100%' }}
                justifyContent='flex-start'
                key={`${practiceSelected?.id}_title_grid${Math.random().toString()}`}
              >
                {
                  practiceSelected?.fastpassAllStaff && (
                    <Grid item xs={12}
                      style={{ alignItems: 'center', display: 'flex' }}
                      key={`${practiceSelected?.id}_title_grid_item${Math.random().toString()}`}
                    >
                      <img style={{ width: 20, height: 20, marginRight: '5px' }} src={fastpassIcon} alt='fastpass' title='fastpass' />
                      <Typography
                        key={`${practiceSelected?.id}_fastpass`}
                        className={classes.fastpassText}
                      >
                        FAST PASS
                      </Typography>
                    </Grid>
                  )
                }
                <Typography
                  key={`${practiceSelected?.id}_ss_title${Math.random().toString()}`}
                  className={classes.cardTitle}
                >
                  {practiceSelected?.name}
                </Typography>
                <Typography variant='body1'>
                  {practiceSelected?.address}
                </Typography>
              </Grid>
            )}
          />
          <CardActions style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <ButtonBase
              style={{ textDecoration: 'underline', float: 'right', visibility: disabled ? 'hidden' : 'visible' }}
              key={`${practiceSelected?.id}_get_dir_btn${Math.random().toString()}`}
              disabled={disabled}
              onClick={() => {
                onConfirm(practiceSelected)
                setAnchorEl(null);
                setPracticeSelected(null);
              }}
            >
              <Typography className={classes.link} key={`${practiceSelected?.id}_get_dir_text${Math.random().toString()}`}>Add to search</Typography>
            </ButtonBase>
            <ButtonBase
              target='_blank'
              href={`https://www.google.com/maps/dir/?api=1&destination=${destination}`}
              style={{ textDecoration: 'underline', float: 'right' }}
              key={`${practiceSelected?.id}_get_dir_btn${Math.random().toString()}`}
            >
              <Typography className={classes.link} key={`${practiceSelected?.id}_get_dir_text${Math.random().toString()}`}>Get Directions</Typography>
            </ButtonBase>
          </CardActions>
        </Card>
      </Popover>
    )
  }

  return isLoaded && (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle || defaultContainerStyle}
        center={{
          lat: latitude || thriveDefaultLocation.lat,
          lng: longitude || thriveDefaultLocation.lng
        }}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={options}
        id="ggmap"
      >
        {isShowPractices && markers.length && markers.map(({ id, name, position }) => (
          <div>
            <Marker
              key={`${id}${Math.random().toString()}`}
              position={position?.lat && position?.lng ? { lat: position.lat, lng: position.lng } : thriveDefaultLocation}
              icon={{
                url: activeMarker === id ? `${IMG_STORAGE_LINK}thrive-green-pin.svg` : `${IMG_STORAGE_LINK}thrive-pin.svg`,
                scaledSize: new google.maps.Size(42, 45),
              }}
              zIndex={100}
              onClick={(e) => {
                e.domEvent.stopPropagation();
                setActiveMarker(id);
                setLat(position?.lat);
                setLng(position?.lng);
                const practice = practices.find(x => x.id === id);
                setTimeout(() => {
                  const ggmap = document.getElementById('ggmap')
                  setPracticeSelected(practice);
                  setAnchorEl(ggmap ? ggmap : e.domEvent.target);
                }, 400);
              }}
              title={name}
            >
            </Marker>
          </div>
        ))}
      </GoogleMap>
      {renderCard()}
    </>
  )
}

export default withStyles(styles)(memo(PracticeGoogleMap));