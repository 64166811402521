import { combineReducers } from 'redux';
import user from './AuthReducer';
import loading from './LoadingReducer';
import message from './MessageReducer';
import resources from './ResourceReducer';

const RootReducer = combineReducers({
  user,
  message,
  loading,
  resources,
});

export default RootReducer;
