import React from 'react';
import { Hidden } from '@mui/material';
import { withStyles } from '@mui/styles';
import { WhiskerBotStyle } from '../../css/style';
import { IMG_STORAGE_LINK } from '../constants/Constants';

const WhiskerBot = (props) => {
  let { classes, long } = props;
  return (
    <React.Fragment>
      <Hidden xsDown>
        {long ? <img src={`${IMG_STORAGE_LINK}banner-desktop-long.png`} className={classes.whiskerBotBanner} /> : <img src={`${IMG_STORAGE_LINK}banner-desktop.png`} className={classes.whiskerBotBanner} />}
      </Hidden>
      <Hidden smUp>
        <img src={`${IMG_STORAGE_LINK}banner-mobile.png`} className={classes.whiskerBotBanner} />
      </Hidden>
    </React.Fragment>
  );
};

export default withStyles(WhiskerBotStyle)(WhiskerBot);
