import React from 'react';
import OnboardingItemComponent from './OnboardingItemComponent';
import SimpleSelectableIconComponent from './SimpleSelectableIconComponent';
import { withStyles } from '@mui/styles';
import { SimpleSelectableSpecialty, CertificationSpecialtyStyle } from '../../../css/style';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { VET_CERTS, VET_CERT_SPECIALTIES } from '../../../config/enums';
import SpecialityCollegeComponent from './SpecialityCollegeComponent';

class CertificationSpecialtyComponent extends OnboardingItemComponent {
  constructor(props) {
    super(props);
    const { initState } = props;
    const st = {};
    initState.forEach(cert => st[cert.strValue] = true);
    this.state = st;
    this.handleState();
  }

  handleState() {
    const { handleStateChange } = this.props;
    const items = VET_CERTS.filter(cert => this.state[cert]).map(cert => ({
      criteria: 'cert-specialty',
      strValue: cert,
    }));
    handleStateChange && handleStateChange(items);
  }

  typeClicked(specialty) {
    const st = {};
    st[specialty] = !this.state[specialty];
    this.setState(st, () => this.handleState());
  }

  render() {
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {
          VET_CERTS.map((cert) => {
            const certLabel = VET_CERT_SPECIALTIES[cert];
            return (
              <Grid item container justifyContent="center" xs={6} sm={4} md={3} key={cert}>
                <SimpleSelectableIconComponent
                  icon={cert}
                  title={`${certLabel} (${cert.toUpperCase()})`}
                  selected={this.state[cert]}
                  style={SimpleSelectableSpecialty}
                  onClick={e => this.typeClicked(cert)}
                />
              </Grid>
            );
          })
        }
      </Grid>
    );
  }
}

export default withStyles(CertificationSpecialtyStyle)(CertificationSpecialtyComponent);
