import Immutable from 'seamless-immutable';
import types from '../actions/ActionTypes';

export default function loadingReducer(state = Immutable({ loading: 0, loadingNotification: 0 }), action) {
  switch (action.type) {
    case types.LOGIN_USER_REQUEST:
    case types.RESET_PASSWORD_REQUEST:
    case types.VERIFY_USER_REQUEST:
      return Immutable.merge(state, { loading: state.loading + 1 });
    case types.ERROR:
    case types.LOGIN_USER_FAILURE:
    case types.LOGIN_USER_SUCCESS:
    case types.RESET_PASSWORD_FAILURE:
    case types.RESET_PASSWORD_SUCCESS:
    case types.VERIFY_USER_SUCCESS:
    case types.VERIFY_USER_FAILURE:
      return Immutable.merge(state, { loading: state.loading - 1 });
    case types.LOGOUT_USER:
      return Immutable.merge(state, { loading: 0 });
    default:
      return Immutable.merge(state, { loading: state.loading });
  }
}
